<template>
  <div class="cart">
    <div class="page-title-header">
      <h1 class="title page-title">
        <span>
          Shopping Cart &nbsp;(0.30kg)
        </span>
      </h1>
    </div>
    <div class="container">
      <div class="cart-page">
        <CartTable />
        <Side />
      </div>
    </div>
  </div>
</template>

<script>
import CartTable from '../components/cart/CartTable.vue'
import Side from '../components/cart/Side.vue'
export default { components: { CartTable, Side } }
</script>

<style lang="scss">
.cart-page {
  display: flex;
  @media (max-width: 1024px) {
    display: block;
  }
  .cart-bottom {
    width: 100%;
    max-width: 400px;
    @media (max-width: 1024px) {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(233, 102, 49, 1);
  border-radius: 5px;
}
</style>
