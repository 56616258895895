<template>
  <div class="product product-page" v-if="product">
    <div class="page-title-header">
      <h1 class="title page-title"><span>Black Vintage T-Shirt</span></h1>
    </div>
    <div class="container">
      <div class="product-swipper d-flex justify-content-between flex-wrap">
        <div class="left-side">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="main-image">
              <ProductSwiper
                v-if="product"
                :products="product"
                :current-slide="slide"
                :thumbs="thumbs"
              />
            </div>
            <div class="additional-images">
              <ProductThumnail
                v-if="product"
                :products="product"
                @thumbs="thumbs"
                v-model:current-slide="slide"
              />
            </div>
          </div>
        </div>
        <div class="right-side">
          <div class="product-details">
            <div class="product-blocks blocks-top">
              <div class="nobg">
                <b-tabs align="center">
                  <b-tab
                    title="Description"
                    v-if="product.description"
                    class="nav nav-tabs"
                    active
                  >
                    <div
                      class="block-content"
                      :class="expanded ? ' expand-content' : ''"
                    >
                      <p>
                        {{ product.description }}
                      </p>
                      <div class="block-expand-overlay text-center">
                        <a
                          class="block-expand btn-primary btn"
                          role="button"
                          @click="expanded = !expanded"
                        ></a>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Specifications" class="nav nav-tabs">
                    <div class="table-responsive">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <td colspan="2">
                              <strong>Style</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Material</td>
                            <td>cotton</td>
                          </tr>
                          <tr>
                            <td>Style</td>
                            <td>riding</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-tab>
                  <b-tab title="Reviews" class="nav nav-tabs reviews">
                    <form>
                      <div class="">
                        <p>
                          There are no reviews for this product.
                        </p>
                      </div>
                      <h4>Write a review</h4>
                      Please
                      <router-link to="/account/login">
                        login
                      </router-link>
                      or
                      <router-link to="/account/register">
                        register
                      </router-link>
                      to review
                    </form>
                  </b-tab>
                  <b-tab title="Custom Tabs" class="nav nav-tabs">
                    <div class="block-body">
                      <p>
                        Unlimited Blocks, Tabs or Accordions with any HTML
                        content can be assigned to any individual product or to
                        certain groups of products, like entire categories,
                        brands, products with specific options, attributes,
                        price range, etc. You can indicate any criteria via the
                        advanced product assignment mechanism and only those
                        products matching your criteria will display the
                        modules.
                      </p>
                      <p>
                        Also, any module can be selectively activated per device
                        (desktop/tablet/phone), customer login status and other
                        criteria. Imagine the possibilities.&nbsp;
                      </p>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
            <div class="rating-container d-flex">
              <Rating />
              <div class="review-links">
                <a>Based on 0 reviews.</a>
                <b>-</b>
                <a>Write a review</a>
              </div>
            </div>
            <div class="product-price-group d-flex">
              <div class="price-wrapper">
                <div class="price-group">
                  <div class="product-price">${{ product.price_normal }}</div>
                </div>
                <div class="product-tax">Ex Tax: ${{ product.tax }}</div>
              </div>
              <div class="product-stats flex-1 d-flex justify-content-between">
                <ul class="list-unstyled">
                  <li class="product-stock in-stock">
                    <b>Stock:</b>
                    <span>In Stock</span>
                  </li>
                  <li class="product-model">
                    <b>Model:</b>
                    <span>{{ product.model }}</span>
                  </li>
                </ul>
                <div class="brand-image product-manufacturer">
                  <router-link to="/products/1">
                    <img
                      src="https://www.journal-theme.com/1/image/cache/catalog/journal3/brands/fashion-60x60.jpg.webp"
                      alt="NY Fashion"
                      width="60"
                      height="60"
                    />
                    <span>NY Fashion</span>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="product-options" v-if="product.extraOptions.sizes">
              <div class="form-group">
                <label class="control-label">Multiple choice</label>
                <div>
                  <div class="checkbox">
                    <label
                      v-for="item in product.extraOptions.sizes"
                      :key="item"
                    >
                      <input type="checkbox" v-model="size" :value="item" />
                      <span class="option-value">
                        {{ item }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="product.extraOptions.singleChoice">
                <label class="control-label">
                  Single choice (with image)
                </label>
                <div class="d-flex">
                  <div
                    class="radio"
                    v-for="item in product.extraOptions.singleChoice"
                    :key="item.id"
                  >
                    <label>
                      <input type="radio" v-model="color" :value="item.title" />
                      <img
                        width="40"
                        height="40"
                        :src="item.path"
                        alt="Dark Gray "
                      />
                      <span class="option-value">
                        Dark Gray
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="button-group-page">
              <div class="buttons-wrapper">
                <div class="stepper-group cart-group">
                  <div class="stepper">
                    <input
                      type="text"
                      name="quantity"
                      v-model="qty"
                      class="form-control"
                    />
                    <span>
                      <i
                        class="fa-solid fa-angle-up"
                        @click="changeQuantity(1)"
                      ></i>
                      <i
                        class="fa-solid fa-angle-down"
                        @click="changeQuantity(-1)"
                      ></i>
                    </span>
                  </div>
                  <a id="button-cart" class="btn btn-primary btn-cart">
                    <span class="btn-text" @click="addToCart(product)">
                      Add to Cart
                    </span>
                  </a>
                  <div class="extra-group">
                    <a class="btn btn-extra btn1">
                      <span class="btn-text">Buy Now</span>
                    </a>
                    <a class="btn btn-extra btn2">
                      <span class="btn-text">Question</span>
                    </a>
                  </div>
                </div>

                <div class="wishlist-compare">
                  <a
                    class="btn-wishlist"
                    role="button"
                    @click="addToWashList(product)"
                  >
                    <span class="btn-text">Add to Wish List</span>
                  </a>

                  <a
                    class="btn-compare"
                    role="button"
                    @click="addToCompare(product)"
                  >
                    <span class="btn-text">Compare this Product</span>
                  </a>
                </div>
              </div>
            </div>
            <teleport to="body">
              <transition name="fade-in">
                <Notification
                  :imgSrc="imgSrc"
                  :message="message"
                  :mode="mode"
                  @closeModal="closeNotfiy"
                  v-if="openNotification == product.id"
                />
              </transition>
            </teleport>
          </div>
          <div class="product-blocks blocks-bottom">
            <div class="block-body expand-block"></div>
          </div>
        </div>
      </div>
      <div class="tags">
        <span class="tags-title">Tags:</span>
        <router-link to="/categories/1" v-for="n in 5" :key="n">
          body
        </router-link>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <Recentslider />
      </div>
    </div>
  </div>
</template>

<script>
import Products from '@/products.json'
import Rating from '../Rating.vue'
import Recentslider from './Recentslider.vue'
import ProductSwiper from './ProductSwiper.vue'
import ProductThumnail from './ProductThumnail.vue'
import 'lazysizes'
import Notification from './Notification.vue'
export default {
  components: {
    Rating,
    Recentslider,
    ProductSwiper,
    ProductThumnail,
    Notification,
  },
  data() {
    return {
      expanded: false,
      openNotification: 0,
      qty: 1,
      lightbox: false,
      slide: 0,
      thumbs: null,
      products: Products,
      product: '',
      size: [],
      color: '',
      id: '',
      message: '',
      mode: '',
      imgSrc: '',
    }
  },

  methods: {
    slideHandler(n) {
      this.lightbox = true
      this.slide = Number(n) - 1
    },
    getProduct() {
      this.product = this.products.filter((el) => el.id == this.id)[0]
    },
    changeQuantity(n) {
      if (this.qty <= 1 && n == -1) return
      this.qty += n
    },
    addToCart(e) {
      this.imgSrc = ''
      this.message = ''
      this.mode = ''
      this.$store.commit('addToCart', {
        product: e,
        qty: this.qty,
        color: this.color,
        size: this.size,
      })
      this.openNotification = e.id
      this.imgSrc = `${e.images[0].path}`
      this.message = `<h4>You have added <a> ${e.name}</a> Successfully</h4>`
      this.mode = 'addToCart'

      setTimeout(() => {
        this.openNotification = 0
      }, 3000)
    },
    closeNotfiy() {
      this.openNotification = 0
    },
    addToWashList(e) {
      this.imgSrc = ''
      this.message = ''
      this.mode = ''
      this.$store.commit('addToWashlist', e)
      this.openNotification = e.id
      this.imgSrc = `${e.images[0].path}`
      this.message = `<h4>You have added <a> ${e.name}</a> Successfully</h4>`
      this.mode = ''

      setTimeout(() => {
        this.openNotification = 0
      }, 3000)
    },
    addToCompare(e) {
      this.imgSrc = ''
      this.message = ''
      this.mode = ''
      this.$store.commit('addToCompare', e)
      this.openNotification = e.id
      this.imgSrc = `${e.images[0].path}`
      this.message = `<h4>You have added <a> ${e.name}</a> Successfully</h4>`
      this.mode = ''

      setTimeout(() => {
        this.openNotification = 0
      }, 3000)
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.getProduct()
    }
  },

  watch: {
    $route() {
      if (this.$route.params.id) {
        this.id = this.$route.params.id
        this.getProduct()
      }
    },
  },
}
</script>

<style lang="scss">
.product-details {
  .nobg {
    ul {
      padding: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .rating-container {
    justify-content: flex-start;
    display: flex;
    padding-top: 10px;
    padding-bottom: 25px;
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: rgba(226, 226, 226, 1);
    margin-bottom: 15px;
    .review-links {
      a {
        display: inline-flex;
        font-size: 14px;
        margin: 0 4px;
        font-family: 'Roboto';
      }
    }
    a {
      color: rgba(13, 82, 214, 1) !important;
      text-decoration: underline !important;
      cursor: pointer;
      &:hover {
        color: rgba(233, 102, 49, 1) !important;
        text-decoration: none !important;
      }
    }
  }
  .product-price-group {
    margin-bottom: 15px;
    .price-group {
      font-size: 30px;
      padding: 0 20px 0 0;
      font-weight: 700;
      line-height: 1;
    }
    .product-tax {
      display: block;
      color: rgba(105, 105, 115, 1);
      margin-top: 5px;
    }
    .product-stats {
      padding-left: 25px;
      border-width: 0;
      border-left-width: 1px;
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      border-style: solid;
      border-color: rgba(226, 226, 226, 1);
      .brand-image.product-manufacturer {
        a {
          display: flex;
          flex-direction: column;
          padding: 5px;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(226, 226, 226, 1);
          color: rgba(13, 82, 214, 1) !important;
          text-decoration: underline !important;
          font-family: 'Roboto';
          &:hover {
            color: rgba(233, 102, 49, 1) !important;
          }
        }
      }
      span {
        font-size: 12px;
      }
      li {
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
      b {
        display: none;
      }
      .in-stock {
        &::before {
          content: '\eb1b' !important;
          font-family: icomoon !important;
          font-size: 16px;
          color: rgba(80, 173, 85, 1);
          margin-right: 5px;
          vertical-align: middle;
        }
        span {
          color: rgba(80, 173, 85, 1);
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
  .product-options {
    padding-top: 5px;
    padding-bottom: 10px;
    border-width: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: rgba(226, 226, 226, 1);
    .control-label {
      max-width: 9999px;
      padding-top: 7px;
      padding-bottom: 5px;
      justify-content: flex-start;
    }
    .checkbox,
    .radio {
      display: flex;
      padding: 0 !important;
      margin: 0 !important;
      label {
        min-height: 20px;
        cursor: pointer;
      }
      input {
        display: none;
      }
    }
    .checkbox {
      & > label {
        margin-right: 5px;
        margin-bottom: 5px;
        min-width: 30px;
        min-height: 30px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(226, 226, 226, 1);
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
      }
      .option-value {
        padding: 2px 7px;
      }
      input:checked + .option-value {
        background: rgba(240, 242, 245, 1);
        box-shadow: inset 0 0 5px rgb(0 0 0 / 10%);
      }
    }
    .radio {
      & > label {
        margin-right: 5px;
        margin-bottom: 5px;
        img {
          border-width: 1px;
          border-style: solid;
          border-color: rgba(139, 145, 152, 1);
          border-radius: 50%;
        }
        input:checked + img {
          border-color: blue;
        }
      }
      .option-value {
        display: none;
      }
    }
    .form-group {
      margin-bottom: 6px !important;
    }
  }
  .buttons-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    .stepper-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-grow: 1;
      width: 100%;
      .stepper {
        height: 45px;
        margin-right: 10px;
        input {
          padding: 0 20px 0 2px;
          text-align: center;
          font-size: 1em;
          height: 100%;
          width: 100%;
        }
      }
    }
    .btn-cart {
      flex-grow: 1;
      height: 45px;
      font-size: 13px;
      font-family: 'Roboto';
      &::before {
        margin: 0 5px 0 0;
      }
    }
    .extra-group {
      margin-left: 10px;
      flex-grow: initial;
      a {
        flex-grow: 1;
        height: 45px;
        font-size: 13px;
        font-family: 'Roboto';
        display: inline-flex;
        align-items: center;
        &.btn2 {
          span {
            display: none;
            &:before {
              margin: 0 !important;
            }
          }
        }
        &::before {
          margin: 0 5px 0 0;
          font-size: 17px;
        }
      }
    }
    .wishlist-compare {
      border-width: 0;
      border-top-width: 1px;
      border-style: solid;
      border-color: rgba(226, 226, 226, 1);
      width: 100%;
      padding-top: 10px;
      margin-top: 15px;
      a {
        font-size: 12px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        &.btn-wishlist {
          &::before {
            color: rgba(221, 14, 28, 1);
          }
        }
        &:hover {
          color: rgba(233, 102, 49, 1) !important;
          background: none !important;
        }
        &:first-child {
          margin-right: 10px;
        }
        &::before {
          font-size: 17px;
          margin: 0 5px 0 0;
        }
      }
    }

    @media (max-width: 1025px) {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 10px;
      background: #fff;
      z-index: 10;
      width: 100% !important;
      justify-content: space-between;

      .stepper-group {
        flex-grow: initial;
        width: auto;

        .stepper {
          height: 36px !important;
          margin-right: 5px !important;
        }
      }
      .extra-group {
        margin-left: 5px !important;
        a {
          height: 36px;
          &.btn2 {
            margin-left: 5px !important;
            span {
              display: inline-flex;
              &:before {
                margin: 0 5px 0 0 !important;
              }
            }
          }
        }
        @media (max-width: 768px) {
          .btn1,
          .btn2 {
            span {
              display: none !important;
            }
          }
        }
      }
      .wishlist-compare {
        flex-grow: initial;
        width: auto;
        border-top-width: 0;
        padding-top: 0;
        margin-top: 0;
        a {
          span {
            display: none;
          }
        }
      }
      .btn-cart {
        flex-grow: initial;
        height: 36px !important;
      }
    }
  }

  .reviews {
    form {
      & > h4 {
        font-size: 18px !important;
        margin-top: 10px !important;
      }
      a {
        color: rgba(13, 82, 214, 1) !important;
        text-decoration: underline !important;
        display: inline-block !important;
        &:hover {
          color: rgba(233, 102, 49, 1) !important;
          text-decoration: none !important;
        }
      }
    }
  }
}
.product .bottom {
  background: rgba(240, 242, 245, 1);
  padding-bottom: 20px;
  padding-top: 40px;
}
.btn1 {
  background: rgba(80, 173, 85, 1) !important;
  line-height: 25px;
  color: #fff !important;
  &::before {
    content: '\eaaf' !important;
    font-family: icomoon !important;
    font-size: 17px;
  }
}
.btn2 {
  background: rgba(221, 14, 28, 1) !important;
  height: 45px;
  margin-left: 10px;
  color: #fff !important;
  &::before {
    content: '\f29c' !important;
    font-family: icomoon !important;
    font-size: 17px;
  }
}

.product-page .button-group-page {
  padding-top: 20px;
}

.main-image {
  // height: 100%;
  width: 100%;
  margin-bottom: 10px;
  .swiper {
    max-height: 512px;
    // height: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(226, 226, 226, 1);
    border-radius: 3px;
  }
}
.additional-images {
  width: 100%;
  .swiper {
    max-height: 512px;
  }
  .swiper-slide {
    img {
      width: 100%;
      // height: 70px !important;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(226, 226, 226, 1);
      border-radius: 3px;
      cursor: pointer;
    }
  }
}
.product-swipper {
  .right-side {
    width: 100%;
  }
  .left-side {
    width: 100%;
    margin-bottom: 40px;
    .swiper-thumbs {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .product-details .buttons-wrapper .btn-cart {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 992px) {
    .right-side {
      padding-left: 40px;
      width: 50%;
    }
    .left-side {
      width: 50%;
      margin-bottom: 0;
    }
  }
}
.is-rtl {
  @media (min-width: 992px) {
    .right-side {
      padding-right: 40px;
      padding-left: 0;
      width: 50%;
    }
  }
  .product-details .product-price-group .price-group {
    padding: 0 0 0 20px;
  }
  .product-details .product-price-group .product-stats .in-stock::before {
    margin: 0 0 0 5px;
  }
  .product-details .product-price-group .product-stats {
    border-width: 0 !important;
    border-right-width: 1px !important;
  }
  .product-details .product-options .checkbox > label {
    margin-right: 0px;
    margin-left: 5px;
  }
  .product-details .product-options .radio > label {
    margin-right: 0px;
    margin-left: 5px;
  }
  .product-details .buttons-wrapper .stepper-group .stepper {
    margin-right: 0px;
    margin-left: 10px;
  }
  .product-details .buttons-wrapper .extra-group {
    margin-left: 0;
    margin-right: 0;
  }
  .product-details .buttons-wrapper .extra-group a {
    margin-left: 0;
    margin-right: 10px;
  }
  .product-details .buttons-wrapper .wishlist-compare a::before {
    margin: 0 0 0 5px;
  }
  .product-details .buttons-wrapper .wishlist-compare a:first-child {
    margin-left: 10px;
    margin-right: 0px;
  }
  .tags a,
  .tags-title {
    margin-left: 8px;
    margin-right: 0px;
  }
  #productOverview .modal-dialog .modal-header {
    left: 10px;
    right: unset;
  }
  .product-info .button-group-page .wishlist-compare a.btn-compare.btn {
    margin-left: 7px;
    // margin-right: 7px;
  }
}
</style>
