<template>
  <div class="return">
    <p>Please complete the form below to request an RMA number.</p>
    <form class="form-horizontal">
      <fieldset>
        <legend>Order Information</legend>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-firstname">
              First Name
            </label>
          </div>
          <div class="col-sm-9">
            <input
              type="text"
              name="firstname"
              value="Ezat"
              placeholder="First Name"
              id="input-firstname"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-lastname">
              Last Name
            </label>
          </div>
          <div class="col-sm-9">
            <input
              type="text"
              name="lastname"
              value="Ellozy"
              placeholder="Last Name"
              id="input-lastname"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-email">E-Mail</label>
          </div>
          <div class="col-sm-9">
            <input
              type="text"
              name="email"
              value="ezatellozy1@gmail.co"
              placeholder="E-Mail"
              id="input-email"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-telephone">
              Telephone
            </label>
          </div>
          <div class="col-sm-9">
            <input
              type="text"
              name="telephone"
              value="01017564705"
              placeholder="Telephone"
              id="input-telephone"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-order-id">
              Order ID
            </label>
          </div>
          <div class="col-sm-9">
            <input
              type="text"
              name="order_id"
              value=""
              placeholder="Order ID"
              id="input-order-id"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-date-ordered">
              Order Date
            </label>
          </div>
          <div class="col-sm-9">
            <div class="input-group date">
              <input
                type="text"
                name="date_ordered"
                value=""
                placeholder="Order Date"
                data-date-format="YYYY-MM-DD"
                id="input-date-ordered"
                class="form-control"
              />
              <span class="input-group-btn">
                <button type="button" class="btn btn-primary">
                  <i class="fa fa-calendar"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>Product Information</legend>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-product">
              Product Name
            </label>
          </div>
          <div class="col-sm-9">
            <input
              type="text"
              name="product"
              value=""
              placeholder="Product Name"
              id="input-product"
              class="form-control"
            />
            <input type="hidden" name="product_id" value="" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-model">
              Product Code
            </label>
          </div>
          <div class="col-sm-9">
            <input
              type="text"
              name="model"
              value=""
              placeholder="Product Code"
              id="input-model"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-quantity">
              Quantity
            </label>
          </div>
          <div class="col-sm-9">
            <input
              type="text"
              name="quantity"
              value="1"
              placeholder="Quantity"
              id="input-quantity"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label">Reason for Return</label>
          </div>
          <div class="col-sm-9">
            <div class="radio">
              <label>
                <input type="radio" name="return_reason_id" value="1" />
                Dead On Arrival
              </label>
            </div>
            <div class="radio">
              <label>
                <input type="radio" name="return_reason_id" value="4" />
                Faulty, please supply details
              </label>
            </div>
            <div class="radio">
              <label>
                <input type="radio" name="return_reason_id" value="3" />
                Order Error
              </label>
            </div>
            <div class="radio">
              <label>
                <input type="radio" name="return_reason_id" value="5" />
                Other, please supply details
              </label>
            </div>
            <div class="radio">
              <label>
                <input type="radio" name="return_reason_id" value="2" />
                Received Wrong Item
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <div class="col-sm-3">
            <label class="control-label">Product is opened</label>
          </div>
          <div class="col-sm-9">
            <label class="radio-inline">
              <input type="radio" name="opened" value="1" />
              Yes
            </label>
            <label class="radio-inline">
              <input type="radio" name="opened" value="0" checked="checked" />
              No
            </label>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label class="control-label" for="input-comment">
              Faulty or other details
            </label>
          </div>
          <div class="col-sm-9">
            <textarea
              name="comment"
              rows="10"
              placeholder="Faulty or other details"
              id="input-comment"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </fieldset>
      <div class="buttons">
        <div class="pull-left">
          <router-link to="/account" class="btn btn-default">
            Back
          </router-link>
        </div>
        <div class="pull-right">
          <button type="submit" class="btn btn-primary">
            <span>Continue</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.radio {
  label {
    max-width: 100%;
  }
}
.input-group-btn {
  button {
    height: 38px !important;
    display: flex;
    align-items: center;
    @media (max-width: 470px) {
      min-height: 32px !important;
      height: 32px !important;
    }
  }
}
</style>
