<template>
  <div class="table-responsive">
    <table class="table table table-borderless">
      <tbody>
        <tr>
          <td class="text-left">
            Ezat Ellozy
            <br />
            Ezat Ellozy
            <br />
            Saif Eldin - Alzarqa - Damietta
            <br />
            Alzarqa 34733
            <br />
            Swietokrzyskie
            <br />
            Poland
          </td>
          <td class="td-action text-end">
            <button class="btn btn-info">
              Edit
            </button>
            <button class="btn btn-danger">
              Delete
            </button>
          </td>
        </tr>
        <tr>
          <td class="text-left">
            Ezat Ellozy
            <br />
            Ezat Ellozy
            <br />
            Saif Eldin - Alzarqa - Damietta
            <br />
            Alzarqa 34733
            <br />
            Swietokrzyskie
            <br />
            Poland
          </td>
          <td class="td-action text-end">
            <button class="btn btn-info">
              Edit
            </button>
            <button class="btn btn-danger">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.account-address {
  .td-action {
    button {
      padding: 13px 18px !important;
    }
  }
}
</style>
