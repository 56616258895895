<template>
  <div class="grid-row grid-row-top-2">
    <div class="container p-0">
      <div class="grid-cols">
        <div class="grid-col grid-col-top-2-1">
          <div class="grid-items">
            <div class="grid-item grid-item-top-2-1-1">
              <div class="module module-info_blocks module-info_blocks-86">
                <div class="module-body row w-100 m-0">
                  <div
                    class="module-item module-item-1 info-blocks info-blocks-icon col-sm-6 col-lg-3 p-0"
                  >
                    <div class="info-block">
                      <div class="info-block-content">
                        <div class="info-block-title">Free Shipping</div>
                        <div class="info-block-text">
                          Free delivery over $100
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="module-item module-item-2 info-blocks info-blocks-icon col-sm-6 col-lg-3 p-0"
                  >
                    <div class="info-block">
                      <div class="info-block-content">
                        <div class="info-block-title">Free Returns</div>
                        <div class="info-block-text">Hassle free returns</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="module-item module-item-3 info-blocks info-blocks-icon col-sm-6 col-lg-3 p-0"
                  >
                    <div class="info-block">
                      <div class="info-block-content">
                        <div class="info-block-title">Secure Shopping</div>
                        <div class="info-block-text">
                          Best security features
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="module-item module-item-4 info-blocks info-blocks-icon col-sm-6 col-lg-3 p-0"
                  >
                    <div class="info-block">
                      <div class="info-block-content">
                        <div class="info-block-title">Unlimited Blocks</div>
                        <div class="info-block-text">Any content, any page</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
// .module-info_blocks-86 .module-item {
//   padding: 0px;
//   width: calc(100% / 4 - 0.01px);
// }
</style>
