<template>
  <div class="main-menu-wrapper">
    <div class="main-menu-container">
      <div class="main-menu main-menu-1">
        <ul>
          <li
            class="menu-item main-menu-item-1 dropdown drop-menu first-dropdown"
          >
            <router-link
              to="/categories"
              role="button"
              class="dropdown-toggle departments"
            >
              <span class="links-text">All Departments</span>
              <span class="menu-label">Sale</span>
            </router-link>
            <div class="dropdown-menu j-dropdown">
              <div class="flyout-menu flyout-menu-7">
                <ul class="j-menu">
                  <li
                    class="menu-item flyout-menu-item flyout-menu-item-1 mega-menu dropdown"
                  >
                    <router-link
                      to="/categories"
                      class="dropdown-toggle"
                      aria-expanded="false"
                      data-toggle="dropdown"
                    >
                      <span class="links-text">Shop by Category</span>
                    </router-link>
                    <div class="dropdown-menu j-dropdown">
                      <div class="mega-menu-content">
                        <div>
                          <div class="content">
                            <div class="item" v-for="n in 6" :key="n">
                              <div class="item-content">
                                <router-link
                                  to="/categories/1"
                                  class="catalog-title"
                                >
                                  Fashion
                                </router-link>
                                <div class="item-assets image-left">
                                  <div class="subitems">
                                    <div
                                      class="subitem"
                                      v-for="n in 4"
                                      :key="n"
                                    >
                                      <router-link to="/products/1">
                                        <span>Accesories</span>
                                      </router-link>
                                    </div>

                                    <div class="subitem view-more">
                                      <router-link to="/categories/1">
                                        <span>
                                          See all products
                                        </span>
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    class="menu-item flyout-menu-item flyout-menu-item-2 dropdown mega-menu"
                  >
                    <router-link to="/categories/1" class="dropdown-toggle">
                      <span class="links-text">Shop by Brand</span>
                    </router-link>
                    <div class="dropdown-menu j-dropdown">
                      <div class="mega-menu-content">
                        <div>
                          <div class="content">
                            <div class="item" v-for="n in 6" :key="n">
                              <div class="item-content">
                                <router-link
                                  to="/categories/1"
                                  class="catalog-title"
                                >
                                  Apple
                                </router-link>
                                <div class="item-assets image-left">
                                  <div class="subitems">
                                    <div
                                      class="subitem"
                                      v-for="n in 4"
                                      :key="n"
                                    >
                                      <router-link to="/products/1">
                                        <span>
                                          Apple Cinema 30"
                                        </span>
                                      </router-link>
                                    </div>
                                    <div class="subitem view-more">
                                      <router-link to="/categories/1">
                                        <span>
                                          See all products
                                        </span>
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    class="menu-item flyout-menu-item flyout-menu-item-3 dropdown mega-menu"
                  >
                    <router-link
                      to="/categories/1"
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <span class="links-text">Special Deals</span>
                      <span class="menu-label">Sale</span>
                    </router-link>
                    <div class="dropdown-menu j-dropdown">
                      <div class="mega-menu-content">
                        <div class="content">
                          <div class="item item1">
                            <router-link to="/categories/1">
                              <img
                                src="https://www.journal-theme.com/1/image/cache/catalog/journal3/banners/sale-240x375h.jpg.webp"
                                alt=""
                              />
                              <div class="banner-text banner-caption">
                                <span>See all specials</span>
                              </div>
                            </router-link>
                          </div>
                          <div class="item item2">
                            <div class="product-grid">
                              <div
                                v-for="n in 8"
                                :key="n"
                                class="product-layout"
                              >
                                <div class="product-thumb">
                                  <div class="image">
                                    <div class="quickview-button">
                                      <a class="btn-quickview tooltip-parent">
                                        <span class="tooltips">
                                          Quickview
                                        </span>
                                      </a>
                                    </div>

                                    <router-link
                                      to="/products/1"
                                      class="product-img"
                                    >
                                      <div>
                                        <img
                                          src="https://www.journal-theme.com/1/image/cache/catalog/journal3/products/beauty/2-250x250.jpg.webp"
                                          alt="Body Oil"
                                          class="img-responsive"
                                        />
                                      </div>
                                    </router-link>
                                  </div>

                                  <div class="caption">
                                    <div class="name">
                                      <router-link to="/products/1">
                                        Body Oil
                                      </router-link>
                                    </div>

                                    <div class="price">
                                      <div>
                                        <span class="price-new">
                                          $100.00
                                        </span>
                                        <span class="price-old">
                                          $460.00
                                        </span>
                                      </div>
                                    </div>

                                    <div class="buttons-wrapper">
                                      <div class="button-group d-flex">
                                        <a
                                          class="btn-cart btn-primary tooltip-parent"
                                        >
                                          <span class="tooltips">
                                            Add to Cart
                                          </span>
                                        </a>

                                        <a
                                          class="tooltip-parent btn-primary btn-wishlist"
                                        >
                                          <span class="tooltips">
                                            Add to Wish List
                                          </span>
                                        </a>

                                        <a
                                          class="tooltip-parent btn-primary btn-compare"
                                        >
                                          <span class="tooltips">
                                            Compare this Product
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    class="menu-item flyout-menu-item flyout-menu-item-4 dropdown mega-menu"
                  >
                    <a class="dropdown-toggle">
                      <span class="links-text">Bestsellers</span>
                    </a>
                    <div class="dropdown-menu j-dropdown">
                      <div class="mega-menu-content">
                        <div class="grid-rows">
                          <div class="grid-row grid-row-1">
                            <div class="grid-cols">
                              <div class="grid-col grid-col-1">
                                <div class="grid-items">
                                  <div class="grid-item grid-item-1">
                                    <div
                                      class="module module-products module-products-227 module-products-grid"
                                    >
                                      <div class="module-body">
                                        <div class="module-item module-item-1">
                                          <div class="product-grid">
                                            <div
                                              v-for="n in 8"
                                              :key="n"
                                              class="product-layout has-countdown has-extra-button"
                                            >
                                              <div class="product-thumb">
                                                <div class="image">
                                                  <div class="quickview-button">
                                                    <a
                                                      role="button"
                                                      @click="
                                                        openProductModal = n
                                                      "
                                                      class="btn-primary btn-quickview tooltip-parent"
                                                    >
                                                      <span class="tooltips">
                                                        Quickview
                                                      </span>
                                                    </a>
                                                  </div>
                                                  <Teleport to="body">
                                                    <ProductModal
                                                      v-if="
                                                        openProductModal == n
                                                      "
                                                      @closeModal="closeModal"
                                                    />
                                                  </Teleport>
                                                  <router-link
                                                    to="/products/1"
                                                    class="product-img"
                                                  >
                                                    <div>
                                                      <img
                                                        src="https://www.journal-theme.com/1/image/cache/catalog/journal3/products/beauty/2-250x250.jpg.webp"
                                                        alt="Body Oil"
                                                        class="img-responsive"
                                                      />
                                                    </div>
                                                  </router-link>

                                                  <div class="product-labels">
                                                    <span
                                                      class="product-label product-label-28 product-label-default"
                                                    >
                                                      <b>-78 %</b>
                                                    </span>
                                                  </div>
                                                </div>

                                                <div class="caption">
                                                  <div class="name">
                                                    <router-link
                                                      to="/products/1"
                                                    >
                                                      Body Oil
                                                    </router-link>
                                                  </div>

                                                  <div class="price">
                                                    <div>
                                                      <span class="price-new">
                                                        $100.00
                                                      </span>
                                                      <span class="price-old">
                                                        $460.00
                                                      </span>
                                                    </div>
                                                  </div>

                                                  <div class="buttons-wrapper">
                                                    <div
                                                      class="button-group d-flex"
                                                    >
                                                      <a
                                                        class="btn-cart btn-primary tooltip-parent"
                                                      >
                                                        <span class="tooltips">
                                                          Add to Cart
                                                        </span>
                                                      </a>

                                                      <a
                                                        class="tooltip-parent btn-primary btn-wishlist"
                                                      >
                                                        <span class="tooltips">
                                                          Add to Wish List
                                                        </span>
                                                      </a>

                                                      <a
                                                        class="tooltip-parent btn-primary btn-compare"
                                                      >
                                                        <span class="tooltips">
                                                          Compare this Product
                                                        </span>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="grid-col grid-col-2">
                                <div class="grid-items">
                                  <div class="grid-item grid-item-1">
                                    <div
                                      class="module module-blocks module-blocks-212 blocks-grid"
                                    >
                                      <div class="module-body">
                                        <div
                                          class="module-item module-item-1 no-expand"
                                        >
                                          <div class="block-body expand-block">
                                            <h3
                                              class="title module-title block-title"
                                            >
                                              CMS Blocks
                                            </h3>
                                            <div class="block-header">
                                              <img
                                                src="https://www.journal-theme.com/1/image/cache/catalog/journal3/banners/ban4-200x120w.jpg.webp"
                                                alt=""
                                                class="block-image"
                                              />
                                            </div>
                                            <div class="block-wrapper">
                                              <div class="">
                                                <p>
                                                  The mega menus use the page
                                                  builder interface and this
                                                  means you can add any layout
                                                  module in your menus to build
                                                  the desired layout in any rows
                                                  and columns combination.
                                                </p>
                                              </div>
                                              <div class="block-footer">
                                                <router-link
                                                  class="btn btn-primary shop"
                                                  to="/categories/1"
                                                >
                                                  Shop Now
                                                </router-link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    class="menu-item flyout-menu-item flyout-menu-item-5 dropdown mega-menu"
                  >
                    <router-link to="/blogs" class="dropdown-toggle">
                      <span class="links-text">Custom Links</span>
                    </router-link>
                    <div class="dropdown-menu j-dropdown">
                      <div class="mega-menu-content flex-column">
                        <div class="content">
                          <div class="top">
                            <div class="item" v-for="n in 3" :key="n">
                              <div class="links-menu links-menu-206">
                                <h3 class="title module-title closed">
                                  Top Categories
                                </h3>
                                <ul class="module-body">
                                  <li
                                    v-for="n in 10"
                                    :key="n"
                                    class="menu-item links-menu-item links-menu-item-1"
                                  >
                                    <router-link to="/categories/1">
                                      <span class="links-text">
                                        Fashion
                                      </span>
                                    </router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="item item1">
                              <div class="icons-menu icons-menu-209">
                                <h3 class="title module-title">
                                  Icons Menus
                                </h3>
                                <ul>
                                  <li
                                    v-for="n in 9"
                                    :key="n"
                                    class="menu-item icons-menu-item icons-menu-item-1 icon-menu-icon"
                                  >
                                    <router-link to="/categories/1">
                                      <span class="links-text">
                                        Fashion
                                      </span>
                                    </router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="bottom">
                          <ul class="">
                            <li
                              class="menu-item links-menu-item links-menu-item-1"
                            >
                              <a>
                                <span class="links-text" style="color: #333;">
                                  Don't Miss:
                                </span>
                              </a>
                            </li>

                            <div class="d-flex">
                              <li
                                class="menu-item links-menu-item links-menu-item-2"
                              >
                                <a>
                                  <span class="links-text">
                                    Create
                                  </span>
                                </a>
                              </li>
                              <li
                                v-for="n in 4"
                                :key="n"
                                class="menu-item links-menu-item links-menu-item-3"
                              >
                                <router-link to="/categories">
                                  <span class="links-text">
                                    Any Links
                                  </span>
                                </router-link>
                              </li>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    class="menu-item flyout-menu-item flyout-menu-item-6 multi-level"
                  >
                    <router-link to="/products">
                      <span class="links-text">See All Products</span>
                      <span class="menu-label">New</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="menu-item main-menu-item-2 multi-level dropdown drop-menu">
            <router-link to="/categories/1" class="dropdown-toggle">
              <span class="links-text">Multilevel</span>
            </router-link>
            <div class="dropdown-menu j-dropdown" id="collapse-62dd363fdcc80">
              <ul class="j-menu">
                <li class="menu-item menu-item-c105 dropdown">
                  <router-link to="/categories/1" class="dropdown-toggle">
                    <span class="links-text">Accesories</span>
                  </router-link>
                  <div class="dropdown-menu j-dropdown">
                    <ul class="j-menu">
                      <li class="menu-item menu-item-c152">
                        <router-link to="/categories/1">
                          <span class="links-text">Belts</span>
                        </router-link>
                      </li>
                      <li class="menu-item dropdown" v-for="n in 5" :key="n">
                        <router-link to="/categories/1" class="dropdown-toggle">
                          <span class="links-text">Jewelry</span>
                        </router-link>
                        <div
                          class="dropdown-menu j-dropdown"
                          id="collapse-62dd363fdccb6"
                        >
                          <ul class="j-menu">
                            <li class="menu-item" v-for="n in 5" :key="n">
                              <router-link to="/categories/1">
                                <span class="links-text">Bracelets</span>
                              </router-link>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="menu-item menu-item-c105 dropdown">
                  <router-link to="/categories/1">
                    <span class="links-text">Accesories</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li
            class="menu-item main-menu-item-3 dropdown mega-menu menu-fullwidth"
          >
            <router-link to="/categories/1" class="dropdown-toggle">
              <span class="links-text">Mega Menu</span>
              <span class="menu-label new">New</span>
            </router-link>
            <div class="dropdown-menu j-dropdown" id="collapse-62dd363fdcdce">
              <div class="mega-menu-content">
                <div class="content">
                  <div class="item1">
                    <h3 class="title module-title closed">
                      Top Categories
                    </h3>
                    <ul class="module-body">
                      <li
                        v-for="n in 44"
                        :key="n"
                        class="menu-item links-menu-item links-menu-item-1"
                      >
                        <router-link to="/categories/1">
                          <span class="links-text">Fashion</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="item2">
                    <div class="module-body">
                      <div class="module-item module-item-1">
                        <router-link to="/products/1">
                          <img
                            src="https://www.journal-theme.com/1/image/cache/catalog/journal3/banners/17-tall-220x280w.jpg.webp"
                            alt=""
                          />
                          <div class="banner-text banner-caption">
                            <span>Shop Fashion</span>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            class="menu-item main-menu-item-4 dropdown mega-menu menu-fullwidth mega-fullwidth"
          >
            <router-link
              to="/categories/1"
              class="dropdown-toggle"
              aria-expanded="false"
              data-toggle="dropdown"
            >
              <span class="links-text">Fullwidth</span>
            </router-link>
            <div class="dropdown-menu j-dropdown">
              <div class="mega-menu-content">
                <div class="content">
                  <div class="item1">
                    <div class="item-content" v-for="n in 6" :key="n">
                      <router-link to="/categories/1" class="catalog-title">
                        Fashion
                      </router-link>
                      <div class="item-assets image-left">
                        <router-link to="/categories/1" class="catalog-image">
                          <img
                            src="https://www.journal-theme.com/1/image/cache/catalog/journal3/categories/demo09-150x150.jpg.webp"
                            alt="Fashion"
                          />
                        </router-link>
                        <div class="subitems">
                          <div
                            class="subitem"
                            v-for="n in 4"
                            :key="n"
                            data-image="https://www.journal-theme.com/1/image/cache/catalog/journal3/products/sunglasses/glasses3-150x150.jpg.webp"
                          >
                            <router-link to="/products/1">
                              <span>Accesories</span>
                            </router-link>
                          </div>

                          <div class="subitem view-more">
                            <router-link to="/products">
                              <span>See all products</span>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item2">
                    <div class="module-body menu-full nobg">
                      <b-tabs align="center">
                        <b-tab title="Top Sellers" class="nav nav-tabs" active>
                          <swiper
                            :slides-per-view="2"
                            :breakpoints="breakpoints"
                            :space-between="5"
                            :modules="modules"
                            navigation
                            :pagination="{ clickable: true }"
                          >
                            <swiper-slide v-for="n in 10" :key="n">
                              <ProductCard />
                            </swiper-slide>
                          </swiper>
                        </b-tab>
                        <b-tab title="Special Deals" class="nav nav-tabs">
                          <swiper
                            :slides-per-view="2"
                            :breakpoints="breakpoints"
                            :space-between="5"
                            :modules="modules"
                            navigation
                            :pagination="{ clickable: true }"
                          >
                            <swiper-slide v-for="n in 10" :key="n">
                              <ProductCard />
                            </swiper-slide>
                          </swiper>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="main-menu main-menu-2">
        <ul class="">
          <li class="menu-item main-menu-item-1">
            <a href="tel:18005556787" class="">
              <span class="links-text">1.800.555.6789</span>
            </a>
          </li>
          <li class="menu-item main-menu-item-2">
            <router-link to="/blogs" class="blog">
              <span class="links-text">Blog</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
import ProductCard from '../layout/ProductCard.vue'
import ProductModal from '../products/ProductModal.vue'
export default {
  components: { Swiper, SwiperSlide, ProductCard, ProductModal },
  data() {
    return {
      modules: [Navigation, Pagination, A11y],
      quantity: 1,
      openProductModal: 0,
    }
  },
  methods: {
    closeModal() {
      this.openProductModal = 0
    },
  },
}
</script>

<style lang="scss">
.main-menu-wrapper {
  background-color: #0d52d6;
  height: 43px;
  .main-menu-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    .main-menu {
      > ul > li > a {
        &.dropdown-toggle {
          &::after {
            // content: unset;
            display: none;
          }
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        height: 100%;
        li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: 0.3s;
          height: 100%;
          position: relative;
          > a {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 13px;
            color: rgba(255, 255, 255, 1) !important;
            text-transform: uppercase;
            padding: 10px;
            padding-right: 15px;
            padding-left: 15px;
            display: flex;
            align-items: center;
            &.dropdown-toggle {
              &::after {
                margin-left: auto;
              }
            }
            .menu-label {
              margin-right: calc(0px - (-7px));
              margin-top: -7px;
            }
          }

          &:hover {
            background: #fff;
            > a {
              color: #333 !important;
            }
          }
        }
      }
      &.main-menu-1 {
        .main-menu-item-1 {
          min-width: 230px;
          background: rgba(254, 212, 48, 1);
          color: rgba(51, 51, 51, 1) !important;
          &:hover {
            background: #fff;
          }
          > a {
            color: rgba(51, 51, 51, 1) !important;

            &::before {
              content: '\e8d2' !important;
              font-family: icomoon !important;
              font-size: 20px;
              top: -1px;
              margin-right: 5px;
            }
            .menu-label {
              color: rgba(255, 255, 255, 1);
              font-weight: 400;
              text-transform: none;
              background: rgba(233, 102, 49, 1);
              padding: 1px;
              padding-right: 5px;
              padding-left: 5px;
            }
          }
          .j-dropdown {
            min-width: 230px;
          }
        }
        .main-menu-item-2 {
          .dropdown-menu {
            li {
              > a {
                color: rgba(58, 71, 84, 1) !important;
              }
            }
          }
        }
        .main-menu-item-3 {
          .mega-menu-content {
            .content {
              width: 100%;
              .item1 {
                width: 80%;
                padding-bottom: 20px;
                h3.title {
                  &::after {
                    display: none;
                  }
                }
                .module-body {
                  display: block;
                  column-count: 4;
                  column-gap: 30px;
                  column-rule-color: rgba(226, 226, 226, 1);
                  column-rule-width: 1px;
                  column-rule-style: solid;
                  li {
                    height: auto !important;
                    a {
                      height: auto !important;
                      justify-content: flex-start;
                      width: auto;
                      padding: 0;
                      padding-bottom: 4px;
                      padding-left: 10px;
                      .links-text {
                        color: rgba(105, 105, 115, 1) !important;
                        text-transform: capitalize;
                        font-weight: 400 !important;
                        font-size: 16px !important;
                        font-family: 'Roboto';
                      }
                      &:hover {
                        .links-text {
                          color: rgba(13, 82, 214, 1) !important;
                          text-decoration: underline !important;
                        }
                      }
                    }
                  }
                }
              }
              .item2 {
                width: 20%;
                padding: 20px 10px 20px 20px;
                border-width: 0;
                border-left-width: 1px;
                border-style: solid;
                border-color: rgba(226, 226, 226, 1);
                a {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  width: 100%;
                  height: auto !important;
                  transition: all 0.15s ease-out;
                  -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
                  overflow: hidden;
                  &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    pointer-events: none;
                    transition: all 0.15s ease-out;
                    z-index: 2;
                    border-radius: inherit;
                    background: rgba(0, 0, 0, 0.3);
                    opacity: 0;
                  }
                  &::after {
                    content: '\e5c8' !important;
                    font-family: icomoon !important;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 0.12s ease-out;
                    font-size: 35px;
                    color: #fff;
                  }
                  img {
                    max-width: 100%;
                    height: auto;
                  }
                  .banner-text.banner-caption {
                    position: absolute;
                    left: 0;
                    right: 0;
                    color: #fff;
                    line-height: 1.3;
                    z-index: 3;
                    transition: all 0.12s ease-out;
                    white-space: normal;
                    font-weight: 700;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 20px;
                    opacity: 0;
                    z-index: 500;
                  }
                  &:hover {
                    .banner-text.banner-caption {
                      opacity: 1;
                    }
                    &::before {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
        .main-menu-item-4 {
          .content {
            width: 100%;
            .item1 {
              width: 66.666666666%;
              padding: 20px;
              display: flex;
              flex-wrap: wrap;
              a {
                height: auto !important;
              }
              .item-content {
                padding: 10px;
                width: calc(100% / 3 - 0.01px);
                a.catalog-title {
                  padding: 0 !important;
                  margin: 0 auto 8px 0;
                  height: auto !important;
                  border: 0;
                }
                .item-assets {
                  flex-direction: row;
                  > a {
                    max-width: 40%;
                    margin-right: 10px;
                    img {
                      width: 100%;
                    }
                  }
                  .subitems {
                    width: 60%;
                  }
                }
              }
            }
            .item2 {
              width: 33.33333333%;
              padding: 20px;

              .nav {
                padding: 0 !important;
                flex-direction: row;
                margin-bottom: 0 !important;
              }
              .product-wrapper {
                // padding: 0 10px 0 0;
                overflow: visible !important;
                .product-thumb {
                  .image {
                    > a {
                      border: 0 !important;
                      height: auto !important;
                    }
                    .quickview-button {
                      a {
                        height: 40px !important;
                        .tooltips {
                          font-size: 13px !important;
                          font-family: 'Roboto';
                        }
                      }
                    }
                  }
                  .price {
                    margin: 0 !important;
                    padding: 0 8px 8px !important;
                    span {
                      font-size: 13px !important;
                      color: #333 !important;
                      font-weight: 400 !important;
                    }
                  }
                  .caption {
                    a {
                      height: auto !important;
                    }
                    .name {
                      margin: 0 !important;
                      padding: 0 !important;
                      a {
                        padding-top: 8px;
                        padding-bottom: 5px;
                        padding-left: 8px;
                        font-size: 14px;
                        font-weight: 'Roboto';
                      }
                    }
                  }
                  .buttons-wrapper {
                    a {
                      .tooltips {
                        font-size: 13px !important;
                        font-family: 'Roboto';
                      }
                    }

                    .button-group {
                      padding: 8px !important;
                      padding-bottom: 5px !important;
                      border-width: 0;
                      border-top-width: 1px;
                      border-style: solid;
                      border-color: rgba(226, 226, 226, 1);
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.main-menu-2 {
        .main-menu-item-1 {
          > a {
            font-size: 15px !important;
            &::before {
              content: '\eb97' !important;
              font-family: icomoon !important;
              font-size: 20px;
              margin-right: 5px;
            }
          }
        }
        .main-menu-item-2 {
          color: rgba(51, 51, 51, 1) !important;
          background: rgba(254, 212, 48, 1);
          > a {
            color: rgba(51, 51, 51, 1) !important;
            &::before {
              content: '\e946' !important;
              font-family: icomoon !important;
              margin-right: 7px;
              font-size: 18px;
            }
          }
          &:hover {
            background: #fff;
          }
        }
      }
      li.dropdown {
        position: relative;
        .dropdown-menu {
          a {
            height: 45px;
          }
          transition: all 0.3s linear ease-in !important;
          animation: from-top 0.3s linear forwards;
          border: 0;
          display: none;
          opacity: 0;
          li.dropdown {
            .dropdown-menu {
              left: 100%;
              top: 0;
            }
          }

          li.flyout-menu-item-1 > a::before {
            content: '\e8d2' !important;
            font-family: icomoon !important;
            font-size: 20px;
          }
        }
        .j-dropdown {
          top: 100%;
          padding: 0;
          z-index: 1000;
          border-radius: 0;
          transition: 0.3s;
          ul {
            flex-direction: column;
          }
        }
        &:hover {
          > .dropdown-menu {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }
}

.main-menu-wrapper
  .main-menu-container
  .main-menu.main-menu-1
  .main-menu-item-1
  > .dropdown-menu
  > .flyout-menu
  > .j-menu
  > li
  > a {
  color: rgba(105, 105, 115, 1) !important;
  font-weight: 400;
  background: rgba(240, 242, 245, 1);
  &:hover {
    color: rgba(51, 51, 51, 1) !important;
    background: rgba(255, 255, 255, 1) !important;
  }
}

.mega-menu {
  .menu-label {
    position: relative;
    margin: 0 7px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
    text-transform: none;
    background: rgba(233, 102, 49, 1);
    padding: 1px;
    padding-right: 5px;
    padding-left: 5px;
  }
  &.flyout-menu-item {
    .dropdown-menu {
      width: 800px;
    }
  }
  &.flyout-menu-item-1,
  &.flyout-menu-item-2 {
    .dropdown-menu {
      > div {
        background-position: right bottom;
        background-repeat: no-repeat;
      }
    }
  }
  &.flyout-menu-item-1 {
    .dropdown-menu {
      width: 900px;
      > div {
        background-image: url('https://www.journal-theme.com/1/image/cache/catalog/journal3/misc/menu2-252x312.png.webp');
      }
    }
  }
  &.flyout-menu-item-2 {
    .dropdown-menu {
      > div {
        background-image: url('https://www.journal-theme.com/1/image/cache/catalog/journal3/misc/menubg3-344x351.jpg.webp');
      }
    }
  }
  &.flyout-menu-item-3 {
    .mega-menu-content {
      padding: 0;
      .content {
        width: 100%;
      }
    }
    .item1 {
      width: 30% !important;
      padding: 0 !important;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        transition: all 0.15s ease-out;
        backface-visibility: hidden;
        overflow: hidden;
        padding-bottom: 0;
        height: 100% !important;
        img {
          height: 100%;
        }
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          pointer-events: none;
          transition: all 0.15s ease-out;
          z-index: 2;
          border-radius: inherit;
        }
        .banner-text.banner-caption {
          width: 100%;
          position: absolute;
          display: none;
          top: auto;
          left: 50%;
          right: auto;
          bottom: 0;
          transform: translate3d(-50%, 0, 0);
          color: #fff;
          line-height: 1.3;
          span {
            transform: scale(calc(100 / 100));
            transform-origin: center;
            white-space: normal;
            text-align: center;
            background: rgba(13, 82, 214, 1);
            margin: 15px;
            padding: 10px;
            display: block;
            padding-bottom: 12px;
            &::after {
              content: '\e5c8' !important;
              font-family: icomoon !important;
              top: 1px;
              margin-left: 5px;
            }
          }
        }
        &:hover {
          &::before {
            background: rgba(52, 59, 151, 0.2);
          }
          .banner-text.banner-caption {
            display: block;
          }
        }
      }
    }
    .item2 {
      width: 70% !important;
      .product-grid {
        display: flex;
        flex-wrap: wrap;
        .product-layout {
          padding: 10px;

          width: calc(100% / 4 - 0.01px);
          .product-thumb {
            padding: 0;
            border: 0;
            a {
              height: auto !important;
            }
            .image {
              .quickview-button {
                top: 0;
                left: 50%;
                transform: translate(-50%, 0) !important;
                margin: 0 !important;
                height: 34px !important;
                width: 34px !important;
                .btn-quickview {
                  border-radius: 0;
                  background: #0f398a !important;
                  padding-bottom: 0;
                  padding: 10px !important;
                }
              }
            }
            .caption {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              position: relative;
              .name {
                display: flex;
                width: 100%;
                padding-left: 0;
                justify-content: flex-start;
                margin-left: 0;
                margin-right: auto;
                margin-top: 5px;
                a {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-family: 'Montserrat';
                  font-weight: 600;
                  font-size: 12px !important;
                  color: rgba(51, 51, 51, 1);
                  padding-bottom: 0;
                }
              }
              .price {
                display: block;
                font-size: 12px;
                color: rgba(105, 105, 115, 1);
                text-align: left;
                width: auto;
                margin-left: 0;
                margin-bottom: 0;
                text-align: start;
                justify-content: flex-start;
                margin-top: 3px;
                padding-left: 0;
                > div {
                  display: flex;
                  flex-direction: row-reverse;
                  justify-content: flex-end;
                  span {
                    font-size: 12px !important;
                    font-family: 'Roboto';
                    &.price-new {
                      color: rgba(221, 14, 28, 1);
                    }
                  }
                }
              }
            }
            .buttons-wrapper {
              position: absolute;
              display: none;
              width: 100%;
              left: 50%;
              transform: translate3d(-50%, -100%, 1px);
              .button-group {
                background: rgba(15, 58, 141, 1);
                position: relative;
                justify-content: center;
                height: 30px !important;
                .btn-cart {
                  width: 30px;
                  height: 30px !important;
                }
                .wish-group {
                  position: static;
                  width: auto;
                  top: auto;
                  visibility: visible;
                  opacity: 1;
                  transform: translate3d(0, 0, 0);
                  justify-content: flex-start;
                }
                .btn-wishlist,
                .btn-compare {
                  width: 30px !important;
                  height: 30px !important;
                  display: inline-flex;
                }
                .btn-cart,
                .btn-wishlist,
                .btn-compare {
                  padding: 13px 18px !important;
                  &::before {
                    display: inline-block;
                    font-size: 13px;
                    left: 2px;
                    margin-right: 2px;
                    line-height: 1;
                    color: #fff !important;
                  }
                  &:hover {
                    color: rgba(255, 255, 255, 1) !important;
                    background: rgba(13, 82, 214, 1) !important;
                  }
                }
                .btn {
                  background: rgba(15, 58, 141, 1);
                  border-width: 2px;
                  padding: 13px;
                  padding-right: 18px;
                  padding-left: 18px;
                }
              }
              .tooltip-parent {
                z-index: 10;
                .tooltips {
                  font-size: 10px !important;
                }
              }
            }
            &:hover {
              .buttons-wrapper {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &.flyout-menu-item-4 {
    .mega-menu-content {
      padding: 0;
      .block-wrapper {
        padding: 0;
        p {
          font-family: 'Roboto';
        }
      }
      .block-footer {
        padding-top: 10px;
        margin-top: 10px;
        border-width: 0;
        border-top-width: 1px;
        border-style: solid;
        border-color: rgba(226, 226, 226, 1);
        text-align: left;
        a {
          width: auto;
          transform: scale(calc(80 / 100));
          transform-origin: top left;
          line-height: 1;
          padding: 13px 18px;
          font-size: 13px !important;
          color: #fff !important;
        }
      }
      .product-grid {
        display: flex;
        flex-wrap: wrap;
        .product-layout {
          padding: 10px;

          width: calc(100% / 4 - 0.01px);
          .product-thumb {
            padding: 0;
            border: 0;
            a {
              height: auto !important;
            }
            .image {
              .quickview-button {
                top: 0;
                left: 50%;
                transform: translate(-50%, 0) !important;
                margin: 0 !important;
                height: 34px !important;
                width: 34px !important;
                .btn-quickview {
                  border-radius: 0;
                  background: #0f398a !important;
                  padding-bottom: 0;
                  padding: 10px !important;
                }
              }
            }
            .caption {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              position: relative;
              .name {
                display: flex;
                width: 100%;
                padding-left: 0;
                justify-content: flex-start;
                margin-left: 0;
                margin-right: auto;
                margin-top: 5px;
                a {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-family: 'Montserrat';
                  font-weight: 600;
                  font-size: 12px !important;
                  color: rgba(51, 51, 51, 1);
                  padding-bottom: 0;
                }
              }
              .price {
                display: block;
                font-size: 12px;
                color: rgba(105, 105, 115, 1);
                text-align: left;
                width: auto;
                margin-left: 0;
                margin-bottom: 0;
                text-align: start;
                justify-content: flex-start;
                margin-top: 3px;
                padding-left: 0;
                > div {
                  display: flex;
                  justify-content: flex-end;
                  span {
                    font-size: 12px !important;
                    font-family: 'Roboto';
                    &.price-new {
                      color: rgba(221, 14, 28, 1);
                    }
                  }
                }
              }
            }
            .buttons-wrapper {
              position: absolute;
              display: none;
              width: 100%;
              left: 50%;
              transform: translate3d(-50%, -100%, 1px);
              .button-group {
                background: rgba(15, 58, 141, 1);
                position: relative;
                justify-content: center;
                height: 30px !important;
                .btn-cart {
                  width: 30px;
                  height: 30px !important;
                }
                .wish-group {
                  position: static;
                  width: auto;
                  top: auto;
                  visibility: visible;
                  opacity: 1;
                  transform: translate3d(0, 0, 0);
                  justify-content: flex-start;
                }
                .btn-wishlist,
                .btn-compare {
                  width: 30px !important;
                  height: 30px !important;
                  display: inline-flex;
                }
                .btn-cart,
                .btn-wishlist,
                .btn-compare {
                  padding: 13px 18px !important;
                  &::before {
                    display: inline-block;
                    font-size: 13px;
                    left: 2px;
                    margin-right: 2px;
                    line-height: 1;
                    color: #fff !important;
                  }
                  &:hover {
                    color: rgba(255, 255, 255, 1) !important;
                    background: rgba(13, 82, 214, 1) !important;
                  }
                }
                .btn {
                  background: rgba(15, 58, 141, 1);
                  border-width: 2px;
                  padding: 13px;
                  padding-right: 18px;
                  padding-left: 18px;
                }
              }
              .tooltip-parent {
                z-index: 10;
                .tooltips {
                  font-size: 10px !important;
                }
              }
            }
            &:hover {
              .buttons-wrapper {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &.flyout-menu-item-5 {
    .mega-menu-content {
      padding: 0 !important;
    }
    .content {
      .top {
        padding: 20px;
        display: flex;
        width: 100%;
        .item {
          width: 20%;
          .links-menu-item {
            a {
              padding: 0;
              display: inline-flex;
              justify-content: flex-start;
              width: auto;
              padding-bottom: 4px;
              font-weight: 400;
              text-transform: capitalize;
              span {
                font-size: 16px;
              }
            }
          }
          &.item1 {
            width: 40%;
            ul {
              flex-direction: row;
              li {
                padding: calc(10px / 2);
                a {
                  padding: 0;
                  background: rgba(240, 242, 245, 1) !important;
                  width: 80px !important;
                  height: 80px !important;
                  &::before {
                    line-height: 1;
                  }
                  span {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .bottom {
      background: rgba(240, 242, 245, 1);
      ul {
        flex-direction: row !important;
        justify-content: flex-start;
        li {
          height: auto !important;
          width: fit-content;
          a {
            color: rgba(13, 82, 214, 1) !important;
            font-size: 13px !important;
          }
        }
        .inks-menu-item-1 {
          a {
            color: #333 !important;
          }
        }
      }
    }
  }
  position: static !important;
  &.menu-fullwidth .dropdown-menu {
    position: absolute;
    left: 0;
    width: 100%;
  }
  &.menu-fullwidth.mega-fullwidth {
    .dropdown-menu {
      position: absolute;
      width: 100vw;
      left: 50%;
      margin-left: -50vw;
    }
  }
}

.flyout-menu-7 > ul.j-menu > li.flyout-menu-item-6 > a .menu-label {
  position: relative !important;
  background: rgba(80, 173, 85, 1) !important;
  padding: 1px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  margin: 0 7px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-family: 'Roboto';
  color: #fff !important;
}

.mega-menu-content {
  padding: 10px;
  display: flex;
  .content {
    display: flex;
    flex-wrap: wrap;
    .item {
      padding: 10px;
      width: calc(100% / 3 - 0.01px);
      &.item1 {
        a {
          padding-bottom: 0 !important;
          height: 100% !important;
        }
      }
      a {
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-bottom: 6px;
        background: none !important;
        height: auto !important;
        &.catalog-title {
          color: rgba(51, 51, 51, 1) !important;
          font-weight: bold !important;
          text-transform: uppercase;
          margin-bottom: 8px;
          padding: 0;
          border: 0;
        }
        &:hover {
          color: #23527c !important;
          text-decoration: underline !important;
        }
      }
      .subitem {
        &.view-more {
          a span {
            color: rgba(105, 105, 115, 1) !important;
            font-weight: 700;
            text-decoration: underline;
            &:hover {
              color: #23527c !important;
              text-decoration: underline !important;
            }
          }
        }
      }
    }
  }
}
.menu-label {
  position: absolute;
  font-size: 10px;
  top: 0;
  right: 0;
}
@keyframes from-top {
  0% {
    transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.item-assets {
  display: flex;
  flex: 1;
}

.is-rtl {
  .main-menu ul {
    li {
      > a {
        &.dropdown-toggle {
          &::after {
            margin-left: unset !important;
            margin-right: auto !important;
          }
        }
      }
    }
  }
  .main-menu-wrapper
    .main-menu-container
    .main-menu.main-menu-1
    .main-menu-item-1
    > a::before {
    margin: 0 0 0 5px;
  }
  .main-menu-wrapper
    .main-menu-container
    .main-menu
    li.dropdown
    .dropdown-menu
    li
    > a::before {
    margin: 0 0 0 5px;
  }
}
</style>
