<template>
  <div class="product-wrapper" v-if="items">
    <div class="product-thumb has-countdown">
      <div class="image">
        <div class="quickview-button">
          <a
            role="button"
            @click="openModal(items.id)"
            @addTocart="placeOrder"
            class="btn-primary btn-quickview tooltip-parent"
          >
            <span class="tooltips">Quickview</span>
          </a>
        </div>
        <Teleport to="body">
          <ProductModal
            v-if="openProductModal == items.id"
            :mode="extraOption"
            :items="items"
            :quantity="item.qty"
            @closeModal="closeModal"
            @addToCart="placeOrder"
          />
        </Teleport>
        <teleport to="body">
          <transition name="fade-in">
            <Notification
              :imgSrc="imgSrc"
              :message="message"
              :mode="mode"
              @closeModal="closeNotfiy"
              v-if="openNotification == items.id"
            />
          </transition>
        </teleport>

        <router-link to="/products/1" class="product-img has-second-image">
          <div v-if="items.images">
            <img
              :src="items.images[0].path"
              alt="Headphones"
              class="img-responsive img-first swiper-lazy"
            />

            <img
              :src="items.images[1].path"
              alt="Headphones"
              class="img-responsive img-second swiper-lazy"
            />
          </div>
        </router-link>

        <div class="product-labels">
          <span
            v-for="item in items.status"
            :key="item.id"
            class="product-label highlight"
            :class="[item.name, item.type]"
          >
            <b>{{ item.value }}</b>
          </span>
        </div>
        <CountDown :date="items.countDownDate" @onFinish="finish()" />
      </div>

      <div class="caption">
        <div class="stats">
          <span class="stat-1">
            <span class="stats-label">Brand:</span>
            <span>
              <router-link to="/categories/1">{{ items.brand }}</router-link>
            </span>
          </span>
          <span class="stat-2">
            <span class="stats-label">Model:</span>
            <span>{{ items.model }}</span>
          </span>
        </div>

        <div class="name">
          <router-link to="/products/1">
            {{ items.name }}
          </router-link>
        </div>
        <div class="description">
          {{ items.description }}
        </div>
        <div class="price">
          <div>
            <span v-if="items.price_normal" class="price-normal">
              ${{ items.price_normal }}
            </span>
            <span v-if="items.price_old" class="price-old">
              ${{ items.price_old }}
            </span>
          </div>
          <span class="price-tax" v-if="items.tax">
            Ex Tax:${{ items.tax }}
          </span>
        </div>
        <div class="buttons-wrapper">
          <div class="button-group d-flex flex-wrap">
            <div
              class="cart-group d-flex justify-content-between flex-wrap h-100"
            >
              <div class="stepper">
                <input
                  type="text"
                  name="quantity"
                  v-model="item.qty"
                  class="form-control"
                />
                <span>
                  <i
                    class="fa-solid fa-angle-up"
                    @click="changeQuantity(1)"
                  ></i>
                  <i
                    class="fa-solid fa-angle-down"
                    @click="changeQuantity(-1)"
                  ></i>
                </span>
              </div>
              <button
                class="btn btn-primary"
                @click="addToCart(items, items.id, items)"
              >
                <span class="btn-text">
                  Add to Cart
                </span>
              </button>
              <Teleport to="body">
                <ProductModal
                  v-if="openProductModal == items.id"
                  :mode="extraOption"
                  @closeModal="closeModal"
                />
              </Teleport>
            </div>

            <div class="wish-group d-flex justify-content-between flex-wrap">
              <a
                role="button"
                class="btn-wishlist tooltip-parent"
                @click="addToWashList(items)"
              >
                <span class="tooltips">
                  Add to Wish List
                </span>
              </a>

              <a
                role="button"
                class="btn-compare tooltip-parent"
                @click="addToCompare(items)"
              >
                <span class="tooltips">
                  Compare this Product
                </span>
              </a>
            </div>
          </div>
        </div>

        <div class="extra-group">
          <div>
            <a role="button" class="btn-extra btn-extra-46">
              <span class="btn-text">Buy Now</span>
            </a>
            <a
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#faqQuestion"
              class="btn-extra btn-extra-93"
            >
              <span class="btn-text">Question</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductModal from '../products/ProductModal.vue'
import CountDown from '../CountDown.vue'
import Notification from '../products/Notification.vue'

export default {
  props: ['items'],
  components: { ProductModal, CountDown, Notification },
  data() {
    return {
      openProductModal: 0,
      openNotification: 0,
      extraOption: false,
      imgSrc: '',
      mode: '',
      message: '',
      item: {
        qty: 1,
        totalPrice: 0,
        book: null,
      },
    }
  },

  computed: {
    productPrice() {
      return this.items.price_normal
    },
  },

  methods: {
    closeModal() {
      this.openProductModal = 0
    },
    changeQuantity(n) {
      if (this.item.qty <= 1 && n == -1) return
      this.item.qty += n
    },
    openModal(id) {
      this.openProductModal = id
      if (this.openProductModal == id) {
        this.items[id]
        this.extraOption = false
        this.openProductModal = id
      }
    },
    closeNotfiy() {
      this.openNotification = 0
    },
    placeOrder(e) {
      this.mode = ''
      this.message = ''
      this.imgSrc = ''
      this.$store.commit('addToCart', e)
      this.mode = 'addToCart'
      this.openNotification = e.product.id
      this.message = `<h4>You have added <a> ${e.product.name}</a> Successfully</h4>`
      this.imgSrc = e.product.images[0].path
      setTimeout(() => {
        this.openNotification = 0
      }, 3000)
    },
    addToCart(extra, id, e) {
      this.openProductModal = id
      if (this.openProductModal == id) {
        const isEmpty = Object.keys(extra.extraOptions).length
        if (isEmpty != 0) {
          this.openProductModal = id
          this.extraOption = true
          return
        } else if (isEmpty == 0) {
          this.openProductModal = 0
          this.extraOption = false
          this.placeOrder({ product: e, qty: this.item.qty })
        }
      }
    },
    addToWashList(e) {
      this.imgSrc = ''
      this.message = ''
      this.mode = ''
      this.$store.commit('addToWashlist', e)
      this.openNotification = e.id
      this.imgSrc = `${e.images[0].path}`
      this.message = `<h4>You have added <a> ${e.name}</a> Successfully</h4>`
      this.mode = ''

      setTimeout(() => {
        this.openNotification = 0
      }, 3000)
    },
    addToCompare(e) {
      this.imgSrc = ''
      this.message = ''
      this.mode = ''
      this.$store.commit('addToCompare', e)
      this.openNotification = e.id
      this.imgSrc = `${e.images[0].path}`
      this.message = `<h4>You have added <a> ${e.name}</a> Successfully</h4>`
      this.mode = ''

      setTimeout(() => {
        this.openNotification = 0
      }, 3000)
    },
  },
}
</script>

<style lang="scss">
.product-wrapper {
  // padding: 0 10px 0 0;
  overflow: hidden;
  width: 100%;
}
.product-thumb {
  background: rgba(255, 255, 255, 1);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(226, 226, 226, 1);
  border-radius: 7px;
  position: relative;
  width: 100%;
  height: 100%;

  &:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    .product-img > div {
      transform: scale(1.12);
      img {
        &.img-first {
          visibility: hidden;
        }
        &.img-second {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .btn-quickview {
      visibility: visible !important;
      opacity: 1 !important;
      transform: scale(1);
    }
  }

  .image {
    position: relative;
    overflow: hidden;

    .product-img {
      display: block;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(226, 226, 226, 1);
      overflow: hidden;
      & > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.25s ease-out;
      }
      img {
        border-radius: 5px;
        pointer-events: none;
        transition: all 0.2s ease-out;
        width: 100%;
        height: auto;
        background: rgba(238, 238, 238, 1);
        &.img-second {
          position: absolute;
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }
  .caption {
    .stats {
      display: flex;
      justify-content: space-between;
      position: relative;
      font-size: 14px;
      color: rgba(139, 145, 152, 1);
      background: rgba(240, 242, 245, 1);
      padding: 9px;
      .stats-label {
        display: none;
      }
      a {
        color: rgba(139, 145, 152, 1) !important;
        text-decoration: underline !important;
        &:hover {
          color: rgba(233, 102, 49, 1) !important   ;
        }
      }
    }
    .name {
      display: flex;
      justify-content: flex-start;
      padding-left: 10px;
      margin-top: 15px;
      a {
        font-size: 16px !important;
        color: rgba(51, 51, 51, 1) !important;
        font-weight: 700;
      }
    }
    .price {
      margin-top: 5px;
      margin-bottom: 15px;
      color: rgb(51, 51, 51);
      text-align: start;
      padding-left: 10px;
      > div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }

      span {
        font-family: 'Roboto' !important;
        font-weight: 700;
        font-size: 22px;
      }
      .price-new {
        margin: 0 7px 0 0;
        font-size: 16px;
        order: 1;
      }
      .price-normal {
        font-size: 16px;
        margin: 0 7px 0 0;
        order: 1;
      }
      .price-old {
        font-size: 13px;
        text-decoration: line-through;
        color: rgba(139, 145, 152, 1);
      }
      .price-tax {
        font-size: 12px;
        color: rgba(105, 105, 115, 1);
        display: none;
      }
    }
    .description {
      display: none;
      font-size: 14px;
      color: rgba(105, 105, 115, 1);
      line-height: 1.5;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .buttons-wrapper {
      .button-group {
        padding-bottom: 15px;
        padding-left: 10px;
        justify-content: space-between;

        .stepper {
          display: inline-flex;
          height: 37px;
          margin: 0 5px 0 0;
          border-color: rgba(226, 226, 226, 1);
          + button {
            height: 37px;
          }
          input {
            width: 100%;
            height: 100%;
            min-height: 28px;
            border-radius: inherit !important;
            border-width: 0;
            text-align: center;
            padding: 0 20px 0 2px;
            font-size: 13px;
            transition: all 0.05s ease-out;
          }
          span {
            i {
              background: rgb(230, 230, 230);
            }
          }
        }
      }
      .btn-text {
        font-size: 14px;
      }
    }
    .extra-group {
      background: rgba(240, 242, 245, 1);
      padding: 9px;
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
      & > div {
        display: flex;
        justify-content: space-between;
        a {
          color: rgb(139, 145, 152) !important;
          font-size: 14px;
          font-family: 'Roboto';
          padding: 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.btn-extra-93::before {
            color: rgba(221, 14, 28, 1);
          }
          &.btn-extra-46::before {
            color: rgba(80, 173, 85, 1);
          }
          .btn-text {
            padding: 0 4.5px;
          }
          &:hover {
            .btn-text {
              color: rgba(233, 102, 49, 1) !important;
            }
          }
        }
      }
    }
  }
  .btn-wishlist,
  .btn-compare {
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 3px;

    &::before {
      display: inline-block;
      font-size: 20px;
      color: rgba(139, 145, 152, 1);
    }
    &:hover {
      &::before {
        color: rgba(233, 102, 49, 1) !important;
      }
    }
  }
}
.quickview-button {
  display: flex;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  transform: translate(-50%, -50%) !important;
  margin-right: 5px;
  margin-bottom: 5px;
  position: absolute;
  z-index: 4;
  padding: 0 !important;
  cursor: pointer;
  .btn-quickview {
    visibility: hidden;
    opacity: 0;
    transform: scale(0.88);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    &::before {
      display: inline-block;
      content: '\ebef' !important;
      font-family: icomoon !important;
      font-size: 18px;
      color: #fff !important;
    }
  }
}
.product-labels {
  visibility: visible;
  opacity: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  .product-label {
    &.highlight {
      &.default {
        display: flex;
        margin-top: 5px;
        margin-right: 5px;
        position: relative;
        justify-content: flex-end;
      }
      &.diagonal {
        top: 0;
        left: 0;
        width: 300px;
        transform: translate3d(-130px, 8px, 0) rotate(-45deg);
        font-size: 15px;
        text-align: center;
        position: absolute;
        margin: 0;
        padding: 0;
        margin: 25px;
        & > b {
          transform: scale(calc(10 / 10));
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      b {
        min-width: 45px;
        transform: scale(calc(10 / 10));
      }
      &.hot {
        b {
          color: rgba(51, 51, 51, 1);
          background: rgba(254, 212, 48, 1);
        }
      }
      &.new {
        b {
          background: rgba(13, 82, 214, 1);
        }
      }
      &.discound {
        b {
          background: rgba(221, 14, 28, 1);
        }
      }
      &.free {
        margin: 0 !important;
        b {
          background: rgba(80, 173, 85, 1);
        }
      }
      &.top-brand {
        order: 1;
        b {
          font-size: 11px;
          color: rgba(255, 255, 255, 1);
          font-weight: 700;
          text-transform: uppercase;
          border-radius: 3px;
          padding: 5px;
          padding-bottom: 6px;
          background: rgba(233, 102, 49, 1);
        }
      }
    }

    b {
      font-size: 11px;
      color: rgba(255, 255, 255, 1);
      font-weight: 700;
      text-transform: uppercase;
      background: rgba(13, 82, 214, 1);
      border-radius: 3px;
      padding: 5px;
      padding-bottom: 6px;
    }
  }
}
.countdown {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  transform: translate3d(-50%, 0, 1px) scale(1);
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 15px;
  color: rgba(51, 51, 51, 1);
  background: rgba(240, 242, 245, 1);
  border-radius: 3px;
  width: 90%;
  bottom: 35px;
  position: absolute;
  left: 50%;
  pointer-events: none;
  z-index: 2;
  margin: 0 !important;
  & > div {
    border-style: solid;
    border-color: rgba(226, 226, 226, 1);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1px;
    border-width: 0;
    &:not(:last-child) {
      border-right-width: 1px;
    }
    span {
      color: rgba(105, 105, 115, 1);
      font-size: 11px;
    }
  }
}
.mega-menu {
  .product-thumb {
    padding: 5px;
    .countdown {
      display: none;
    }
    .caption {
      .stats {
        display: none;
      }
      .name {
        a {
          color: rgba(13, 82, 214, 1) !important;
          text-decoration: none !important;
          font-size: 14px !important;
          &:hover {
            color: rgba(233, 102, 49, 1) !important ;
          }
        }
      }
      .extra-group {
        display: none;
      }
      .buttons-wrapper {
        .button-group {
          padding: 0 !important;
          .cart-group {
            & > button {
              padding: 2px 6px !important;
              line-height: 1;
              span {
                font-size: 12px !important;
              }
            }
          }
          .wish-group {
            a {
              width: 35px !important;
              height: 35px !important;
            }
          }
        }
        .stepper {
          display: none !important;
        }
      }
    }
  }
}
.vertical {
  .product-thumb {
    padding-bottom: 25px;
    border: 0;
    @media (min-width: 768px) {
      display: flex;
    }
    .image {
      flex: 1;
    }
    .caption {
      flex: 2;
      padding-left: 20px;
      .stats {
        margin-bottom: 10px;
        background: none;
        padding: 0;
        justify-content: flex-start;
        & > span {
          padding: 0;
          padding-right: 1rem;
        }
        .stats-label {
          display: inline;
          padding: 0 !important;
        }
      }
      .description {
        display: block;
      }
      .name {
        padding-left: 0;
      }
      .price {
        padding-left: 0;
        .price-new {
          color: rgba(233, 102, 49, 1);
          font-size: 22px;
        }

        .price-normal {
          font-size: 22px;
        }
        .price-tax {
          display: block;
        }
      }
      .stepper {
        height: 38px !important;
        + button {
          height: 38px;
        }
      }
      .buttons-wrapper {
        .button-group {
          padding-left: 0;
          justify-content: flex-start;
        }
      }
      .extra-group {
        background: none;
        padding: 0;
        & > div {
          justify-content: flex-start;
        }
      }
    }
  }
}
.product-thumb.has-countdown {
  .countdown {
    @media (min-width: 992px) {
      visibility: visible;
      opacity: 1;
    }
  }
}
.is-rtl {
  .vertical .product-thumb .caption {
    padding: 0 20px 0 0;
  }
  .product-thumb {
    .caption {
      .name {
        justify-content: flex-end !important;
        padding-left: 0px;
        padding-right: 10px;
      }
      .price {
        padding-right: 10px;
        padding-left: 0px;
        > div {
          align-items: center;
          justify-content: flex-start;
        }

        .price-new {
          margin: 0 7px 0 0;
        }
        .price-normal {
          margin: 0 7px 0 0;
        }
      }

      .buttons-wrapper {
        .button-group {
          padding-left: 0px;
          padding-right: 10px;
          flex-direction: row-reverse;

          .stepper {
            margin: 0 0 0 5px;

            input {
              padding: 0 20px 0 2px;
            }
          }
        }
      }
    }
  }
}
.price-normal {
  font-size: 16px;
  margin: 0 7px 0 0;
  order: 1;
}
.price-old {
  font-size: 13px;
  text-decoration: line-through;
  color: rgba(139, 145, 152, 1);
}
.price-tax {
  font-size: 12px;
  color: rgba(105, 105, 115, 1);
  display: none;
}
.fade-in-enter,
.fade-in-leave-to {
  z-index: 5000;
  transform: scale(0.5);
}
.fade-in-enter-active,
.fade-in-leave-active {
  transition: all 0.3s ease;
  transform: scale(0.5);
}
.fade-in-enter-to,
.fade-in-leave {
  opacity: 1;
  transform: scale(1);
}
.fadeInLeft {
  animation: fadeInLeft 0.8s forwards;
}
.fadeInRight {
  animation: fadeInRight 0.8s forwards;
}
</style>
