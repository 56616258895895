<template>
  <form class="form-horizontal">
    <fieldset>
      <legend>Your Password</legend>
      <div class="form-group row">
        <label class="col-sm-2 control-label" for="input-password">
          Password
        </label>
        <div class="col-sm-10">
          <input
            type="password"
            name="password"
            value=""
            placeholder="Password"
            id="input-password"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 control-label" for="input-confirm">
          Password Confirm
        </label>
        <div class="col-sm-10">
          <input
            type="password"
            name="confirm"
            value=""
            placeholder="Password Confirm"
            id="input-confirm"
            class="form-control"
          />
        </div>
      </div>
    </fieldset>
    <div class="buttons mt-5">
      <div class="pull-left">
        <router-link to="/account/myAccount" class="btn btn-default">
          Back
        </router-link>
      </div>
      <div class="pull-right">
        <button type="submit" class="btn btn-primary">
          <span>Continue</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {}
</script>

<style></style>
