<template>
  <div class="well">
    <h2 class="title">Returning Customer</h2>
    <form @submit.prevent>
      <div class="form-group">
        <label class="control-label" for="input-email">E-Mail Address</label>
        <input
          type="text"
          name="email"
          value=""
          placeholder="E-Mail Address"
          id="input-email"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label class="control-label" for="input-password">Password</label>
        <input
          type="password"
          name="password"
          value=""
          placeholder="Password"
          id="input-password"
          class="form-control"
        />
      </div>
      <div class="text-start">
        <router-link to="/account/resetPassword" class="forgot-passsword">
          Forgotten Password
        </router-link>
      </div>
      <div class="buttons">
        <div class="pull-right">
          <button type="submit" class="btn btn-primary">
            <span>Login</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.well{
  padding: 20px;
}
</style>
