import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Account from "../views/Account.vue";
import Products from "../views/Products.vue";
import Checkout from "../views/Checkout.vue";
import AboutUs from "../views/About.vue";
import Cart from "../views/Cart.vue";
import Compare from "../views/Compare.vue";
import Blogs from "../views/Blogs.vue";
import Faq from "../views/Faq.vue";
import Delivery from "../views/Delivery.vue";
import Privacy from "../views/Privacy.vue";
import Terms from "../views/Terms.vue";
import Contact from "../views/Contact.vue";
import Swiper from "@/components/products/ProductSwiper.vue";
import LightBox from "@/components/LightBox.vue";
import Categories from "../views/Categories.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/account/:slug?/:actions?",
    name: "account",
    component: Account,
  },
  {
    path: "/products/:id?",
    name: "products",
    component: Products,
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
  },
  {
    path: "/lightbox",
    name: "lightbox",
    component: LightBox,
  },
  {
    path: "/delivery",
    name: "delivery",
    component: Delivery,
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/about_us",
    name: "about_us",
    component: AboutUs,
  },
  {
    path: "/compare",
    name: "compare",
    component: Compare,
  },
  {
    path: "/blogs/:id?",
    name: "blogs",
    component: Blogs,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: Checkout,
  },
  {
    path: "/swiper",
    name: "swiper",
    component: Swiper,
  },
  {
    path: "/categories/:id?",
    name: "categories",
    component: Categories,
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
