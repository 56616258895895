<template>
  <div class="reset-password h-100">
    <p>
      Enter the e-mail address associated with your account. Click submit to
      have a password reset link e-mailed to you.
    </p>
    <form @submit.prevent>
      <legend>Your E-Mail Address</legend>
      <div class="form-group">
        <label class="control-label" for="input-email">
          E-Mail Address
        </label>

        <input
          type="text"
          name="email"
          value=""
          placeholder="E-Mail Address"
          id="input-email"
          class="form-control"
        />
      </div>

      <div class="buttons">
        <div class="pull-left">
          <router-link to="/account/login" class="btn btn-default d-block">
            Back
          </router-link>
        </div>
        <div class="pull-right">
          <button type="submit" class="btn btn-primary">
            <span>Continue</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.reset-password {
  & > p {
    text-align: start;
  }
  form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    legend {
      text-align: start;
    }
  }
}
.buttons {
  display: flex;
  flex-wrap: wrap;

  .pull-left {
    a {
      color: #fff !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-size: 13px !important;
      text-decoration: none !important;
      height: 100%;
    }
  }
}
</style>
