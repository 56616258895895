<template>
  <div class="about">
    <div class="page-title-header">
      <h1 class="title page-title"><span>About Us</span></h1>
    </div>
    <div class="container">
      <div id="information-information">
        <div class="d-flex flex-wrap">
          <div id="right-column">
            <div class="content">
              <p>
                ince 2013, has been the best selling and most loved OpenCart
                theme on the market. Now at version 3, it brings many new and
                revolutionary features such as an advanced page builder with 30+
                multi-purpose modules that can be added on any page in any grid
                layout configuration, as well as the best possible customizable
                options for any area of your store.
              </p>
              <p>
                Journal 3 also comes with fully customizable CSS options with
                the added possibility of setting each option differently on any
                breakpoint. This breakthrough feature will greatly enhance your
                design skills and allow you to create pixel perfect layouts at
                any screen width.
              </p>
              <p>
                The new advanced Status mechanism allow you to set up modules
                and menus based on device, customer login status or customer
                groups.
                <br />
              </p>
              <p>
                The new Schedule feature&nbsp;allows you to display any module
                at specific dates in the future, or to disable any module
                automatically at a certain time and date. Imagine the
                possibilities and peace of mind...designing your promotional
                banners or sliders, or entire product modules that only show up
                on specific dates and are disabled automatically whenever you
                don't need them anymore.&nbsp;
              </p>
              <p>
                These are just a few of the outstanding features available in
                the new Journal 3 framework, there are so many new options and
                possibilities that it will takes us a very long time to list
                them all.
              </p>

              <p>
                <a class="btn" href="#" target="_blank">
                  Buy Journal
                </a>
              </p>
            </div>
          </div>
          <PagesAside />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PagesAside from '@/components/PagesAside.vue'

export default {
  components: { PagesAside },
}
</script>

<style lang="scss">
.about {
  p {
    color: #333 !important;
    font-family: 'Roboto';
    &:hover {
      color: #333 !important;
    }
  }
}
.side-column {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  > div {
    margin-bottom: 30px;
  }
  .banners {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      transition: all 0.15s ease-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        transition: all 0.15s ease-out;
        z-index: 2;
        border-radius: inherit;
        background: rgba(52, 59, 151, 0.2);
        opacity: 0;
      }
      .banner-text {
        width: 100%;
        display: block;
        top: auto;
        left: 50%;
        right: auto;
        bottom: 0;
        transform: translate3d(-50%, 0, 0);
        position: absolute;
        text-align: center;
        color: #fff;
        line-height: 1.3;
        z-index: 3;
        transition: all 0.12s ease-out;
        opacity: 0;
        span {
          transform: scale(calc(100 / 100));
          transform-origin: center;
          white-space: normal;
          text-align: center;
          background: rgba(13, 82, 214, 1);
          margin: 15px;
          padding: 10px;
          padding-bottom: 12px;
          display: block;
        }
      }
      img {
        width: 100%;
        height: auto;
        transition: all 0.2s ease;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
        .banner-text {
          opacity: 1;
        }
      }
    }
  }
}
.side-gallery {
  .gallary-body {
    display: flex;
    flex-wrap: wrap;

    .item {
      padding: 5px;
      width: calc(100% / 3 - 0.01px);
      a {
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        overflow: hidden;
        .gallery-image {
          width: 100%;
          img {
            width: 100%;
          }
        }
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          pointer-events: none;
          transition: all 0.1s ease-out;
          opacity: 0;
          background-color: rgba(51, 55, 154, 0.45);
        }
        &::after {
          display: block;
          content: '\eba0' !important;
          font-family: icomoon !important;
          color: rgba(255, 255, 255, 1);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0) scale(0.5);
          transition: all 0.1s ease-out;
          opacity: 0;
          font-size: 35px;
        }
      }
      &:hover {
        a {
          &::after {
            opacity: 1;
          }
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
