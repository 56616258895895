<template>
  <form
    action="https://www.journal-theme.com/1/index.php?route=account/address/add"
    method="post"
    enctype="multipart/form-data"
    class="form-horizontal"
  >
    <div id="address">
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="input-firstname">
            First Name
          </label>
        </div>
        <div class="col-sm-10">
          <input
            type="text"
            name="firstname"
            value=""
            placeholder="First Name"
            id="input-firstname"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="input-lastname">
            Last Name
          </label>
        </div>
        <div class="col-sm-10">
          <input
            type="text"
            name="lastname"
            value=""
            placeholder="Last Name"
            id="input-lastname"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="input-company">
            Company
          </label>
        </div>
        <div class="col-sm-10">
          <input
            type="text"
            name="company"
            value=""
            placeholder="Company"
            id="input-company"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="input-address-1">
            Address 1
          </label>
        </div>
        <div class="col-sm-10">
          <input
            type="text"
            name="address_1"
            value=""
            placeholder="Address 1"
            id="input-address-1"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="input-address-2">
            Address 2
          </label>
        </div>
        <div class="col-sm-10">
          <input
            type="text"
            name="address_2"
            value=""
            placeholder="Address 2"
            id="input-address-2"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="input-city">City</label>
        </div>
        <div class="col-sm-10">
          <input
            type="text"
            name="city"
            value=""
            placeholder="City"
            id="input-city"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="input-postcode">
            Post Code
          </label>
        </div>
        <div class="col-sm-10">
          <input
            type="text"
            name="postcode"
            value=""
            placeholder="Post Code"
            id="input-postcode"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="input-country">
            Country
          </label>
        </div>
        <div class="col-sm-10">
          <select name="country_id" id="input-country" class="form-control">
            <option value="">--- Please Select ---</option>
            <option value="244">Aaland Islands</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="input-zone">
            Region / State
          </label>
        </div>
        <div class="col-sm-10">
          <select name="zone_id" id="input-zone" class="form-control">
            <option value="">--- Please Select ---</option>
            <option value="2679" v-for="n in 10" :key="n">Alba</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label">Default Address</label>
        </div>
        <div class="col-sm-10">
          <label class="radio-inline">
            <input type="radio" name="default" value="1" />
            Yes
          </label>
          <label class="radio-inline">
            <input type="radio" name="default" value="0" checked="checked" />
            No
          </label>
        </div>
      </div>
    </div>
    <div class="buttons">
      <div class="pull-left">
        <router-link to="/account/addresses" class="btn btn-default">
          Back
        </router-link>
      </div>
      <div class="pull-right">
        <button type="submit" class="btn btn-primary">
          <span>Continue</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {}
</script>

<style></style>
