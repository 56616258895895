<template>
  <div v-if="cart.length">
    <li class="cart-products">
      <table class="table">
        <tbody>
          <tr v-for="item in cart" :key="item">
            <td class="text-center td-image">
              <router-link to="/products/1">
                <img
                  v-if="item.product.images[0].path"
                  :src="item.product.images[0].path"
                  :alt="item.product.name"
                  :title="item.product.name"
                  class="img-fluid"
                />
              </router-link>
            </td>
            <td class="text-left td-name">
              <router-link to="/products/1">
                {{ item.product.name }}
              </router-link>
              <br />
              <span v-if="item.size">Multiple choice</span>
              <small v-if="item.size">{{ item.size }}</small>
              <br />
              <span v-if="item.color">Single choice (with image)</span>
              <small v-if="item.color">{{ item.color }}</small>
              <br />
            </td>
            <td class="text-right td-qty">x {{ item.qty }}</td>
            <td class="text-right td-total">
              ${{ item.qty * item.product.price_normal }}
            </td>
            <td class="text-center td-remove">
              <button
                type="button"
                title="Remove"
                class="cart-remove"
                v-if="item"
                @click="removeItem(item)"
              >
                <i class="fa fa-times-circle"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </li>
    <li class="cart-totals">
      <div>
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="td-total-title">Sub-Total</td>
              <td class="td-total-text">${{ totalPrice }}</td>
            </tr>
            <tr>
              <td class="td-total-title">Total</td>
              <td class="td-total-text">${{ totalPrice }}</td>
            </tr>
          </tbody>
        </table>
        <div class="cart-buttons">
          <router-link to="/cart" class="btn-primary btn">
            <i class="fa"></i>
            <span>View Cart</span>
          </router-link>
          <router-link to="/checkout" class="btn-checkout btn">
            <i class="fa"></i>
            <span>Checkout</span>
          </router-link>
        </div>
      </div>
    </li>
  </div>
  <div v-else>
    <li>
      <p class="text-center cart-empty">
        Your shopping cart is empty!
      </p>
    </li>
  </div>
</template>

<script>
export default {
  computed: {
    cart() {
      return this.$store.getters.cart
    },

    totalPrice() {
      return this.$store.getters.totalPrice
    },
  },
  methods: {
    removeItem(item) {
      this.$store.commit('removeItem', item)
    },
  },
}
</script>

<style lang="scss">
.cart-content {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(0px, 42px);
  max-width: 400px !important;
  border: none;
  font-size: 13px;
  right: 0;
  left: auto;
  padding-top: 0;
  background: none;
  font-size: 13px;
  &::before {
    content: '';
    display: block;
    border: 10px solid transparent;
    border-bottom-color: rgb(255, 255, 255);
    margin-left: -2px;
    margin-top: -6px;
    left: 100%;
    margin-left: auto;
    width: 0;
    height: 0;
    transform: translateX(-150%);
  }
  .cart-empty {
    padding: 20px;
    font-size: 13px;
    color: #333 !important;
    margin: 0;
    &:hover {
      color: #333 !important;
    }
  }
  table {
    margin-bottom: 0 !important;
    border-style: none !important;
    border-bottom-width: 0 !important;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    font-size: 13px;

    td {
      min-width: initial;
    }
    .td-image {
      min-width: 60px !important;
      max-width: 80px !important;
      a {
        max-width: 80px;
      }
    }
    .td-name {
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .td-qty {
      white-space: nowrap;
    }

    .td-remove {
      button {
        padding: 0;
        border: none;
        box-shadow: none;
        background: none;
      }
    }
    .td-total-text {
      width: 25%;
    }
  }
  .cart-totals {
    table tr td {
      text-align: end;
    }
    .cart-buttons {
      padding: 13px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border-width: 1px 0 0 0;
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
      background: rgba(230, 230, 230, 1);
      a {
        margin: 0 6px;
        font-size: 13px !important;
        color: #fff !important;
      }
    }
  }
}

div.cart-content ul {
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  box-shadow: 0 15px 90px -10px rgba(0, 0, 0, 0.2);
}

div.cart-content .cart-products tbody > tr > td {
  border-style: solid !important;
  border-color: rgba(226, 226, 226, 1) !important;
  vertical-align: middle;
}

div.cart-content .cart-products {
  max-height: 275px;
  overflow-y: auto;
}

div.cart-content .cart-totals tbody td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: rgba(238, 238, 238, 1);
  border-style: none !important;
}

div.cart-content .cart-products tbody .td-remove button {
  color: rgba(221, 14, 28, 1);
}

div.cart-content .cart-products tbody .td-remove button:hover {
  color: rgba(80, 173, 85, 1);
}

div.cart-content .cart-totals td {
  font-weight: 700;
}

div.cart-content .cart-totals .td-total-text {
  font-weight: 700;
}

div.cart-content .cart-buttons {
  background: rgba(230, 230, 230, 1);
}

div.cart-content .btn-cart {
  display: inline-flex;
}

div.cart-content .btn.btn-cart::before {
  content: none !important;
}

div.cart-content .btn-checkout {
  display: inline-flex;
}

div.cart-content .btn-checkout.btn {
  background: rgba(80, 173, 85, 1);
}

div.cart-content .btn-checkout.btn:hover {
  background: rgba(13, 82, 214, 1) !important;
}

div.cart-content .btn-checkout.btn.btn.disabled::after {
  font-size: 20px;
}

div.cart-content .cart-buttons .btn {
  width: auto;
}
</style>
