<template>
  <table class="table table-borderless">
    <thead>
      <tr>
        <td class="text-left" colspan="2">Order Details</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-left" style="width: 50%;">
          <b>Order ID:</b>
          #4019
          <br />
          <b>Date Added:</b>
          14/08/2022
        </td>
        <td class="text-left" style="width: 50%;">
          <b>Payment Method:</b>
          Bank Transfer
          <br />
          <b>Shipping Method:</b>
          Flat Shipping Rate
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table table-borderless">
    <thead>
      <tr>
        <td class="text-left" style="width: 50%; vertical-align: top;">
          Payment Address
        </td>
        <td class="text-left" style="width: 50%; vertical-align: top;">
          Shipping Address
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-left">
          Ezat Ellozy
          <br />
          Ezat Ellozy
          <br />
          Saif Eldin - Alzarqa - Damietta
          <br />
          Alzarqa 34733
          <br />
          Swietokrzyskie
          <br />
          Poland
        </td>
        <td class="text-left">
          Ezat Ellozy
          <br />
          Ezat Ellozy
          <br />
          Saif Eldin - Alzarqa - Damietta
          <br />
          Alzarqa 34733
          <br />
          Swietokrzyskie
          <br />
          Poland
        </td>
      </tr>
    </tbody>
  </table>
  <div class="table-responsive">
    <table class="table table-borderless table-order">
      <thead>
        <tr>
          <td class="text-left">Product Name</td>
          <td class="text-left">Model</td>
          <td class="text-right">Quantity</td>
          <td class="text-right">Price</td>
          <td class="text-right">Total</td>
          <td style="width: 20px;"></td>
        </tr>
      </thead>
      <tbody>
        <tr class="">
          <td class="text-left">
            Headphones
            <br />
            &nbsp;
            <small>- Single choice (with image): Gold</small>
          </td>
          <td class="text-left">Model 519</td>
          <td class="text-right">1</td>
          <td class="text-right">$999.00</td>
          <td class="text-right">$999.00</td>
          <td class="text-right actions">
            <button class="btn btn-primary tooltip-parent">
              <span class="tooltips">Reorder</span>
              <i class="fa fa-shopping-cart"></i>
            </button>
            <button class="btn btn-danger tooltip-parent">
              <span class="tooltips">Return</span>
              <i class="fa fa-reply"></i>
            </button>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td colspan="3"></td>
          <td class="text-right"><b>Sub-Total</b></td>
          <td class="text-right">$999.00</td>
          <td></td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td class="text-right"><b>Flat Shipping Rate</b></td>
          <td class="text-right">$5.00</td>
          <td></td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td class="text-right"><b>Total</b></td>
          <td class="text-right">$1,004.00</td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  </div>
  <h2 class="title mt-4">Order History</h2>
  <div class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <td class="text-left">Date Added</td>
          <td class="text-left">Status</td>
          <td class="text-left">Comment</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left">14/08/2022</td>
          <td class="text-left">Processing</td>
          <td class="text-left">
            Bank Transfer Instructions
            <br />
            <br />
            Bank Transfer Instructions
            <br />
            <br />
            Your order will not ship until we receive payment.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="buttons">
    <div class="pull-right">
      <router-link to="/account/order" class="btn btn-primary">
        Continue
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.order-info {
  .tooltips {
    font-size: 11px;
  }
  .actions {
    white-space: nowrap;
    button {
      &:first-child {
        margin-right: 5px;
      }
    }
  }
}
</style>
