<template>
  <div class="grid-row grid-row-top-4">
    <div class="container">
      <div class="grid-cols">
        <div class="grid-col grid-col-top-4-1">
          <div class="grid-items">
            <div class="grid-item grid-item-top-4-1-1">
              <div class="module title-module module-title-145">
                <div class="title-wrapper">
                  <h3>Featured Products</h3>
                  <div class="title-divider"></div>
                  <div class="subtitle">
                    Display any products as tabs or accordions with optional
                    grid or carousel mode. Custom products per row per module
                    and per breakpoint. Each module can display products in
                    either grid or list view with different styles per module.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col-top-4-2">
          <div class="grid-items">
            <div class="grid-item grid-item-top-4-2-1">
              <div class="module">
                <div class="module-body">
                  <b-tabs content-class="mt-3" align="center">
                    <b-tab title="Featured" class="nav nav-tabs" active>
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :space-between="5"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true, dynamicBullets: true }"
                      >
                        <swiper-slide
                          v-for="product in products"
                          :key="product.id"
                        >
                          <ProductCard :items="product" />
                        </swiper-slide>
                      </swiper>
                    </b-tab>
                    <b-tab title="Latest" class="nav nav-tabs">
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :space-between="5"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true, dynamicBullets: true }"
                      >
                        <swiper-slide
                          v-for="product in products"
                          :key="product.id"
                        >
                          <ProductCard :items="product" />
                        </swiper-slide>
                      </swiper>
                    </b-tab>
                    <b-tab title="Bestsellers" class="nav nav-tabs">
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :space-between="5"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true, dynamicBullets: true }"
                      >
                        <swiper-slide
                          v-for="product in products"
                          :key="product.id"
                        >
                          <ProductCard :items="product" />
                        </swiper-slide>
                      </swiper>
                    </b-tab>
                    <b-tab title="Specials" class="nav nav-tabs">
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :space-between="5"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true, dynamicBullets: true }"
                      >
                        <swiper-slide
                          v-for="product in products"
                          :key="product.id"
                        >
                          <ProductCard :items="product" />
                        </swiper-slide>
                      </swiper>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col-top-4-3">
          <div class="grid-items">
            <div class="grid-item grid-item-top-4-3-1">
              <div class="module module-button module-button-263 text-center">
                <router-link to="/products" class="btn btn-primary">
                  See all products
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Products from '@/products.json'
import ProductCard from '@/components/layout/ProductCard.vue'
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
export default {
  components: { ProductCard, Swiper, SwiperSlide },
  data() {
    return {
      modules: [Navigation, Pagination, A11y],
      products: Products,
      breakpoints: {
        1025: {
          slidesPerView: 4,
        },
        992: {
          slidesPerView: 3,
        },
        600: {
          slidesPerView: 2,
        },
      },
    }
  },
}
</script>

<style></style>
