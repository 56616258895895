<template>
  <form
    action="https://www.journal-theme.com/1/index.php?route=account/newsletter"
    method="post"
    enctype="multipart/form-data"
    class="form-horizontal"
  >
    <fieldset>
      <div class="form-group row">
        <label class="col-sm-2 control-label">Subscribe</label>
        <div class="col-sm-10">
          <label class="radio-inline">
            <input type="radio" name="newsletter" value="1" checked="checked" />
            Yes
          </label>
          <label class="radio-inline">
            <input type="radio" name="newsletter" value="0" />
            No
          </label>
        </div>
      </div>
    </fieldset>
    <div class="buttons">
      <div class="pull-left">
        <router-link to="/account" class="btn btn-default">
          Back
        </router-link>
      </div>
      <div class="pull-right">
        <button type="submit" class="btn btn-primary">
          <span>Continue</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {}
</script>

<style></style>
