<template>
  <div class="product-info">
    <div class="product-right">
      <div class="product-details">
        <div class="product-options">
          <h3 class="options-title title">Available Options</h3>
          <div class="form-group required product-option-checkbox push-option">
            <label class="control-label">Multiple choice</label>
            <div id="input-option763">
              <div
                class="checkbox"
                v-for="extraOptions in items.extraOptions.sizes"
                :key="extraOptions"
              >
                <label>
                  <input
                    type="checkbox"
                    v-model="item.size"
                    :value="extraOptions"
                  />
                  <span class="option-value">
                    {{ extraOptions }}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group required product-option-radio push-option">
            <label class="control-label">Single choice (with image)</label>
            <div id="input-option650">
              <div
                class="radio"
                v-for="extraOptions in items.extraOptions.singleChoice"
                :key="extraOptions.id"
              >
                <label>
                  <input
                    type="radio"
                    v-model="item.color"
                    :value="extraOptions.id"
                  />
                  <img
                    width="40"
                    height="40"
                    :src="extraOptions.path"
                    :alt="extraOptions.title"
                  />
                  <span class="option-value">
                    {{ extraOptions.title }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="button-group-page">
          <div class="buttons-wrapper">
            <div class="stepper-group cart-group">
              <div class="stepper">
                <input
                  type="text"
                  name="quantity"
                  v-model="item.qty"
                  class="form-control"
                />
                <span>
                  <i
                    class="fa-solid fa-angle-up"
                    @click="changeQuantity(1)"
                  ></i>
                  <i
                    class="fa-solid fa-angle-down"
                    @click="changeQuantity(-1)"
                  ></i>
                </span>
              </div>
              <a
                id="button-cart"
                data-loading-text="<span class='btn-text'>Add to Cart</span>"
                class="btn btn-primary btn-cart"
                @click="addToCart"
              >
                <span class="btn-text">Add to Cart</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items', 'quantity'],
  data() {
    return {
      item: {
        qty: 1,
        size: [],
        color: '',
      },
    }
  },
  mounted() {
    this.item.qty = this.quantity
  },
  methods: {
    changeQuantity(n) {
      if (this.item.qty <= 1 && n == -1) return
      this.item.qty += n
    },
    addToCart() {
      this.$emit('addToCart', {
        color: this.item.color,
        size: this.item.size,
        qty: this.item.qty,
        product: this.items,
      })
    },
  },
}
</script>

<style></style>
