<template>
  <div class="faqWrapper">
    <div class="form">
      <h3 class="title">Ask a Question About This Product</h3>
      <div class="module-body">
        <form>
          <fieldset>
            <div class="form-group custom-field required">
              <label class="col-sm-2 control-label" for="field-62fe0ba81b2a5-1">
                Your Name
              </label>
              <div class="col-sm-10">
                <input
                  type="text"
                  name="item[1]"
                  value=""
                  placeholder="Your Name"
                  id="field-62fe0ba81b2a5-1"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group custom-field required">
              <label class="col-sm-2 control-label" for="field-62fe0ba81b2a5-2">
                Your Email
              </label>
              <div class="col-sm-10">
                <input
                  type="email"
                  name="item[2]"
                  value=""
                  placeholder="Your Email"
                  id="field-62fe0ba81b2a5-2"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group custom-field required">
              <label class="col-sm-2 control-label" for="field-62fe0ba81b2a5-3">
                Topic
              </label>
              <div class="col-sm-10">
                <select
                  name="item[3]"
                  id="field-62fe0ba81b2a5-3"
                  class="form-control"
                >
                  <option value="">--- Please Select ---</option>
                  <option value="How does it fit?">How does it fit?</option>
                  <option value="How do you wash it?">
                    How do you wash it?
                  </option>
                  <option value="Customize this form with any fields...">
                    Customize this form with any fields...
                  </option>
                  <option value="Product name is included in the email">
                    Product name is included in the email
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group custom-field">
              <label class="col-sm-2 control-label" for="field-62fe0ba81b2a5-4">
                Message
              </label>
              <div class="col-sm-10">
                <textarea
                  name="item[4]"
                  rows="5"
                  placeholder="Message"
                  id="field-62fe0ba81b2a5-4"
                  class="form-control"
                ></textarea>
              </div>
            </div>
          </fieldset>
          <div class="buttons">
            <div class="pull-right">
              <button
                type="submit"
                class="btn btn-primary"
                data-loading-text="<span>Submit</span>"
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
.faqWrapper {
  padding: 20px;
}
</style>
