<template>
  <div class="checkout-page">
    <div class="page-title-header">
      <h1 class="title page-title"><span>Quick Checkout</span></h1>
    </div>

    <div class="container">
      <div class="quick-checkout-wrapper" style="">
        <div class="d-flex flex-wrap">
          <Left @authSelect="authSelect($event)" />
          <Right :mode="login" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <Recentslider />
      </div>
    </div>
  </div>
</template>

<script>
import Left from '../components/checkout/Left.vue'
import Right from '../components/checkout/Right.vue'
import Recentslider from '@/components/products/Recentslider.vue'

export default {
  components: { Left, Right, Recentslider },
  data() {
    return {
      login: false,
    }
  },
  methods: {
    authSelect(e) {
      if (e == 'login') {
        this.login = true
        return
      }
      this.login = false
    },
  },
}
</script>

<style lang="scss">
.form-group.login-options {
  .radio {
    width: auto;
    padding: 0 15px 0 0;
  }
}
.checkout-page {
  .bottom {
    background: rgba(240, 242, 245, 1);
    padding-top: 30px;
    padding-bottom: 30px;
    border-width: 0;
    border-top-width: 1px;
    border-style: solid;
    border-color: rgba(226, 226, 226, 1);
    margin-top: 20px;
  }
}
</style>
