<template>
  <div class="top-bar d-flex">
    <div
      class="container p-0 d-flex justify-content-between flex-wrap align-items-center"
    >
      <div class="top-menu top-menu-2">
        <ul class="list-unstyled d-flex mb-0 j-menu">
          <li class="menu-item top-menu-item top-menu-item-1">
            <router-link to="/">
              <span class="links-text">Home</span>
            </router-link>
          </li>

          <li class="menu-item top-menu-item top-menu-item-2">
            <router-link to="/about_us">
              <span class="links-text">About Us</span>
            </router-link>
          </li>

          <li class="menu-item top-menu-item top-menu-item-3">
            <router-link to="/contact">
              <span class="links-text">Contact</span>
            </router-link>
          </li>

          <li class="menu-item top-menu-item top-menu-item-4 dropdown">
            <router-link
              to="/faq"
              class="dropdown-toggle header"
              type="button"
              id="language"
              aria-expanded="false"
            >
              <span class="links-text">FAQ</span>
            </router-link>
            <ul class="dropdown-menu header" aria-labelledby="language">
              <li>
                <a class="dropdown-item" href="#">
                  <span class="links-text">Menus</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  <span class="links-text">
                    Unlimited
                  </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="language-currency top-menu d-flex">
        <div class="desktop-language-wrapper">
          <div class="dropdown">
            <button
              class="dropdown-toggle header"
              type="button"
              id="language"
              aria-expanded="false"
            >
              <span class="sympol">
                <img src="@/assets/flag/en.png" alt="technostore" />
              </span>
              <div class="links-text">
                English
              </div>
            </button>
            <ul class="dropdown-menu header" aria-labelledby="language">
              <li>
                <a class="dropdown-item" role="button" @click="setLocale('en')">
                  <span class="links-text">English</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" role="button" @click="setLocale('ar')">
                  <span class="links-text">Arabic</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="desktop-currency-wrapper">
          <div class="dropdown">
            <button
              class="dropdown-toggle header"
              type="button"
              id="currency"
              aria-expanded="false"
            >
              <span class="sympol">$</span>
              <span class="links-text">US Dollar</span>
            </button>
            <ul class="dropdown-menu header" aria-labelledby="currency">
              <li>
                <a class="dropdown-item" href="#">
                  <span class="links-text">
                    Action
                  </span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  <span class="links-text">
                    Another action
                  </span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  <span class="links-text">
                    Something else here
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="third-menu">
        <div class="top-menu top-menu-14">
          <ul class="j-menu list-unstyled d-flex mb-0">
            <li class="menu-item top-menu-item top-menu-item-1 dropdown">
              <a
                class="dropdown-toggle header"
                type="button"
                id="currency"
                aria-expanded="false"
              >
                <span class="links-text">More Menus</span>
              </a>
              <ul class="dropdown-menu header" aria-labelledby="currency">
                <li><a class="dropdown-item" href="#">The Best Menu</a></li>
                <li>
                  <a class="dropdown-item" href="#">Options You Will</a>
                </li>
                <li><a class="dropdown-item" href="#">Ever Find</a></li>
                <li><a class="dropdown-item" href="#">In a Theme</a></li>
              </ul>
            </li>

            <li
              class="menu-item top-menu-item top-menu-item-6 align-self-center"
            >
              <router-link to="/delivery">
                <span class="links-text">Delivery</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  methods: {
    setLocale(lang) {
      Cookies.set('locale', lang)
      setTimeout(() => {
        window.location.reload()
      }, 300)
    },
  },
}
</script>

<style lang="scss">
.top-bar {
  height: 35px;
  .top-menu {
    .top-menu-item {
      align-self: center;
      a {
        font-size: 13px;
        text-decoration: none;
        color: rgba(105, 105, 115, 1) !important;
        font-family: 'Roboto';
        &::before {
          line-height: 1 !important;
        }
        &:hover {
          color: rgba(13, 82, 214, 1) !important;
        }
        &.dropdown-item {
          padding: 10px;
          color: #fff !important;

          &:hover {
            background-color: #0d52d6;
            color: #fff !important;
          }
        }
      }
      .icon {
        margin-right: 3px;
        font-size: 14px;
      }
    }
  }
  .language-currency {
    button {
      &:first-child {
        margin-right: 7px;
      }
    }
  }
}

.dropdown {
  .links-text {
    font-size: 13px;
  }
}
.links-text {
  font-family: 'Roboto' !important;
}

.top-menu-2 .j-menu li.top-menu-item-1 > a::before {
  content: '\eb69' !important;
  font-family: icomoon !important;
  top: -1px;
}

.top-menu-2 .j-menu li.top-menu-item-2 > a::before {
  content: '\e97e' !important;
  font-family: icomoon !important;
  top: -1px;
}

.top-menu-2 .j-menu li.top-menu-item-3 > a::before {
  content: '\eb79' !important;
  font-family: icomoon !important;
}

.top-menu-2 .j-menu li.top-menu-item-4 > a::before {
  content: '\f29c' !important;
  font-family: icomoon !important;
  top: -1px;
}

.top-menu-2 .j-menu .j-menu li.top-menu-item-5 > a::before {
  content: '\e5c8' !important;
  font-family: icomoon !important;
  margin-right: 5px;
}

.top-menu-2 .j-menu .j-menu li.top-menu-item-6 > a::before {
  content: '\e984' !important;
  font-family: icomoon !important;
  margin-right: 5px;
}

.top-menu-2 .j-menu .j-menu li.top-menu-item-7 > a::before {
  content: '\e901' !important;
  font-family: icomoon !important;
  margin-right: 5px;
}

.is-rtl {
  .dropdown-menu.header li a {
    text-align: start;
  }
  div.cart-content .cart-totals tbody td {
    text-align: start;
  }
  .main-menu-wrapper
    .main-menu-container
    .main-menu
    li.dropdown
    .dropdown-menu
    li.dropdown
    .dropdown-menu {
    left: unset;
    right: 100%;
  }
  .mega-menu.flyout-menu-item-1 .dropdown-menu > div,
  .mega-menu.flyout-menu-item-2 .dropdown-menu > div {
    background-position: left bottom !important;
  }
  .mega-menu-content .content .item a.catalog-title {
    margin-left: auto;
    margin-right: unset;
  }
  .module-products-227 .product-grid .product-thumb .price-new {
    margin: 0 0 0 7px;
  }
  .module-products-227 .product-grid .product-thumb .price {
    margin-right: unset;
    margin-left: auto;
  }
  .dropdown.cart:hover .cart-content {
    inset: 0 auto 0 0 !important;
    &::before {
      margin-left: unset;
      margin-right: auto;

      transform: translateX(150%);
    }
  }
  .top-bar {
    .top-menu {
      .top-menu-item {
        .icon {
          margin-left: 3px;
        }
      }
    }
    .language-currency {
      button {
        &:first-child {
          margin-left: 7px;
        }
      }
    }
  }
  .top-bar .top-menu .top-menu-item a.dropdown-item {
    text-align: start !important;
  }

  .top-menu .j-menu > li.top-menu-item-1 > a::before,
  .top-menu .j-menu > li.top-menu-item-4 > a::before {
    margin-right: 0;
    margin-left: 5px;
  }
}

.top-menu-240 .j-menu li.top-menu-item-6 > a::before {
  content: '\eab6' !important;
  font-family: icomoon !important;
}
.desktop-currency-wrapper,
.top-menu.top-menu-14,
.menu-item.top-menu-item.top-menu-item-4.dropdown,
.desktop-language-wrapper {
  .dropdown-toggle::after {
    font-size: 15px !important;
  }
}
.is-rtl {
  .desktop-currency-wrapper,
  .top-menu.top-menu-14,
  .menu-item.top-menu-item.top-menu-item-4.dropdown,
  .desktop-language-wrapper {
    .dropdown-toggle::after {
      margin-left: 0 !important;
      margin-right: 2px !important;
    }
  }
}
</style>
