<template>
  <div class="wrapper">
    <div
      class="modal fade show"
      id="productOverview"
      tabindex="-1"
      aria-labelledby="productOverviewLabel"
      aria-hidden="true"
      @click.stop="closeModal"
      v-if="items"
    >
      <div class="modal-dialog" :class="mode ? 'hasExtra' : ''" @click.stop>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              ref="closeModal"
              @click="closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <ProductOptions
              @addToCart="addToCard"
              :quantity="quantity"
              :items="items"
              v-if="mode"
            />
            <ProductOverview
              @addToCart="addToCard"
              :quantity="quantity"
              :items="items"
              v-else
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductOverview from '../layout/ProductOverview.vue'
import ProductOptions from '../layout/ProductOptions.vue'
export default {
  props: ['mode', 'items', 'quantity'],

  components: { ProductOverview, ProductOptions },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
    addToCard(e) {
      this.$emit('addToCart', e)
      this.$refs.closeModal.click()
    },
  },
}
</script>

<style lang="scss">
#productOverview {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.76);
  }
  .modal-dialog.hasExtra {
    width: 400px;
    .modal-body {
      height: 380px;
    }
    .product-right {
      padding-left: 0 !important;
      width: 100% !important;
      .product-details {
        @media (min-width: 557px) {
          width: 100% !important;
        }

        .product-options {
          border: 0 !important;
          > .options-title {
            display: block !important;
          }
          .form-group .control-label {
            font-size: 12px;
            font-family: 'Roboto';
          }
          width: 100%;
          padding: 0 !important;
          background: none;
        }
      }
    }
    .buttons-wrapper .stepper-group .stepper,
    .button-group-page .btn-cart {
      max-height: 38px;
    }
    .button-group-page .btn-cart {
      margin: 0 !important;
    }
  }
}
</style>
