<template>
  <p>
    Your current balance is:
    <b>$0.00</b>
    .
  </p>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <td class="text-left">Date Added</td>
          <td class="text-left">Description</td>
          <td class="text-right">Amount (USD)</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center" colspan="5">
            You do not have any transactions!
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row pagination-results mt-4">
    <div class="text-start">Showing 0 to 0 of 0 (0 Pages)</div>
  </div>
  <div class="buttons mt-4">
    <div class="pull-right">
      <router-link to="/account" class="btn btn-primary">
        Continue
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
