<template>
  <div class="container">
    <ul class="list-unstyled">
      <li class="breadcrumb-item"><a href="#"></a></li>
    </ul>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.breadcrumb {
  display: block !important;
  text-align: left;
  background: rgba(255, 255, 255, 1);
  padding: 10px !important;
  padding-left: 0px;
  white-space: nowrap;
  margin: 0 !important;
  li:first-of-type a {
    &::before {
      content: '\eb69' !important;
      font-family: icomoon !important;
      top: 1px;
    }
  }
}
</style>
