<template>
  <aside class="side-column-blog">
    <div class="box-search">
      <input
        type="text"
        class="form-control blog-search"
        value=""
        placeholder="Blog Search"
      />
      <button class="btn btn-primary search-btn">
        <i class="fa fa-search"></i>
      </button>
    </div>
    <div class="blog_categories">
      <h3 class="title">Blog Categories</h3>
      <div class="">
        <router-link to="/blogs" class="module-item">
          <span>Shopping</span>
        </router-link>
        <router-link to="/blogs" class="module-item">
          <span>Traveling</span>
        </router-link>
        <router-link to="/blogs" class="module-item">
          <span>Branding</span>
        </router-link>
        <router-link to="/blogs" class="module-item">
          <span>New Products</span>
        </router-link>
      </div>
    </div>
    <div class="comments">
      <h3 class="title">Latest Comments</h3>
      <div class="content">
        <div class="comment" v-for="n in 5" :key="n">
          <router-link to="/blogs/1ds" class="side-image">
            <img
              src="https://s.gravatar.com/avatar/249c25b8f6019d8b1f445a4c65857967?s=40"
              alt="Marble deep cleaning dubai"
              width="40"
              height="40"
            />
          </router-link>
          <div class="side-details">
            <router-link to="/blogs/1ds" class="side-title">
              Welcome to Journal Blog
            </router-link>
            <div class="side-subtitle">
              <span>Marble deep cleaning dubai</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="side-gallery">
        <h3 class="title">Gallery Module</h3>
        <div class="gallary-body">
          <div class="item" v-for="n in 9" :key="n">
            <a title="Image Caption">
              <span class="gallery-image">
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/gallery/233H-60x60h.jpg.webp"
                  alt="Image Caption"
                  width="60"
                  height="60"
                  class="lazyload lazyloaded"
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="module-blog_tags">
        <h3 class="title">Filter by Tags</h3>
        <div class="tags">
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
          <router-link to="/blogs/ds">
            cool
          </router-link>
          <b>,</b>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
export default {}
</script>

<style lang="scss">
aside.side-column-blog {
  width: 100%;
  max-width: 240px;
  border-width: 0;
  border-left-width: 1px;
  border-style: solid;
  border-color: rgba(226, 226, 226, 1);
  padding: 30px;
  padding-top: 0px;
  padding-right: 0px;
  > div {
    margin-bottom: 30px;
  }
  @media (min-width: 992px) {
    width: 260px;
  }
  @media (max-width: 768px) {
    display: none;
  }
  .box-search {
    margin-bottom: 30px;
    display: flex;
    position: relative;
    input.form-control {
      min-width: 35px;
      transition: all 0.075s ease-out;
      width: auto;
      flex-grow: 1;
    }
    .btn {
      height: calc(100% - 6px);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .blog_categories {
    > div {
      display: flex;
      flex-direction: column;
      a {
        border-style: solid;
        border-color: rgba(226, 226, 226, 1);
        display: flex;
        padding: 7px;
        padding-left: 0px;
        align-items: center;
        transition: all 0.07s ease-out;
        border-width: 0;
        &:hover {
          color: rgba(233, 102, 49, 1) !important;
        }
        &::before {
          content: '\f0da' !important;
          font-family: 'Font Awesome 5 Free' !important;
          font-weight: 900;
          margin-right: 4px;
        }
        &:not(:first-child) {
          border-width: 1px 0 0 0;
        }
      }
    }
  }
  .comments {
    .content {
      .comment {
        display: flex;
        padding-top: 8px;
        margin-bottom: 8px;
        a {
          img {
            margin-right: 7px;
            border-radius: 50%;
          }
        }
        .side-details {
          a.side-title {
            color: rgba(13, 82, 214, 1) !important;
            font-family: 'Roboto';
            line-height: 1.2 !important;
          }
          .side-subtitle {
            font-family: 'Roboto';
          }
        }
      }
    }
  }
}
</style>
