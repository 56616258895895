<template>
  <p>You have not made any previous downloadable orders!</p>
  <div class="buttons">
    <div class="pull-right">
      <router-link to="/account" class="btn btn-primary">
        Continue
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
