<template>
  <aside class="products-side-column">
    <div class="block-image">
      <img
        src="https://www.journal-theme.com/1/image/cache/catalog/journal3/categories/beauty-230x230.jpg.webp"
        width="230"
        height="230"
        class="img-fluid w-100"
      />
    </div>

    <h3 class="title">
      <span>Filter</span>
      <button class="reset-filter btn-primary btn">Clear</button>
      <a class="x"></a>
    </h3>
    <div class="module-body">
      <div class="panel-group">
        <b-accordion free>
          <b-accordion-item title="Price" visible style="direction: ltr;">
            <div class="d-flex justify-content-center flex-wrap">
              <div class="w-100 range-slider" style="direction: ltr;">
                <Slider v-model="value" :tooltips="false" />
              </div>
              <div class="extra-controls d-flex" style="direction: ltr;">
                <span class="currency-symbol currency-left">$</span>

                <input
                  type="text"
                  class="filter-price-min"
                  name="min"
                  v-model="value[0]"
                />

                <span class="currency-symbol currency-left">$</span>

                <input
                  type="text"
                  class="filter-price-max"
                  name="max"
                  v-model="value[1]"
                />
              </div>
            </div>
          </b-accordion-item>
          <b-accordion-item title="AVAILABILITY" visible>
            <div class="filter-checkbox">
              <label v-for="n in 4" :key="n">
                <input type="checkbox" name="q" value="1" />
                <span class="links-text">In Stock</span>
                <span class="count-badge">9</span>
              </label>
            </div>
          </b-accordion-item>
          <b-accordion-item title="Subcategories" visible>
            <div class="filter-radio">
              <label v-for="n in 10" :key="n">
                <input type="radio" name="c" value="59" />
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/categories/demo09-38x38.jpg.webp"
                  width="38"
                  height="38"
                  alt="Fashion"
                  title="Fashion"
                  class="img-responsive"
                />
                <span class="links-text">Fashion</span>
                <span class="count-badge">19</span>
              </label>
            </div>
          </b-accordion-item>

          <b-accordion-item title="Brands" visible>
            <div class="filter-radio d-flex flex-wrap">
              <label v-for="n in 4" :key="n">
                <input type="radio" name="m" value="8" />
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/brands/apple_logo-38x38h.jpg.webp"
                  width="38"
                  height="38"
                  alt="Apple"
                  class="img-responsive"
                />
                <span class="count-badge">14</span>
              </label>
            </div>
          </b-accordion-item>
          <b-accordion-item title="Color" visible>
            <div class="filter-radio d-flex flex-wrap">
              <label>
                <input
                  type="radio"
                  data-filter-trigger=""
                  name="o2"
                  value="54"
                />
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/colors/12-38x38.png.webp"
                  width="38"
                  height="38"
                  alt="Burgundy"
                  title=""
                  class="img-responsive"
                />
              </label>
              <label>
                <input
                  type="radio"
                  data-filter-trigger=""
                  name="o2"
                  value="53"
                />
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/colors/9-38x38.png.webp"
                  width="38"
                  height="38"
                  alt="Dark Blue"
                  title=""
                  class="img-responsive"
                />
              </label>
              <label>
                <input
                  type="radio"
                  data-filter-trigger=""
                  name="o2"
                  value="57"
                />
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/colors/14-38x38.png.webp"
                  width="38"
                  height="38"
                  alt="Dark Gray"
                />
              </label>
              <label>
                <input
                  type="radio"
                  data-filter-trigger=""
                  name="o2"
                  value="54"
                />
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/colors/12-38x38.png.webp"
                  width="38"
                  height="38"
                  alt="Burgundy"
                  title=""
                  class="img-responsive"
                />
              </label>
              <label>
                <input
                  type="radio"
                  data-filter-trigger=""
                  name="o2"
                  value="53"
                />
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/colors/9-38x38.png.webp"
                  width="38"
                  height="38"
                  alt="Dark Blue"
                  title=""
                  class="img-responsive"
                />
              </label>
              <label>
                <input
                  type="radio"
                  data-filter-trigger=""
                  name="o2"
                  value="57"
                />
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/colors/14-38x38.png.webp"
                  width="38"
                  height="38"
                  alt="Dark Gray"
                />
              </label>
            </div>
          </b-accordion-item>

          <b-accordion-item title="No. of Cores" visible>
            <div class="filter-checkbox">
              <label>
                <input
                  type="checkbox"
                  data-filter-trigger=""
                  name="a2"
                  value="1"
                />
                <span class="links-text">1</span>
                <span class="count-badge">1</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  data-filter-trigger=""
                  name="a2"
                  value="processor"
                />
                <span class="links-text">processor</span>
                <span class="count-badge">3</span>
              </label>
            </div>
          </b-accordion-item>
          <b-accordion-item title="Material" visible>
            <div class="filter-checkbox">
              <label>
                <input type="checkbox" value="100%25%20cotton" />
                <span class="links-text">100% cotton</span>
                <span class="count-badge">2</span>
              </label>
            </div>
          </b-accordion-item>
          <b-accordion-item title="Clockspeed" visible>
            <div class="filter-checkbox">
              <label>
                <input
                  type="checkbox"
                  data-filter-trigger=""
                  name="a3"
                  value="100mhz"
                />
                <span class="links-text">100mhz</span>
                <span class="count-badge">1</span>
              </label>
            </div>
          </b-accordion-item>
        </b-accordion>
      </div>
    </div>
  </aside>
</template>

<script>
import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'
export default {
  components: { Slider },
  data() {
    return {
      value: [0, 100],
    }
  },
}
</script>

<style lang="scss">
aside.products-side-column {
  position: relative;
  max-width: 260px;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  @media (max-width: 1300px) {
    max-width: 240px;
  }
  .block-image {
    border-radius: 5px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  h3.title {
    position: relative;
  }
  .reset-filter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    font-size: 12px;
    padding: 2px 6px;
    &::before {
      color: #fff;
      content: '\e5c9' !important;
      font-family: icomoon !important;
      margin-right: 3px;
    }
  }
  @media (min-width: 1300px) {
    max-width: 260px;
  }
  width: 100%;
  .accordion {
    .accordion-item {
      border: none !important;
      border-radius: 0 !important;
      &:not(:last-of-type) {
        border-bottom: 1px solid #dee2e6 !important;
      }
      .accordion-button {
        background: none !important;
        padding: 15px 5px 15px 0;
        color: rgba(15, 58, 141, 1) !important;
        font-weight: 600;
        text-transform: uppercase;
        &::after {
          content: '\2b';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          background-image: none;
          transform: none;
        }
        &:not(.collapsed)::after {
          content: '\f068';
        }
      }
    }
    .accordion-body {
      padding: 0 0 20px 0 !important;
      max-height: 250px;
      overflow-y: auto;
    }
  }
  .filter-radio {
    label {
      display: flex;
      align-items: center;
      transition: 0.3s;
      padding: 5px;
      cursor: pointer;
      img {
        border: 1px solid rgba(238, 238, 238, 1);
      }
      .links-text {
        margin: 0 5px;
        color: #333;
      }
      &:hover {
        img {
          border: 1px solid rgba(13, 82, 214, 1);
        }
        .links-text {
          color: rgba(13, 82, 214, 1);
        }
      }
    }
    input {
      display: none;

      &:checked ~ * {
        color: rgba(13, 82, 214, 1);
      }
      &:checked + img {
        border: 1px solid rgba(13, 82, 214, 1);
      }
    }
  }
  .filter-checkbox {
    display: flex;
    flex-direction: column;

    transition: 0.3s;
    padding: 5px;
    cursor: pointer;
    &:hover {
      .links-text {
        color: rgba(13, 82, 214, 1);
      }
    }

    .links-text {
      margin: 0 5px;
      color: #333;
    }
    input {
      &:checked ~ * {
        color: rgba(13, 82, 214, 1);
      }
    }
  }
  .count-badge {
    display: none !important;
  }

  .extra-controls {
    .currency-symbol {
      padding: 0 4px;
    }
    .currency-left ~ .currency-left {
      margin-left: auto;
    }
    input {
      background: rgba(255, 255, 255, 1) !important;
      border-width: 1px !important;
      border-style: solid !important;
      border-color: rgba(226, 226, 226, 1) !important;
      border-radius: 3px !important;
      width: calc(50% - 25px);
      text-align: center;
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
  .range-slider {
    margin: 20px 10px;
  }
  .slider-connect {
    background-attachment: #827eb2;
  }
  .slider-touch-area {
    background: #0d52d6;
    border-radius: 50%;
  }
}
</style>
