<template>
  <div class="page-title-header" v-if="!id">
    <h1 class="title page-title">
      <span>All Products</span>
    </h1>
  </div>
  <div class="container">
    <div class="row" v-if="!id">
      <Aside />
      <Content />
    </div>
  </div>
  <div v-if="id">
    <Product />
  </div>
</template>

<script>
// import ProductCard from '@/components/layout/ProductCard.vue'
import Aside from '@/components/products/Aside.vue'
import Content from '@/components/products/Content.vue'
import Product from '@/components/products/Product.vue'
export default {
  components: { Aside, Content, Product },
  data() {
    return {
      id: '',
    }
  },
  mounted() {
    this.id = this.$route.params.id
  },
  watch: {
    $route() {
      this.id = this.$route.params.id
    },
  },
}
</script>

<style lang="scss">
.products-page {
  background: #f8f8f8;
}
</style>
