<template>
  <Swiper
    @swiper="onSwiper"
    :slides-per-view="1"
    :space-between="50"
    :modules="[Navigation, Pagination, A11y, Lazy, Thumbs]"
    navigation
    :pagination="{ clickable: true, dynamicBullets: true }"
    grab-cursor
    :preload-images="false"
    lazy
    :thumbs="{ swiper: thumbs }"
  >
    <SwiperSlide
      v-for="product in products.images"
      :key="product.id"
      class="customSlide"
    >
      <img
        v-if="product.path"
        :data-src="product.path"
        class="swiper-lazy"
        style="width: 640px; max-width: 100%;"
      />
      <div class="swiper-lazy-preloader"></div>
    </SwiperSlide>
  </Swiper>
</template>
<script>
import { ref, watch, toRefs } from 'vue'
import { Navigation, Pagination, A11y, Lazy, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    currentSlide: Number,
    thumbs: Object,
    products: Object,
  },
  setup(props) {
    const swiperRef = ref(null)

    const { currentSlide } = toRefs(props)
    console.log(currentSlide)

    watch(currentSlide, () => {
      if (swiperRef.value !== null) {
        swiperRef.value.slideTo(props.currentSlide)
      }
    })

    const onSwiper = (swiper) => {
      swiperRef.value = swiper
    }

    return {
      swiperRef,
      onSwiper,
      Navigation,
      Pagination,
      A11y,
      Lazy,
      Thumbs,
    }
  },
}
</script>

<style scoped>
.customSlide {
  display: grid;
  place-items: center;
  height: 100vh;
}
</style>
