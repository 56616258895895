<template>
  <form
    action="https://www.journal-theme.com/1/index.php?route=account/edit"
    method="post"
    enctype="multipart/form-data"
    class="form-horizontal"
  >
    <div id="account">
      <legend>Your Personal Details</legend>
      <div class="form-group required account-firstname">
        <label class="col-sm-2 control-label" for="input-firstname">
          First Name
        </label>
        <div class="col-sm-10">
          <input
            type="text"
            name="firstname"
            value="Ezat"
            placeholder="First Name"
            id="input-firstname"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group required account-lastname">
        <label class="col-sm-2 control-label" for="input-lastname">
          Last Name
        </label>
        <div class="col-sm-10">
          <input
            type="text"
            name="lastname"
            value="Ellozy"
            placeholder="Last Name"
            id="input-lastname"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group required account-email">
        <label class="col-sm-2 control-label" for="input-email">E-Mail</label>
        <div class="col-sm-10">
          <input
            type="email"
            name="email"
            value="ezatellozy1@gmail.co"
            placeholder="E-Mail"
            id="input-email"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group required account-telephone">
        <label class="col-sm-2 control-label" for="input-telephone">
          Telephone
        </label>
        <div class="col-sm-10">
          <input
            type="tel"
            name="telephone"
            value="01017564705"
            placeholder="Telephone"
            id="input-telephone"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <div class="buttons mt-5">
      <div class="pull-left">
        <router-link to="/account/myAccount" class="btn btn-default">
          Back
        </router-link>
      </div>
      <div class="pull-right">
        <button type="submit" class="btn btn-primary">
          <span>Continue</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {}
</script>

<style></style>
