<template>
  <div class="delivery">
    <div class="page-title-header">
      <h1 class="title page-title"><span>Delivery Information</span></h1>
    </div>
    <div class="container">
      <div class="information-information">
        <div class="d-flex flex-wrap">
          <div id="right-column">
            <div class="content">
              <p>
                Delivery Information
              </p>
            </div>
          </div>
          <PagesAside />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PagesAside from '@/components/PagesAside.vue'
export default { components: { PagesAside } }
</script>

<style>
.delivery p {
  font-family: 'Roboto';
  color: #333 !important;
}
</style>
