<template>
  <!-- Modal -->

  <div class="app-wrapper" :class="$i18n.locale == 'ar' ? 'is-rtl' : ''">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from './components/header/Header.vue'
import Footer from './components/footer/Footer.vue'

export default {
  components: { Header, Footer },
  mounted() {
    if (this.$i18n.locale === 'ar') {
      document.body.classList.add('is-rtl')
    } else {
      document.body.classList.remove('is-rtl')
    }
  },
}
</script>

<style lang="scss">
body {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
#productOverview {
  &.show {
    display: flex !important;
    .modal-dialog {
      transform: scale(1);
    }
  }
  align-items: center;
  justify-content: center;

  .modal-dialog {
    max-width: calc(100% - 20px * 2);
    width: 700px;
    transform: none;
    transform: scale(0.2);
    .modal-body {
      max-height: calc(100vh - 50px * 2);
      height: 525px;
      overflow-y: auto;
      padding: 0;
    }
    .modal-header {
      padding: 0;
      border: 0;
      position: absolute;
      right: 20px;
      top: 10px;
      z-index: 20;
    }
  }
}

h2.title {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 15px;
  white-space: normal;
  overflow: visible;
  text-overflow: initial;
}
h2.title::after {
  content: '';
  display: block !important;
  position: absolute;
  width: 50px;
  height: 1px;
  background: rgba(233, 102, 49, 1);
  margin-top: 10px;
}
</style>
