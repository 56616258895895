<template>
  <!-- :slides-per-view="products.length / 2" -->
  <Swiper
    @swiper="onSwiper"
    :breakpoints="breakpoints"
    slides-per-view="3"
    :space-between="10"
    :modules="[Thumbs]"
    watch-slides-progress
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <SwiperSlide
      v-for="(product, index) in products.images"
      :key="product.id"
      @click.stop="$emit('update:currentSlide', Number(index))"
    >
      <img :src="product.path" style="width: 100%;" />
    </SwiperSlide>
  </Swiper>
</template>

<script>
import { Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    currentSlide: Number,
    products: Object,
  },
  data() {
    return {
      breakpoints: {
        // when window width is <= 499px
        499: {
          slidesPerView: 4,
          spaceBetweenSlides: 30,
        },
        // when window width is <= 999px
        700: {
          slidesPerView: 6,
          spaceBetweenSlides: 40,
        },
        999: {
          slidesPerView: 8,
          spaceBetweenSlides: 40,
        },
        1280: {
          slidesPerView: 10,
          spaceBetweenSlides: 40,
        },
      },
    }
  },
  emits: ['thumbs', 'update:currentSlide'],
  setup(props, { emit }) {
    const onSwiper = (swiper) => {
      emit('thumbs', swiper)
    }

    return {
      onSwiper,
      Thumbs,
    }
  },
}
</script>
<style lang="scss" scoped>
.swiper-slide {
  min-width: 80px !important;
  img {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 3px;
    cursor: grab;
    &:hover {
      border-color: rgba(233, 102, 49, 1);
    }
  }
}
</style>
