<template>
  <div class="module-item">
    <div class="image-wrapper">
      <a title="Image Caption" v-if="items">
        <img :src="items" alt="Image Caption" class="img-fluid" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items'],
}
</script>

<style lang="scss">
.module-item {
  .image-wrapper {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    a {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      border-radius: 7px;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        transition: all 0.1s ease-out;
        opacity: 0;
        background-color: rgba(52, 59, 151, 0.4);
        z-index: 2;
      }
      &::after {
        display: block;
        content: '\eba0' !important;
        font-family: icomoon !important;
        color: rgba(255, 255, 255, 1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) scale(0.5);
        transition: all 0.1s ease-out;
        opacity: 0;
        font-size: 35px !important;
        z-index: 3;
      }

      img {
        transition: all 0.3s ease-in;
      }
      &:hover {
        img {
          transform: scale(1.16);
        }

        &::before {
          opacity: 1 !important;
        }
        &::after {
          transform: translate3d(-50%, -50%, 0) scale(1);
          opacity: 1 !important;
        }
      }
    }
  }
}
</style>
