<template>
  <div class="right" :class="mode ? 'isLogin' : ''">
    <div class="checkout-section shipping-payment">
      <div class="section-shipping">
        <div class="title section-title">Shipping Method</div>
        <div class="section-body">
          <div>
            <div class="shippings">
              <div class="ship-wrapper">
                <p>Flat Rate</p>
                <div class="radio">
                  <label>
                    <input type="radio" />
                    <span class="shipping-quote-title">
                      Flat Shipping Rate - $5.00
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-payment">
        <div class="title section-title">Payment Method</div>
        <div class="section-body">
          <div>
            <div class="radio">
              <label>
                <input type="radio" />
                <span>Bank Transfer</span>
              </label>
            </div>
          </div>
          <div>
            <div class="radio">
              <label>
                <input type="radio" />
                <span>Cash On Delivery</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="checkout-section section-cvr">
      <div class="title section-title">Coupon / Voucher / Reward</div>
      <div class="section-body">
        <div class="form-group form-coupon">
          <label for="input-coupon" class="control-label">
            Enter your coupon here
          </label>
          <div class="input-group">
            <input
              type="text"
              placeholder="Enter your coupon here"
              id="input-coupon"
              class="form-control"
            />
            <span class="input-group-btn">
              <button type="button" class="btn btn-primary">
                <span>Submit</span>
              </button>
            </span>
          </div>
        </div>
        <div class="form-group form-voucher">
          <label for="input-voucher" class="control-label">
            Enter your gift certificate code here
          </label>
          <div class="input-group">
            <input
              type="text"
              placeholder="Enter your gift certificate code here"
              id="input-voucher"
              class="form-control"
            />
            <span class="input-group-btn">
              <button
                type="button"
                data-loading-text="Loading..."
                class="btn btn-primary"
              >
                <span>Submit</span>
              </button>
            </span>
          </div>
        </div>
        <div class="form-group form-reward">
          <label for="input-reward" class="control-label">
            Points to use (Max 0)
          </label>
          <div class="input-group">
            <input
              type="text"
              placeholder="Points to use (Max 0)"
              id="input-reward"
              class="form-control"
            />
            <span class="input-group-btn">
              <button
                type="button"
                data-loading-text="Loading..."
                class="btn btn-primary"
              >
                <span>Submit</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="checkout-section cart-section">
      <div class="title section-title">Shopping Cart</div>
      <div class="section-body" v-if="cart">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="text-center td-image">Image</td>
                <td class="text-left td-product">Product Name</td>
                <td class="text-center td-model">Model</td>
                <td class="text-center td-qty">Quantity</td>
                <td class="text-right td-price">Unit Price</td>
                <td class="text-right td-total">Total</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in cart" :key="item">
                <td class="text-center td-image">
                  <router-link to="/products/1">
                    <img
                      v-if="item.product.images[0].path"
                      :src="item.product.images[0].path"
                      :alt="item.product.name"
                      :title="item.product.name"
                      class="img-fluid"
                    />
                  </router-link>
                </td>
                <td class="text-left td-product">
                  <router-link to="/products/1">
                    {{ item.product.name }}
                  </router-link>
                  <br />
                  <small v-if="item.color">
                    Single choice (with image): {{ item.color }}
                  </small>
                </td>
                <td class="text-left td-model" v-if="item.product.model">
                  Model {{ item.product.model }}
                </td>
                <td class="td-qty">
                  <div class="input-group btn-block">
                    <div class="stepper">
                      <input
                        type="text"
                        name="quantity"
                        v-model="item.qty"
                        class="form-control text-center"
                      />
                      <span>
                        <i
                          class="fa-solid fa-angle-up"
                          @click="addItemByOne(item)"
                        ></i>
                        <i
                          class="fa-solid fa-angle-down"
                          @click="removeItemByOne(item)"
                        ></i>
                      </span>
                    </div>
                    <span class="input-group-btn d-flex">
                      <button
                        type="submit"
                        class="btn btn-primary btn-update tooltip-parent"
                      >
                        <span class="tooltips">Update</span>
                        <i class="fa fa-refresh"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-remove tooltip-parent"
                        data-original-title="Remove"
                        @click="removeFromCart(item)"
                      >
                        <span class="tooltips">
                          Remove
                        </span>
                        <i class="fa fa-times-circle"></i>
                      </button>
                    </span>
                  </div>
                </td>

                <td class="text-right td-price">
                  ${{ item.product.price_normal }}
                </td>
                <td class="text-right td-total">
                  ${{ item.product.price_normal * item.qty }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="table table-borderless">
          <tfoot>
            <tr>
              <td colspan="7" class="">
                <strong>Sub-Total:</strong>
              </td>
              <td class="td-total-text">${{ totalPrice }}</td>
            </tr>
            <tr>
              <td colspan="7" class="">
                <strong>Flat Shipping Rate:</strong>
              </td>
              <td class="td-total-text">$5.00</td>
            </tr>
            <tr>
              <td colspan="7" class="">
                <strong>Total:</strong>
              </td>
              <td class="td-total-text">${{ totalPrice + 5 }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div
      class="checkout-section checkout-payment-details payment-bank_transfer"
    >
      <div class="title section-title">Payment Details</div>
      <div class="quick-checkout-payment">
        <h2>Bank Transfer Instructions</h2>
        <p>
          <b>
            Please transfer the total amount to the following bank account.
          </b>
        </p>
        <div class="well well-sm p-0">
          <p>Bank Transfer Instructions</p>
          <p>Your order will not ship until we receive payment.</p>
        </div>
        <div class="buttons">
          <div class="pull-right">
            <input
              type="button"
              value="Confirm Order"
              id="button-confirm"
              class="btn btn-primary"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="checkout-section confirm-section">
      <div class="title section-title">Confirm Your Order</div>
      <div class="section-body">
        <div class="section-comments">
          <textarea
            placeholder="Add Comments About Your Order"
            class="form-control"
          ></textarea>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" />
            I wish to subscribe to the Journal 3 Demo 1 newsletter.
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" />
            I have read and agree to the
            <router-link to="/privacy" class="agree">
              <b>Terms &amp; Conditions</b>
            </router-link>
          </label>
        </div>
        <div class="buttons confirm-buttons">
          <div class="pull-right">
            <button
              type="button"
              data-loading-text="<span>Confirm Order</span>"
              id="quick-checkout-button-confirm"
              class="btn btn-primary"
            >
              <span>Confirm Order</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['mode'],
  data() {
    return {
      quantity: 1,
    }
  },
  computed: {
    cart() {
      return this.$store.getters.cart
    },
    totalPrice() {
      return this.$store.getters.totalPrice
    },
  },
  methods: {
    removeFromCart(e) {
      console.log(e)
      this.$store.commit('removeItem', e)
    },
    removeItemByOne(e) {
      this.$store.commit('removeItemByOne', e)
    },
    addItemByOne(e) {
      this.$store.commit('addItemByOne', e)
    },
  },
}
</script>

<style lang="scss">
.right {
  &.isLogin {
    .checkout-section.shipping-payment,
    .checkout-section.section-cvr,
    .checkout-section.cart-section,
    .checkout-section.checkout-payment-details.payment-bank_transfer,
    .checkout-section.confirm-section {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .shipping-payment {
    display: flex;
    flex-wrap: wrap;
    .section-body {
      > div {
        display: flex;
      }
      .radio {
        margin-bottom: 5px;
        label {
          span {
            padding-left: 5px;
          }
        }
      }
    }
  }
  .section-cvr {
    .control-label {
      max-width: 250px !important;
    }
    .form-group {
      .control-label + div {
        flex: 1 1 250px;
      }
    }
    .btn-primary {
      font-size: 13px !important;
      font-family: 'Roboto';
    }
  }
  .cart-section {
    tfoot {
      td {
        text-align: end;
      }
      .td-total-text {
        width: 20%;
      }
    }
  }
  .checkout-payment-details {
    p {
      color: #333 !important;
      &:hover {
        color: #333 !important;
      }
    }
  }
  .confirm-section textarea {
    margin-bottom: 10px;
  }
}
.checkout-page .input-group.btn-block {
  flex-wrap: nowrap;
  .input-group-btn .btn {
    max-height: 36px;
  }
  @media (max-width: 470px) {
    .stepper {
      max-height: 32px !important;
    }
  }
}
.is-rtl {
  .quick-checkout-wrapper > div .right {
    padding: 0 25px 0 0;
  }
  .right .shipping-payment .section-body .radio label span {
    padding: 0 5px 0 0;
  }
  .shipping-payment .section-body > div > div {
    padding: 0 8px 0 0;
  }
  .form-group.login-options .radio:first-child {
    padding: 0;
  }
  .quick-checkout-wrapper .input-group .input-group-btn .btn {
    margin: 0 5px 0 0;
  }
}
</style>
