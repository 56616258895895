<template>
  <div class="blog-content">
    <div class="post-list">
      <div v-for="n in 10" :key="n">
        <BlogCard />
      </div>
    </div>
    <div class="row pagination-results">
      <div class="col-sm-6 text-left"></div>
      <div class="col-sm-6 text-right">Showing 1 to 9 of 9 (1 Pages)</div>
    </div>
  </div>
</template>

<script>
import BlogCard from './BlogCard.vue'
export default { components: { BlogCard } }
</script>

<style lang="scss">
div.blog-content {
  width: calc(100% - 240px);
  @media (min-width: 992px) {
    width: calc(100% - 260px);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
