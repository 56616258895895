<template>
  <p>
    Your total number of reward points is:
    <b>0</b>
    .
  </p>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <td class="text-left">Date Added</td>
          <td class="text-left">Description</td>
          <td class="text-right">Points</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center" colspan="3">
            You do not have any reward points!
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row pagination-results">
    <div class="col-sm-6 text-left"></div>
    <div class="col-sm-6 text-right">Showing 0 to 0 of 0 (0 Pages)</div>
  </div>
  <div class="buttons clearfix">
    <div class="pull-right">
      <router-link to="/account" class="btn btn-primary">
        Continue
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
