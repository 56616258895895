<template>
  <div
    class="modal mobile fade menu-modal"
    id="menu-modal"
    tabindex="-1"
    aria-labelledby="menu-modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="menu-modalLabel">Menu</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="main-menu-container">
            <b-accordion free>
              <b-accordion-item
                title="All Departments"
                class="menu-item main-menu-item-1"
              >
                <b-accordion free class="sub-accordian flyout-menu">
                  <b-accordion-item
                    title="Shop by Category"
                    class="flyout-menu-item-1"
                  >
                    <div class="mega-menu-content">
                      <div>
                        <div class="content">
                          <div class="item" v-for="n in 6" :key="n">
                            <div class="item-content">
                              <router-link
                                to="/categories/1"
                                class="catalog-title"
                              >
                                Fashion
                              </router-link>
                              <div class="item-assets image-left">
                                <div class="subitems">
                                  <div class="subitem" v-for="n in 4" :key="n">
                                    <router-link to="/categories/1">
                                      <span>Accesories</span>
                                    </router-link>
                                  </div>

                                  <div class="subitem view-more">
                                    <router-link to="/products">
                                      <span>
                                        See all products
                                      </span>
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-accordion-item>
                  <b-accordion-item
                    title="Shop by Brand"
                    class="flyout-menu-item-2"
                  >
                    <div class="mega-menu-content">
                      <div>
                        <div class="content">
                          <div class="item" v-for="n in 6" :key="n">
                            <div class="item-content">
                              <router-link
                                to="/categories/1"
                                class="catalog-title"
                              >
                                Apple
                              </router-link>
                              <div class="item-assets image-left">
                                <div class="subitems">
                                  <div
                                    class="subitem"
                                    v-for="n in 4"
                                    :key="n"
                                    data-image=""
                                  >
                                    <router-link to="/products/1">
                                      <span>
                                        Apple Cinema 30"
                                      </span>
                                    </router-link>
                                  </div>

                                  <div class="subitem view-more">
                                    <router-link to="/categories/1">
                                      <span>
                                        See all products
                                      </span>
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-accordion-item>
                  <b-accordion-item
                    title="Special Deals"
                    class="flyout-menu-item-3"
                  >
                    <div class="mega-menu-content">
                      <div class="content">
                        <div class="item item1">
                          <router-link to="/categories/1">
                            <img
                              src="https://www.journal-theme.com/1/image/cache/catalog/journal3/banners/sale-240x375h.jpg.webp"
                              alt=""
                            />
                          </router-link>
                        </div>
                        <div class="item item2">
                          <div class="product-grid">
                            <div v-for="n in 8" :key="n" class="product-layout">
                              <div class="product-thumb">
                                <div class="image">
                                  <div class="quickview-button">
                                    <a class="btn-quickview tooltip-parent">
                                      <span class="tooltips">
                                        Quickview
                                      </span>
                                    </a>
                                  </div>

                                  <router-link
                                    to="/products/1"
                                    class="product-img"
                                  >
                                    <div>
                                      <img
                                        src="https://www.journal-theme.com/1/image/cache/catalog/journal3/products/beauty/2-250x250.jpg.webp"
                                        alt="Body Oil"
                                        class="img-responsive"
                                      />
                                    </div>
                                  </router-link>
                                </div>

                                <div class="caption">
                                  <div class="name">
                                    <router-link to="/products/1">
                                      Body Oil
                                    </router-link>
                                  </div>

                                  <div class="price">
                                    <div>
                                      <span class="price-new">
                                        $100.00
                                      </span>
                                      <span class="price-old">
                                        $460.00
                                      </span>
                                    </div>
                                  </div>

                                  <div class="buttons-wrapper">
                                    <div class="button-group d-flex">
                                      <a
                                        class="btn-cart btn-primary tooltip-parent"
                                      >
                                        <span class="tooltips">
                                          Add to Cart
                                        </span>
                                      </a>

                                      <a
                                        class="tooltip-parent btn-primary btn-wishlist"
                                      >
                                        <span class="tooltips">
                                          Add to Wish List
                                        </span>
                                      </a>

                                      <a
                                        class="tooltip-parent btn-primary btn-compare"
                                      >
                                        <span class="tooltips">
                                          Compare this Product
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-accordion-item>
                  <b-accordion-item
                    title="Bestsellers"
                    class="flyout-menu-item-4"
                  >
                    <div class="mega-menu-content">
                      <div class="content">
                        <div class="item item1">
                          <div class="product-grid">
                            <div
                              v-for="n in 8"
                              :key="n"
                              class="product-layout has-countdown has-extra-button"
                            >
                              <div class="product-thumb">
                                <div class="image">
                                  <div class="quickview-button">
                                    <a
                                      class="btn-primary btn-quickview tooltip-parent"
                                    >
                                      <span class="tooltips">
                                        Quickview
                                      </span>
                                    </a>
                                  </div>
                                  <router-link
                                    to="/products/1"
                                    class="product-img"
                                  >
                                    <div>
                                      <img
                                        src="https://www.journal-theme.com/1/image/cache/catalog/journal3/products/beauty/2-250x250.jpg.webp"
                                        alt="Body Oil"
                                        class="img-responsive"
                                      />
                                    </div>
                                  </router-link>

                                  <div class="product-labels">
                                    <span
                                      class="product-label product-label-28 product-label-default"
                                    >
                                      <b>-78 %</b>
                                    </span>
                                  </div>
                                </div>

                                <div class="caption">
                                  <div class="name">
                                    <router-link to="/products/1">
                                      Body Oil
                                    </router-link>
                                  </div>

                                  <div class="price">
                                    <div>
                                      <span class="price-new">
                                        $100.00
                                      </span>
                                      <span class="price-old">
                                        $460.00
                                      </span>
                                    </div>
                                  </div>

                                  <div class="buttons-wrapper">
                                    <div class="button-group d-flex">
                                      <a
                                        class="btn-cart btn-primary tooltip-parent"
                                      >
                                        <span class="tooltips">
                                          Add to Cart
                                        </span>
                                      </a>

                                      <a
                                        class="tooltip-parent btn-primary btn-wishlist"
                                      >
                                        <span class="tooltips">
                                          Add to Wish List
                                        </span>
                                      </a>

                                      <a
                                        class="tooltip-parent btn-primary btn-compare"
                                      >
                                        <span class="tooltips">
                                          Compare this Product
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="item item2">
                          <div
                            class="module module-blocks module-blocks-212 blocks-grid"
                          >
                            <div class="module-body">
                              <div class="module-item module-item-1 no-expand">
                                <div class="block-body expand-block">
                                  <h3 class="title module-title block-title">
                                    CMS Blocks
                                  </h3>
                                  <div class="block-header">
                                    <img
                                      src="https://www.journal-theme.com/1/image/cache/catalog/journal3/banners/ban4-200x120w.jpg.webp"
                                      alt=""
                                      class="block-image"
                                    />
                                  </div>
                                  <div class="block-wrapper">
                                    <div class="">
                                      <p>
                                        The mega menus use the page builder
                                        interface and this means you can add any
                                        layout module in your menus to build the
                                        desired layout in any rows and columns
                                        combination.
                                      </p>
                                    </div>
                                    <div class="block-footer">
                                      <router-link
                                        class="btn btn-primary shop"
                                        to="/products"
                                      >
                                        Shop Now
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-accordion-item>
                  <b-accordion-item
                    title="Custom Links"
                    class="flyout-menu-item-5"
                  >
                    <div class="mega-menu-content flex-column">
                      <div class="content">
                        <div class="top">
                          <div class="item" v-for="n in 3" :key="n">
                            <div class="links-menu links-menu-206">
                              <h3 class="title module-title closed">
                                Top Categories
                              </h3>
                              <ul class="module-body">
                                <li
                                  v-for="n in 10"
                                  :key="n"
                                  class="menu-item links-menu-item links-menu-item-1"
                                >
                                  <router-link to="/categories/1">
                                    <span class="links-text">
                                      Fashion
                                    </span>
                                  </router-link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="item item1">
                            <div class="icons-menu icons-menu-209">
                              <h3 class="title module-title">
                                Icons Menus
                              </h3>
                              <ul>
                                <li
                                  v-for="n in 9"
                                  :key="n"
                                  class="menu-item icons-menu-item icons-menu-item-1 icon-menu-icon"
                                >
                                  <router-link to="/categories/1">
                                    <span class="links-text">
                                      Fashion
                                    </span>
                                  </router-link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bottom">
                        <ul class="">
                          <li
                            class="menu-item links-menu-item links-menu-item-1"
                          >
                            <a>
                              <span class="links-text" style="color: #333;">
                                Don't Miss:
                              </span>
                            </a>
                          </li>

                          <div class="d-flex">
                            <li
                              class="menu-item links-menu-item links-menu-item-2"
                            >
                              <a>
                                <span class="links-text">
                                  Create
                                </span>
                              </a>
                            </li>
                            <li
                              v-for="n in 4"
                              :key="n"
                              class="menu-item links-menu-item links-menu-item-3"
                            >
                              <a>
                                <span class="links-text">
                                  Any Links
                                </span>
                              </a>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </b-accordion-item>
                </b-accordion>
                <router-link to="/products" class="all-products-link">
                  <span class="links-text">See All Products</span>
                  <span class="menu-label">New</span>
                </router-link>
              </b-accordion-item>
              <b-accordion-item title="Multilevel">
                <b-accordion free class="sub-accordian">
                  <b-accordion-item title="Accesories" v-for="n in 5" :key="n">
                    <b-accordion free class="sub-accordian1">
                      <b-accordion-item
                        title="Accesories1"
                        v-for="n in 5"
                        :key="n"
                      >
                        <b-accordion free class="sub-accordian2">
                          <b-accordion-item
                            title="Accesories2"
                            v-for="n in 5"
                            :key="n"
                          >
                            <strong>
                              This is the first item's accordion body.
                            </strong>
                            It is shown by default, until the collapse plugin
                            adds the appropriate classes that we use to style
                            each element. These classes control the overall
                            appearance, as well as the showing and hiding via
                            CSS transitions. You can modify any of this with
                            custom CSS or overriding our default variables. It's
                            also worth noting that just about any HTML can go
                            within the
                            <code>default</code>
                            slot, though the transition does limit overflow.
                          </b-accordion-item>
                        </b-accordion>
                      </b-accordion-item>
                    </b-accordion>
                  </b-accordion-item>
                </b-accordion>
                <router-link to="/products">
                  <span class="links-text">See All Products</span>
                  <span class="menu-label">New</span>
                </router-link>
              </b-accordion-item>
              <b-accordion-item
                title="Mega Menu"
                class="menu-item main-menu-item-3 mega-menu menu-fullwidth"
              >
                <div class="mega-menu-content">
                  <div class="content">
                    <div class="item1">
                      <h3 class="title module-title closed">
                        Top Categories
                      </h3>
                      <ul class="module-body">
                        <li
                          v-for="n in 44"
                          :key="n"
                          class="menu-item links-menu-item links-menu-item-1"
                        >
                          <router-link to="/categories/1">
                            <span class="links-text">Fashion</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <div class="item2">
                      <div class="module-body">
                        <div class="module-item module-item-1">
                          <router-link to="/products/1">
                            <img
                              src="https://www.journal-theme.com/1/image/cache/catalog/journal3/banners/17-tall-220x280w.jpg.webp"
                              alt=""
                            />
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-accordion-item>
              <b-accordion-item
                title="Fullwidth"
                class="menu-item main-menu-item-4 mega-menu menu-fullwidth mega-fullwidth"
              >
                <div class="mega-menu-content">
                  <div class="content">
                    <div class="item1">
                      <div class="item-content" v-for="n in 6" :key="n">
                        <router-link to="/categories/1" class="catalog-title">
                          Fashion
                        </router-link>
                        <div class="item-assets image-left">
                          <router-link to="/categories/1" class="catalog-image">
                            <img
                              src="https://www.journal-theme.com/1/image/cache/catalog/journal3/categories/demo09-150x150.jpg.webp"
                              alt="Fashion"
                            />
                          </router-link>
                          <div class="subitems">
                            <div
                              class="subitem"
                              data-image="https://www.journal-theme.com/1/image/cache/catalog/journal3/products/sunglasses/glasses3-150x150.jpg.webp"
                            >
                              <router-link to="/categories/1">
                                <span>Accesories</span>
                              </router-link>
                            </div>

                            <div class="subitem view-more">
                              <router-link to="/categories/1">
                                <span>See all products</span>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item2">
                      <div class="module-body menu-full nobg">
                        <b-tabs align="center">
                          <b-tab
                            title="Top Sellers"
                            class="nav nav-tabs"
                            active
                          >
                            <swiper
                              :slides-per-view="2"
                              :breakpoints="breakpoints"
                              :space-between="5"
                              :modules="modules"
                              navigation
                              :pagination="{ clickable: true }"
                            >
                              <swiper-slide v-for="n in 10" :key="n">
                                <ProductCard />
                              </swiper-slide>
                            </swiper>
                          </b-tab>
                          <b-tab title="Special Deals" class="nav nav-tabs">
                            <swiper
                              :slides-per-view="2"
                              :breakpoints="breakpoints"
                              :space-between="5"
                              :modules="modules"
                              navigation
                              :pagination="{ clickable: true }"
                            >
                              <swiper-slide v-for="n in 10" :key="n">
                                <ProductCard />
                              </swiper-slide>
                            </swiper>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </b-accordion-item>
            </b-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal mobile fade card-modal"
    id="card-modal"
    tabindex="-1"
    aria-labelledby="card-modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="card-modalLabel">YOUR CART</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <ul class="cart-content">
          <CartContent />
        </ul>
      </div>
    </div>
  </div>
  <header class="header-classic">
    <div class="header header-classic header-lg desktop-header">
      <MainHeader />
      <Midbar />
      <Bottombar />
    </div>
    <div class="mobile-header mobile-default mobile-1">
      <div class="mobile-top-bar">
        <div
          class="container d-flex justify-content-between align-items-center"
        >
          <div class="mobile-top-menu-wrapper">
            <div class="top-menu top-menu-13">
              <ul class="j-menu list-unstyled p-0 m-0 d-flex">
                <li class="menu-item top-menu-item top-menu-item-1">
                  <a
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#loginModal"
                  >
                    <span class="links-text">Login</span>
                  </a>
                </li>

                <li class="menu-item top-menu-item top-menu-item-2">
                  <a
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#registerModal"
                  >
                    <span class="links-text">Register</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="language-currency top-menu d-flex align-items-center">
            <div class="desktop-currency-wrapper">
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle header"
                  type="button"
                  id="currency"
                  aria-expanded="false"
                >
                  <span class="sympol">$</span>
                  <span class="links-text">US Dollar</span>
                </button>
                <ul class="dropdown-menu header" aria-labelledby="currency">
                  <li>
                    <a class="dropdown-item" href="#">
                      <span class="links-text">
                        Action
                      </span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <span class="links-text">
                        Another action
                      </span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <span class="links-text">
                        Something else here
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="desktop-language-wrapper">
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle header"
                  type="button"
                  id="language"
                  aria-expanded="false"
                >
                  <span class="sympol">
                    <img src="@/assets/flag/en.png" alt="technostore" />
                  </span>
                  <span class="links-text">
                    English
                  </span>
                </button>
                <ul class="dropdown-menu header" aria-labelledby="language">
                  <li>
                    <a
                      class="dropdown-item"
                      role="button"
                      @click="setLocale('en')"
                    >
                      <span class="links-text">
                        English
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      role="button"
                      @click="setLocale('ar')"
                    >
                      <span class="links-text">
                        Arabic
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-bar sticky-bar">
        <div
          class="container p-0 d-flex justify-content-between align-items-center"
        >
          <div class="mobile-logo-wrapper">
            <div id="logo">
              <router-link to="/">
                <img
                  src="https://www.journal-theme.com/1/image/cache/catalog/journal3/logo/journal-logo1x-185x32.png.webp"
                  width="185"
                  height="32"
                  alt="Journal 3 Demo 1"
                  title="Journal 3 Demo 1"
                  class="img-fluid"
                />
              </router-link>
            </div>
          </div>
          <div class="mobile-bar-group d-flex align-items-center">
            <a
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#menu-modal"
              aria-expanded="false"
              class="menu-trigger"
            ></a>
            <div class="mobile-search-wrapper mini-search">
              <div id="search" class="dropdown">
                <button
                  class="dropdown-toggle search-trigger"
                  aria-expanded="false"
                ></button>
                <div class="dropdown-menu j-dropdown">
                  <div class="header-search">
                    <div class="search-categories dropdown drop-menu">
                      <div
                        class="search-categories-button dropdown-toggle"
                        data-toggle=""
                        aria-expanded="false"
                      >
                        All
                      </div>

                      <div class="dropdown-menu j-dropdown">
                        <ul class="j-menu">
                          <li data-category_id="0" class="category-level-1">
                            <a>All</a>
                          </li>
                          <li data-category_id="59" class="category-level-1">
                            <a>Fashion</a>
                          </li>

                          <li data-category_id="107" class="category-level-1">
                            <a>Bags</a>
                          </li>

                          <li data-category_id="109" class="category-level-1">
                            <a>Health &amp; Beauty</a>
                          </li>

                          <li data-category_id="111" class="category-level-1">
                            <a>Footwear</a>
                          </li>

                          <li data-category_id="175" class="category-level-1">
                            <a>Home Decor</a>
                          </li>

                          <li data-category_id="69" class="category-level-1">
                            <a>Electronics</a>
                          </li>

                          <li data-category_id="205" class="category-level-1">
                            <a>Appliances</a>
                          </li>

                          <li data-category_id="166" class="category-level-1">
                            <a>Baby &amp; Kids</a>
                          </li>

                          <li data-category_id="196" class="category-level-1">
                            <a>Flowers</a>
                          </li>

                          <li data-category_id="155" class="category-level-1">
                            <a>Food</a>
                          </li>

                          <li data-category_id="221" class="category-level-1">
                            <a>Sports</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <span
                      class="twitter-typeahead"
                      style="position: relative; display: inline-block;"
                    >
                      <input
                        type="text"
                        value=""
                        class="search-input tt-hint"
                        data-category_id="0"
                        readonly=""
                        autocomplete="off"
                        spellcheck="false"
                        tabindex="-1"
                        dir="ltr"
                        style="
                          position: absolute;
                          top: 0px;
                          left: 0px;
                          border-color: transparent;
                          box-shadow: none;
                          opacity: 1;
                          background: none 0% 0% / auto repeat scroll
                            padding-box border-box rgb(255, 255, 255);
                        "
                      />
                      <input
                        type="text"
                        name="search"
                        value=""
                        placeholder="Search here..."
                        class="search-input tt-input"
                        data-category_id="0"
                        autocomplete="off"
                        spellcheck="false"
                        dir="auto"
                        style="
                          position: relative;
                          vertical-align: top;
                          background-color: transparent;
                        "
                      />
                      <pre
                        aria-hidden="true"
                        style="
                          position: absolute;
                          visibility: hidden;
                          white-space: pre;
                          font-family: Roboto;
                          font-size: 16px;
                          font-style: normal;
                          font-variant: normal;
                          font-weight: 400;
                          word-spacing: 0px;
                          letter-spacing: 0px;
                          text-indent: 0px;
                          text-rendering: auto;
                          text-transform: none;
                        "
                      ></pre>
                      <div
                        class="tt-menu .tt-empty"
                        style="
                          position: absolute;
                          top: 100%;
                          left: 0px;
                          z-index: 100;
                          display: none;
                        "
                      >
                        <div class="tt-dataset tt-dataset-0"></div>
                      </div>
                    </span>
                    <button
                      type="button"
                      class="search-button"
                      data-search-url="https://www.journal-theme.com/1/index.php?route=product/search&amp;search="
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="mobile-cart-wrapper">
              <div id="cart" class="dropdown">
                <a
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#card-modal"
                  aria-expanded="false"
                >
                  <span id="cart-total">0 item(s) - $0.00</span>
                  <i class="fa-solid fa-cart-shopping"></i>
                  <span id="cart-items" class="count-badge count-zero">0</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import MainHeader from './MainHeader.vue'
import Midbar from './Midbar.vue'
import Bottombar from './Bottombar.vue'
import CartContent from './CartContent.vue'
import ProductCard from '../layout/ProductCard.vue'
import Cookies from 'js-cookie'
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
export default {
  components: {
    MainHeader,
    Midbar,
    Bottombar,
    CartContent,
    Swiper,
    SwiperSlide,
    ProductCard,
  },
  data() {
    return {
      modules: [Navigation, Pagination, A11y],
    }
  },
  methods: {
    setLocale(e) {
      Cookies.set('locale', e)
      setTimeout(() => {
        window.location.reload()
      }, 300)
    },
  },
}
</script>

<style lang="scss">
.modal.mobile .modal-dialog {
  height: 100%;
  margin: 0;
  width: 85%;
  @media (min-width: 768px) {
    width: 40%;
  }
}
.modal.mobile.show .modal-dialog {
  transform: none;
}
.modal.mobile .modal-dialog .modal-content {
  border: 0 !important;
  border-radius: 0;
  height: 100%;
}
.modal.menu-modal .modal-dialog {
  transform: translate(-100%, 0) !important;
  .modal-body {
    padding: 10px;
    height: calc(100% - 45px);
    overflow-y: auto;
    .accordion-item {
      border: none;
      .accordion-button {
        padding: 10px 10px 10px 0;
        color: #333;

        font-weight: 600;
        background: none;
        &::after {
          content: '\eba1' !important;
          font-family: icomoon !important;
          font-size: 20px;
          left: 5px;
          background-image: none;
          transform: none;
        }
        &:not(.collapsed)::after {
          content: '\eb86' !important;
          font-family: icomoon !important;
          font-size: 20px;
          color: rgba(80, 173, 85, 1);
          left: 5px;
        }
      }
    }
    .accordion-body {
      padding: 0;
    }
    .main-menu-item-1 {
      > .accordion-header {
        .accordion-button::before {
          content: '\e8d2' !important;
          font-family: icomoon !important;
          font-size: 20px;
          top: -1px;
          margin-right: 5px;
        }
      }

      .mega-menu-content {
        padding: 0;
        .content .item {
          width: calc(100% / 2 - 0.01px);
          a {
            line-height: 1;
          }
        }
      }
    }
    .main-menu-item-3 {
      .mega-menu-content {
        .item1 {
          width: 100%;
          .module-body {
            column-count: 2;
            padding-bottom: 30px !important;
            display: block;
            column-gap: 30px;
            column-rule-color: rgba(226, 226, 226, 1);
            column-rule-width: 1px;
            column-rule-style: solid;
          }
        }
        .item2 {
          width: 100%;
          a {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .main-menu-item-4 {
      .mega-menu-content {
        padding: 0;
        .item1 {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 20px;
          .item-content {
            padding: 10px;
            width: calc(100% / 2 - 0.01px);
          }
        }
        .item2 {
          .nav {
            margin-bottom: 0 !important;
            .nav-link {
              text-align: start !important;
            }
          }
          .product-wrapper {
            padding: 0;
            padding-right: 10px;
            overflow: visible;
            .product-thumb {
              padding: 0 !important;
              border: 0;
              .name {
                margin: 0 !important;
                padding: 0 !important;
                a {
                  font-size: 14px !important;
                  font-weight: 400 !important;
                }
              }
              .price {
                margin: 0 !important;
                padding: 0 !important;
                span {
                  font-size: 14px !important;
                  font-family: 'Roboto';
                  font-weight: 400 !important;
                  &.price-old {
                    font-size: 13px !important;
                  }
                }
              }
              .button-group {
                padding: 8px !important;
                padding-bottom: 5px !important;
                border-width: 0;
                border-top-width: 1px;
                border-style: solid;
                border-color: rgba(226, 226, 226, 1);
                .btn-text {
                  font-family: 'Roboto';
                }
                .tooltips {
                  font-size: 12px !important;
                  font-family: 'Roboto';
                }
              }
            }
          }
        }
      }
    }
  }
  .accordion-body {
    > a {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 15px;
      color: rgb(105, 105, 115);
      padding: 8px 10px 8px 33px;
      display: flex;
      &.all-products-link {
        &::before {
          content: '\e998' !important;
          font-family: icomoon !important;
          width: 20px;
        }
      }
    }
  }

  .sub-accordian {
    .accordion-item {
      .accordion-button {
        flex-direction: row;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 15px;
        color: rgba(105, 105, 115, 1);
        padding: 8px 10px 8px 33px;
      }
    }
    .sub-accordian1 {
      .accordion-item {
        .accordion-button {
          padding: 8px 10px 8px 40px !important;
        }
      }
    }
    .sub-accordian2 {
      .accordion-item {
        .accordion-button {
          padding: 8px 10px 8px 50px !important;
        }
      }
    }

    &.flyout-menu {
      .flyout-menu-item-1 {
        .accordion-button {
          &::before {
            content: '\e8d2' !important;
            font-family: icomoon !important;
            font-size: 13px;
            width: 20px;
          }
        }
      }
      .flyout-menu-item-2 {
        .accordion-button {
          &::before {
            content: '\f179' !important;
            font-family: icomoon !important;
            font-size: 13px;
            width: 20px;
          }
        }
      }
      .flyout-menu-item-3 {
        .accordion-button {
          &::before {
            content: '\e91f' !important;
            font-family: icomoon !important;
            font-size: 13px;
            width: 20px;
          }
        }
        .mega-menu-content .content .item1 {
          padding: 0px;
          width: calc(100% / 1 - 0.01px);
          padding: 10px;
          a {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
      .flyout-menu-item-3 .mega-menu-content .content .item2,
      .flyout-menu-item-4 .mega-menu-content .content .item1 {
        padding: 0px;
        width: calc(100% / 1 - 0.01px);
        padding: 10px;
        .product-grid {
          display: flex;
          flex-wrap: wrap;
        }
        .product-layout {
          padding: 5px;
          width: calc(100% / 2 - 0.01px);
          .product-thumb {
            border: 0;
            box-shadow: none;

            .product-img {
              border-width: 1px;

              border-color: rgba(15, 58, 141, 1);
            }
            .quickview-button {
              display: none;
            }
            .caption {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              .name {
                margin: 0;
                padding: 0;
                margin-top: 5px;
                a {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-family: 'Montserrat';
                  font-weight: 600;
                  font-size: 12px;
                  padding-bottom: 0;
                  color: rgba(51, 51, 51, 1);
                }
              }
              .price {
                margin: 0;
                padding: 0;
                margin-top: 5px;
                span {
                  font-size: 12px;
                  font-family: 'Roboto';
                }
              }
              .buttons-wrapper {
                position: static;
                width: auto;
                overflow: visible;
                order: -5;
                margin-top: 0;
                transform: none;
                background: rgba(15, 58, 141, 1);
                align-items: center;
                height: 30px;
                .button-group {
                  padding: 0;
                  justify-content: center;
                  height: 100%;
                  .btn-cart {
                    width: 30px;
                    height: 30px;
                    padding: 0;
                  }
                  .btn-wishlist {
                    width: 30px;
                    height: 30px;
                    padding: 0;
                  }
                  .btn-compare {
                    width: 30px;
                    height: 30px;
                    padding: 0;
                  }
                  .btn-cart,
                  .btn-wishlist,
                  .btn-compare {
                    .tooltips {
                      font-size: 11px !important;
                      font-family: 'Roboto';
                    }
                    &::before {
                      color: #fff;
                    }
                    &:hover {
                      &::before {
                        color: #fff !important;
                      }
                      color: #fff !important;
                      text-decoration: none !important;
                      background: rgba(13, 82, 214, 1) !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .flyout-menu-item-4 {
        .accordion-button {
          &::before {
            content: '\e999' !important;
            font-family: icomoon !important;
            font-size: 13px;
            width: 20px;
          }
        }
        .mega-menu-content {
          .item1 {
            width: 100%;
          }
          .item2 {
            width: 100%;
            padding: 15px 20px;
            background: rgba(240, 242, 245, 1);
            .block-wrapper {
              padding: 0;
            }
            .block-footer {
              .btn {
                background: rgba(15, 58, 141, 1) !important;
                border-width: 2px;
                padding: 13px;
                padding-right: 18px;
                padding-left: 18px;
                border-radius: 3px !important;
                &:hover {
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
      .flyout-menu-item-5 {
        .accordion-button {
          &::before {
            content: '\e993' !important;
            font-family: icomoon !important;
            font-size: 13px;
            width: 20px;
          }
        }
        .top {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: 20px;
          .item1 {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            ul {
              justify-content: flex-start;
            }
          }
        }
        .bottom {
          background: rgba(240, 242, 245, 1);
          ul {
            display: flex;
            align-items: center;
            padding: 20px !important;

            li {
              width: 30%;
            }
            > div {
              width: 80%;
              display: flex;
              flex-wrap: wrap;
              li {
                padding: 2px;
              }
            }
          }
        }
      }
    }
  }
}
.modal.card-modal .modal-dialog {
  transform: translate(100%, 0) !important;
  margin-left: auto;

  .cart-content {
    &::before {
      display: none;
    }
    height: calc(100% - 45px);
    .cart-products {
      height: calc(100% - 120px);
      overflow-y: auto;
      * {
        font-size: 13px;
        font-family: 'Roboto';
      }
    }
    .cart-totals tbody td {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      background: rgba(238, 238, 238, 1) !important;
      border-style: none !important;
      font-size: 13px;
      font-family: 'Roboto';
      font-weight: bold;
    }
    .cart-buttons {
      background: rgba(230, 230, 230, 1);
      padding: 13px;
      .btn-checkout.btn {
        background: rgba(80, 173, 85, 1);
      }
    }
  }
}
.modal.menu-modal.show .modal-dialog {
  transform: none !important;
}
.modal.card-modal.show .modal-dialog {
  transform: none !important;
  margin-left: auto;
}
.modal.mobile {
  .modal-header {
    padding: 0 !important;
    height: 45px !important;
    background: #f0f2f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    .modal-title {
      padding: 0 15px !important;
      height: 100% !important;
      display: flex !important;
      align-items: center !important;
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 16px;
      color: rgba(105, 105, 115, 1);
      text-transform: uppercase;
    }
    .btn-close {
      margin: 0;
      width: 45px;
      height: 45px;
      padding: 0;
    }
  }
}
.mobile-cart-wrapper #cart {
  #cart-total {
    display: none;
  }
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    width: 60px;
    &::before {
      color: #333;
    }
  }
}
.desktop-header {
  display: none;
  @media (min-width: 1025px) {
    display: block;
  }
}
.mobile-header {
  display: block;
  @media (min-width: 1025px) {
    display: none;
  }
  .mobile-top-bar {
    background-color: #0d52d6;
    height: 40px;
    .container {
      height: 100%;
      .mobile-top-menu-wrapper {
        .top-menu {
          .menu-item {
            a {
              color: #fff !important;
              .links-text {
                display: inline;
              }
            }
          }
        }
      }
      .language-currency {
        .dropdown-toggle.header {
          color: #fff !important;
          .links-text {
            font-size: 11px;
          }
        }
      }
    }
  }
  .mobile-bar.sticky-bar {
    height: 60px;
  }
  .mobile-logo-wrapper {
    max-width: 50%;
    padding: 15px;
  }
  .mobile-bar-group {
    .menu-trigger {
      width: 50px;
    }
    .search-trigger {
      width: 50px;
    }
  }
  .mobile-bar-group .menu-trigger::before {
    content: '\e8d2' !important;
    font-family: icomoon !important;
    font-size: 33px;
    color: #333 !important;
  }
}
.mobile-search-wrapper.mini-search {
  .search-trigger {
    border: 0;
    background: none;
    color: #333;
  }
  #search {
    position: static;
    > .dropdown-menu {
      width: 100%;
      left: 0;
      padding: 0;
      border-radius: 0;
    }
    &:hover {
      > .dropdown-menu {
        display: block;
        opacity: 1;
      }
    }
    .header-search {
      padding: 8px;
      height: 60px;
      width: 100vw;
      max-width: 100%;
      display: flex;
      background: rgba(240, 242, 245, 1);
      box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.05);

      .twitter-typeahead {
        flex-grow: 1;
        input {
          width: 100%;
          height: 100%;
          background-color: #fff !important;
          border: 0;
          &:focus {
            border: 0 !important;
            outline: none !important;
            box-shadow: none !important;
          }
        }
      }
      .search-button {
        background: rgba(13, 82, 214, 1);
        min-width: 40px;
        border: 0;
        outline: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: none;
        &::before {
          content: '\f002' !important;
          font-family: icomoon !important;
          font-size: 20px;
          color: rgba(223, 231, 246, 1);
          top: -1px;
        }
      }
      .search-categories {
        background: rgba(13, 82, 214, 1);
        display: flex;
        height: 100%;
        align-items: center;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        .search-categories-button {
          padding: 0 10px;
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
          border-top-left-radius: inherit;
          border-bottom-left-radius: inherit;
          display: flex;
          align-items: center;
          cursor: default;
          color: #fff;
          font-size: 13px;
          display: flex;
          align-items: center;
          font-family: 'Roboto';
          &::after {
            content: '';
          }
        }

        .dropdown-menu {
          top: 100%;
          padding: 0;
          border-radius: 0;
          height: 300px;
          overflow-y: auto;
        }
        &:hover .dropdown-menu {
          display: block;
          transform: none !important;
          opacity: 1;
        }
      }
    }
  }
}
.is-rtl {
  .mobile-search-wrapper.mini-search {
    #search {
      .header-search {
        .search-button {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        .search-categories {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }
  .dropdown-menu.header {
    left: 0;
    transform: unset !important;
    &::before {
      left: 20%;
    }
  }
  .accordion-button::after {
    margin-right: auto;
    margin-left: unset;
  }
  .modal.menu-modal
    .modal-dialog
    .modal-body
    .main-menu-item-1
    > .accordion-header
    .accordion-button::before {
    margin: 0 0 0 5px;
  }
  .modal.menu-modal
    .modal-dialog
    .sub-accordian
    .accordion-item
    .accordion-button {
    padding: 8px 33px 8px 10px !important;
  }
  .modal.menu-modal
    .modal-dialog
    .sub-accordian
    .sub-accordian1
    .accordion-item
    .accordion-button {
    padding: 8px 40px 8px 10px !important;
  }
  .modal.menu-modal
    .modal-dialog
    .sub-accordian
    .sub-accordian2
    .accordion-item
    .accordion-button {
    padding: 8px 50px 8px 10px !important;
  }
  .modal.menu-modal .modal-dialog {
    transform: translate(100%, 0) !important;
  }
  .modal.menu-modal.show .modal-dialog {
    transform: none !important;
  }
  .top-menu-13 .j-menu li.top-menu-item-1 > a::before {
    margin: 0 5px 0 0;
  }
}
</style>
