import { createStore } from "vuex";
function updateLocaleStorage(cart) {
  localStorage.setItem('cart', JSON.stringify(cart))
}
function updateWashlistStorage(washlist) {
  localStorage.setItem('washlist', JSON.stringify(washlist))
}
function updateCompareStorage(compare) {
  localStorage.setItem('compare', JSON.stringify(compare))
}

export default createStore({
  state: {
    cart: JSON.parse(localStorage.getItem('cart')) || [],
    washlist: JSON.parse(localStorage.getItem('washlist')) || [],
    compare: JSON.parse(localStorage.getItem('compare')) || [],
  },
  getters: {
    totalPrice: (state) => {
      return state.cart.length * 999
    },
    totalQuantity: (state) => {
      return state.cart.reduce((a, b) => a + b.qty, 0)
    },
    cart: (state) => state.cart,
    cartLength: (state) => state.cart.length,
    washlistLength: (state) => state.washlist.length,
    compareLength: (state) => state.compare.length,
    washlist: (state) => state.washlist,
    compare: (state) => state.compare,
  },
  mutations: {
    addToCart(state, product) {
      let item = state.cart.filter((i) => i.product.id == product.product.id )
      if (item.length) {
        item[0].qty += product.qty
      } else {
        state.cart.push({ ...product })
      }
      updateLocaleStorage(state.cart)
    },
    removeItem(state, product) {
      let item = state.cart.filter((el) => el.product.id !== product.product.id)
      let newItems = []
      newItems.push(...item)
      updateLocaleStorage(newItems)
      state.cart = JSON.parse(localStorage.getItem('cart'))
    },
    addItemByOne(state, product) {
      let item = state.cart.filter((i) => i.product.id === product.product.id)
      if (item.length) {
      
        item[0].qty += 1
      } else {
        state.cart.push({ ...product })
      }
      updateLocaleStorage(state.cart)
    },
    removeItemByOne(state, product) {
      let item = state.cart.filter((i) => i.product.id === product.product.id)
      if (item.length) {
        item[0].qty -= 1
   if (item[0].qty < 1) {
            let item = state.cart.filter((el) => el.product.id !== product.product.id)
            let newItems = []
            newItems.push(...item)
            updateLocaleStorage(newItems)
            state.cart = JSON.parse(localStorage.getItem('cart'))
          }
      } else {
        state.cart.push({ ...product })
      }
      updateLocaleStorage(state.cart)
    },
    addToWashlist(state, product) {
      let item = state.washlist.filter((i) => i.id === product.id)
      if (item.length) {
        return
      } else {
        state.washlist.push({ ...product })
      }
      updateWashlistStorage(state.washlist)
    },
    removeItemWashlist(state, product) {
      let item = state.washlist.filter((el) => el.id !== product.id)

      updateWashlistStorage(item)
      state.washlist = JSON.parse(localStorage.getItem('washlist'))
    },
    addToCompare(state, product) {
      let item = state.compare.filter((i) => i.id === product.id)
      if (item.length) {
        return
      } else {
        if (state.compare.length > 3) {
          state.compare.pop()
        }
        state.compare.unshift({ ...product })
      }
      updateCompareStorage(state.compare)
    },
    removeItemCompare(state, product) {
      let item = state.compare.filter((el) => el.id !== product.id)

      updateCompareStorage(item)
      state.compare = JSON.parse(localStorage.getItem('compare'))
    },
  },
  actions: {},
  modules: {},
});
