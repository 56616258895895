<template>
  <Teleport to="body">
    <div
      class="modal fade"
      id="loginModal"
      tabindex="-1"
      aria-labelledby="loginModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <Login />
        </div>
      </div>
    </div>
  </Teleport>
  <Teleport to="body">
    <div
      class="modal fade"
      id="registerModal"
      tabindex="-1"
      aria-labelledby="registerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <Register />
        </div>
      </div>
    </div>
  </Teleport>
  <Teleport to="body">
    <div
      class="modal fade"
      id="faqQuestion"
      tabindex="-1"
      aria-labelledby="faqQuestionLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <FaqQuestion />
        </div>
      </div>
    </div>
  </Teleport>
  <div class="mid-bar d-flex align-items-center">
    <div
      class="container p-0 d-flex justify-content-between align-items-center"
    >
      <div class="desktop-logo-wrapper">
        <div
          id="logo"
          class="w-100 h-100 d-flex align-items-center justify-content-start"
        >
          <router-link class="w-100" to="/">
            <img
              src="https://www.journal-theme.com/1/image/cache/catalog/journal3/logo/journal-logo1x-185x32.png.webp"
              width="185"
              height="32"
              class="align-self-center w-100"
              alt="techno store"
              title="techno store"
            />
          </router-link>
        </div>
      </div>
      <div
        class="desktop-search-wrapper full-search default-search-wrapper flex-1"
      >
        <div id="search">
          <div class="header-search">
            <div class="search-categories d-flex">
              <div class="dropdown category">
                <button class="btn btn-secondary dropdown-toggle" type="button">
                  All
                </button>
                <ul class="dropdown-menu header">
                  <div>
                    <li>
                      <router-link class="dropdown-item" to="/categories">
                        All
                      </router-link>
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/categories/1">
                        Fashion
                      </router-link>
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/categories/1">
                        Bags
                      </router-link>
                    </li>
                    <li v-for="n in 10" :key="n">
                      <router-link class="dropdown-item" to="/categories/1">
                        Health &amp; Beauty
                      </router-link>
                    </li>
                  </div>
                </ul>
              </div>

              <input
                type="text"
                name="search"
                value=""
                placeholder="Search here..."
                class="search-input tt-input w-100"
              />

              <button type="button" class="search-button">
                <icon name="search" size="20" color="#fff" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="classic-cart-wrapper h-100 align-items-center d-flex">
        <div class="top-menu secondary-menu">
          <div class="top-menu top-menu-240">
            <ul class="j-menu p-0 mb-0 list-unstyled d-flex">
              <li
                class="menu-item top-menu-item top-menu-item-3 dropdown cart drop-menu"
                v-if="false"
              >
                <router-link
                  to="/account/myAccount"
                  class="dropdown-toggle"
                  aria-expanded="false"
                >
                  <span class="links-text">Account</span>
                </router-link>
                <div class="dropdown-menu j-dropdown">
                  <ul class="j-menu">
                    <li class="menu-item top-menu-item-4">
                      <router-link to="/account/myAccount/edit">
                        <span class="links-text">Edit Account</span>
                      </router-link>
                    </li>

                    <li class="menu-item top-menu-item-5">
                      <router-link to="/account/order">
                        <span class="links-text">My Orders</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="menu-item top-menu-item top-menu-item-1">
                <a
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#loginModal"
                >
                  <span class="links-text">Login</span>
                </a>
              </li>

              <li class="menu-item top-menu-item top-menu-item-2">
                <a
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#registerModal"
                  class=""
                >
                  <span class="links-text">Register</span>
                </a>
              </li>

              <li class="menu-item top-menu-item top-menu-item-5">
                <router-link to="/account/MyWishList">
                  <span class="links-text">Wishlist</span>
                  <span class="count-badge wishlist-badge count-zero">0</span>
                </router-link>
              </li>

              <li class="menu-item top-menu-item top-menu-item-6">
                <router-link to="/compare" class="">
                  <span class="links-text">Compare</span>
                  <span class="count-badge compare-badge count-zero">0</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="desktop-cart-wrapper default-cart-wrapper">
          <div id="cart" class="dropdown cart">
            <a
              class="dropdown-toggle cart-heading d-flex align-items-center"
              href="/cart"
              id="cart-button"
              role="button"
              aria-expanded="false"
            >
              <span id="cart-total" class="">
                {{ totalQuantity }} item(s) - ${{ totalPrice }}
              </span>
              <i
                class="fa fa-shopping-cart d-flex justify-content-center align-items-center"
              ></i>
              <span
                id="cart-items"
                class="count-badge"
                :class="cartLength > 0 ? '' : ' count-zero'"
              >
                {{ cartLength }}
              </span>
            </a>

            <div class="cart-content dropdown-menu">
              <ul>
                <CartContent />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '../auth/Login.vue'
import Register from '../auth/Register.vue'
import CartContent from './CartContent.vue'
import FaqQuestion from '../layout/FaqQuestion.vue'
export default {
  components: { Login, Register, CartContent, FaqQuestion },
  computed: {
    totalQuantity() {
      return this.$store.getters.totalQuantity
    },
    totalPrice() {
      return this.$store.getters.totalPrice
    },
    cartLength() {
      return this.$store.getters.cartLength
    },
  },
}
</script>

<style lang="scss" scoped>
.mid-bar {
  height: 100px;
  background-color: #f8f8f8;
  .desktop-logo-wrapper {
    #logo {
      @media (max-width: 1300px) {
        padding-left: 20px;
      }
    }
    width: 205px;
  }
  .desktop-search-wrapper {
    margin: 0 40px 0 25px;
    flex: 1;

    // border-radius: 0.375rem;
    .header-search {
      height: 100%;

      border-radius: 0.375rem !important;
      .search-categories {
        height: 100%;
        input {
          padding: 0 12px;
          border: none;
          &:focus {
            outline: none;
            border-top: 1px solid #0d52d6;
            border-bottom: 1px solid #0d52d6;
          }
        }
        .dropdown-menu {
          background-color: #fff !important;
          left: 0;
          transform: translate(0, 10px) !important;
          min-width: 200px;
          border: none;
          & > div {
            max-height: 350px;
            overflow: auto;
          }
          li {
            a {
              font-size: 14px;
              color: rgba(58, 71, 84, 1) !important;
              font-weight: 400;
              background: rgba(255, 255, 255, 1);
              padding: 10px;
              padding-left: 15px;
            }
            &:hover {
              a {
                background: rgba(240, 242, 245, 1) !important;
              }
            }
          }

          &::before {
            content: '';
            display: block;
            border: 10px solid transparent;
            border-bottom-color: rgb(255, 255, 255);
            margin-left: -2px;
            left: 10%;
            width: 0;
            height: 0;
            transform: translateX(-10%);
          }
        }
      }
    }

    .dropdown.category {
      .dropdown-toggle {
        border-radius: 0.375rem !important;
        border-top-right-radius: 0 !important ;
        border-bottom-right-radius: 0 !important;
        height: 100%;
        font-family: 'Roboto';
        text-transform: capitalize;
        padding: 0 10px !important;
        font-size: 16px !important;
        border: 0 !important;
        &::after {
          content: '\f0d7' !important;
          font-family: icomoon !important;
          top: 1px;
          border: unset !important;
          font-size: 10px;
        }
      }
    }
    .search-button {
      min-width: 40px;
      background-color: #0d52d6;
      border-radius: 0.375rem !important;
      border-top-left-radius: 0 !important ;
      border-bottom-left-radius: 0 !important;
      border: none;
    }
  }
}
#cart {
  margin-right: 20px;
  margin-left: 25px;
}

.dropdown.cart {
  .dropdown-toggle::after {
    content: unset !important;
  }
}
.classic-cart-wrapper {
  .secondary-menu {
    .top-menu {
      .j-menu {
        & > li {
          a {
            position: relative;
            padding: 5px;
            line-height: 1.3;
            &::before {
              color: rgba(105, 105, 115, 1);
              margin: 0px;
              margin-bottom: 2px;
              font-size: 26px;
              display: block;
              line-height: 1 !important;
              text-align: center;
            }
            .links-text {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

.dropdown.cart {
  .cart-content {
    min-width: 400px !important;
    padding: 0;
    border: none;
  }
  &:hover {
    .cart-content {
      display: block;
      position: absolute;
      inset: 0px 0px auto auto;
      margin: 0px;
      transform: translate(0px, 42.2222px);
    }
  }
}

#search {
  height: 40px;
}

.btn-close {
  position: absolute !important;
  right: 20px;
  top: 20px !important;
  font-size: 12px;
}

.top-menu-240 .j-menu li.top-menu-item-3 > a::before {
  content: '\ead9' !important;
  font-family: icomoon !important;
}

#loginModal .modal-content {
  height: 310px;
  @media (min-width: 768px) {
    height: 275px;
  }
}
#registerModal .modal-content {
  height: 620px;
  overflow-y: auto;
}

.is-rtl {
  .mid-bar {
    height: 100px;
    .desktop-logo-wrapper {
      width: 205px;
      #logo {
        @media (max-width: 1300px) {
          padding-right: 20px;
        }
      }
    }
    .desktop-search-wrapper {
      flex: 1;
      margin: 0 25px 0 40px;

      .search-categories {
        .dropdown-menu {
          background-color: #fff !important;
          left: 0;
          transform: translate(0, 10px) !important;
          min-width: 200px;
          border: none;
          & > div {
            max-height: 350px;
            overflow: auto;
          }
          li {
            a {
              font-size: 14px;
              color: rgba(58, 71, 84, 1) !important;
              font-weight: 400;
              background: rgba(255, 255, 255, 1);
              padding: 10px;
              padding-left: 15px;
            }
          }

          &::before {
            content: '';
            display: block;
            border: 10px solid transparent;
            border-bottom-color: rgb(255, 255, 255);
            margin-left: -2px;
            left: 10%;
            width: 0;
            height: 0;
            transform: translateX(-10%);
          }
        }
        .dropdown.category {
          .dropdown-toggle {
            border-top-right-radius: 0.375rem !important;
            border-bottom-right-radius: 0.375rem !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }
      }
      .search-button {
        border-radius: 0.375rem !important;
        border-top-right-radius: 0 !important ;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
  #cart {
    margin-right: 25px;
    margin-left: 20px;
  }
  .dropdown-menu li a {
    text-align: start;
  }
  a {
    text-align: start !important;
  }
}
</style>
