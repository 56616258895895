<template>
  <div class="account-address">
    <AddressList v-if="addresses" />
    <NewAddress v-if="add" />
    <div class="no data">
      <p>You have no addresses in your account.</p>
      <div class="buttons">
        <div class="pull-left">
          <router-link to="/account" class="btn btn-default">
            Back
          </router-link>
        </div>
        <div class="pull-right">
          <router-link to="/account/addresses/add" class="btn btn-primary">
            New Address
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewAddress from '../forms/NewAddress.vue'
import AddressList from './AddressList.vue'
export default {
  components: { NewAddress, AddressList },
  data() {
    return {
      add: false,
      addresses: true,
    }
  },

  mounted() {
    this.openTab(this.tabOpen)
    this.routeName = this.$route.params.actions
  },
  computed: {
    tabOpen() {
      return this.$route.params.actions
    },
  },
  watch: {
    $route() {
      this.openTab(this.$route.params.actions)
      this.routeName = this.$route.params.actions
    },
  },
  methods: {
    openTab(tab) {
      this.add = false
      //   this.addresses = false

      this[tab] = true
    },
  },
}
</script>

<style></style>
