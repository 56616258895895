<template>
  <div class="account-register">
    <p>
      If you already have an account with us, please login at the
      <router-link to="/account/login">
        login page
      </router-link>
      .
    </p>
    <Register />
  </div>
</template>

<script>
import Register from '../auth/Register.vue'
export default {
  components: { Register },
}
</script>

<style lang="scss">
.account-register {
  & > p {
    margin-bottom: 20px;
    color: #333 !important;
    &:hover {
      color: #333 !important;
    }
    a {
      color: rgba(13, 82, 214, 1) !important;
      text-decoration: underline !important;
      display: inline-block !important;
      &:hover {
        color: rgba(233, 102, 49, 1) !important;
        text-decoration: none !important;
      }
    }
  }
  .register-wrapper {
    padding: 0;
  }
}
</style>
