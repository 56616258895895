<template>
  <div class="rating rating-page">
    <div class="rating-stars">
      <span class="fa fa-stack">
        <i class="fa-solid fa-star"></i>
      </span>
      <span class="fa fa-stack">
        <i class="fa-solid fa-star"></i>
      </span>
      <span class="fa fa-stack">
        <i class="fa-solid fa-star"></i>
      </span>
      <span class="fa fa-stack">
        <i class="fa-regular fa-star"></i>
      </span>
      <span class="fa fa-stack">
        <i class="fa-regular fa-star"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
