<template>
  <div class="blog-content single">
    <div class="container">
      <h1 class="title page-title"><span>Best Leather Bags</span></h1>
      <div class="row">
        <div class="blog-content">
          <div class="post-details">
            <div class="post-image">
              <span class="p-date p-date-image">
                02
                <i>Aug</i>
              </span>
              <img
                src="https://www.journal-theme.com/1/image/cache/catalog/journal3/gallery/demo28-1060x400w.jpg.webp"
                width="1060"
                height="400"
                alt="Best Leather Bags"
                title="Best Leather Bags"
              />
            </div>
            <div class="post-stats">
              <span class="p-posted">Posted By</span>
              <span class="p-author">admin</span>
              <span class="p-comment">27 Comment(s)</span>
              <span class="p-view">6642 View(s)</span>
              <span class="p-category">
                <router-link to="/blogs">
                  Shopping
                </router-link>
                <span>,</span>
                <router-link to="/blogs">
                  Traveling
                </router-link>
                <span>,</span>
                <router-link to="/blogs">
                  Branding
                </router-link>
                <span>,</span>
                <router-link to="/blogs">
                  New Products
                </router-link>
              </span>
            </div>
            <div class="post-content">
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam
                ea quam iure distinctio, fugiat culpa veritatis fuga labore
                consequatur. Blanditiis ab atque, accusantium vitae maxime eaque
                totam maiores doloribus debitis?
              </p>

              <h6>More about Journal</h6>

              <p>
                Since 2013, Journal has been the best selling and most loved
                OpenCart theme on the market. Now at version 3, it brings many
                new and revolutionary features such as an advanced page builder
                with 30+ multi-purpose modules that can be added on any page in
                any grid layout configuration, as well as the best possible
                customizable options for any area of your store.
              </p>

              <p>
                Journal 3 also comes with fully customizable CSS options with
                the added possibility of setting each option differently on any
                <a>breakpoint</a>
                . This breakthrough feature will greatly enhance your design
                skills and allow you to create pixel perfect layouts at any
                screen width.
              </p>

              <p>
                Responsive video embeds with max width and float options, as
                well as full container styles on the video-responsive class.
                Display videos in your descriptions, blog posts, or custom
                modules anywhere on the page. Just wrap the iframe in additional
                div elements with the required classes and you have full control
                over your video layout.
              </p>

              <hr />
              <p>
                The above element is a fully customizable
                <code>hr</code>
                element, including custom icon options. Unprecedented control
                over your elements directly from the admin.
              </p>
            </div>
            <div class="tags">
              <span class="tags-title">Tags:</span>

              <router-link to="/blogs/ds" v-for="n in 10" :key="n">
                cool
              </router-link>
            </div>
          </div>

          <div class="content-bottom">
            <div class="row share-it">
              <div class="col-sm-6">
                <div
                  class="addthis_inline_share_toolbox"
                  data-url="https://www.journal-theme.com/1/blog/best-leather-bags"
                  data-title="Best Leather Bags"
                  data-description="Best Opencart theme options you can find in any theme? Decide for yourself by visiting one our demo admin, user/pass: demo/demo. The Journal 3 blog has been greatly improved and it now comes with the most advanced set of typography tools, including custom drop-cap support as well as optional&nbsp;newspap"
                  style="clear: both;"
                >
                  <div
                    id="atstbx"
                    class="at-resp-share-element at-style-responsive addthis-smartlayers addthis-animated at4-show"
                    aria-labelledby="at-29a3cf44-34f3-4438-8f0b-5dae7fee29fa"
                    role="region"
                  >
                    <span
                      id="at-29a3cf44-34f3-4438-8f0b-5dae7fee29fa"
                      class="at4-visually-hidden"
                    >
                      AddThis Sharing Buttons
                    </span>
                    <div class="at-share-btn-elements">
                      <a
                        role="button"
                        tabindex="0"
                        class="at-icon-wrapper at-share-btn at-svc-facebook"
                        style="
                          background-color: rgb(194, 194, 194);
                          border-radius: 2px;
                        "
                      >
                        <span class="at4-visually-hidden">
                          Share to Facebook
                        </span>
                        <span
                          class="at-icon-wrapper"
                          style="line-height: 16px; height: 16px; width: 16px;"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 32 32"
                            version="1.1"
                            role="img"
                            aria-labelledby="at-svg-facebook-1"
                            class="at-icon at-icon-facebook"
                            style="
                              fill: rgb(255, 255, 255);
                              width: 16px;
                              height: 16px;
                            "
                          >
                            <title id="at-svg-facebook-1">
                              Facebook
                            </title>
                            <g>
                              <path
                                d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z"
                                fill-rule="evenodd"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                      <a
                        role="button"
                        tabindex="0"
                        class="at-icon-wrapper at-share-btn at-svc-twitter"
                        style="
                          background-color: rgb(194, 194, 194);
                          border-radius: 2px;
                        "
                      >
                        <span class="at4-visually-hidden">
                          Share to Twitter
                        </span>
                        <span
                          class="at-icon-wrapper"
                          style="line-height: 16px; height: 16px; width: 16px;"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 32 32"
                            version="1.1"
                            role="img"
                            aria-labelledby="at-svg-twitter-2"
                            class="at-icon at-icon-twitter"
                            style="
                              fill: rgb(255, 255, 255);
                              width: 16px;
                              height: 16px;
                            "
                          >
                            <title id="at-svg-twitter-2">
                              Twitter
                            </title>
                            <g>
                              <path
                                d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336"
                                fill-rule="evenodd"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                      <a
                        role="button"
                        tabindex="0"
                        class="at-icon-wrapper at-share-btn at-svc-pinterest_share"
                        style="
                          background-color: rgb(194, 194, 194);
                          border-radius: 2px;
                        "
                      >
                        <span class="at4-visually-hidden">
                          Share to Pinterest
                        </span>
                        <span
                          class="at-icon-wrapper"
                          style="line-height: 16px; height: 16px; width: 16px;"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 32 32"
                            version="1.1"
                            role="img"
                            aria-labelledby="at-svg-pinterest_share-3"
                            class="at-icon at-icon-pinterest_share"
                            style="
                              fill: rgb(255, 255, 255);
                              width: 16px;
                              height: 16px;
                            "
                          >
                            <title id="at-svg-pinterest_share-3">
                              Pinterest
                            </title>
                            <g>
                              <path
                                d="M7 13.252c0 1.81.772 4.45 2.895 5.045.074.014.178.04.252.04.49 0 .772-1.27.772-1.63 0-.428-1.174-1.34-1.174-3.123 0-3.705 3.028-6.33 6.947-6.33 3.37 0 5.863 1.782 5.863 5.058 0 2.446-1.054 7.035-4.468 7.035-1.232 0-2.286-.83-2.286-2.018 0-1.742 1.307-3.43 1.307-5.225 0-1.092-.67-1.977-1.916-1.977-1.692 0-2.732 1.77-2.732 3.165 0 .774.104 1.63.476 2.336-.683 2.736-2.08 6.814-2.08 9.633 0 .87.135 1.728.224 2.6l.134.137.207-.07c2.494-3.178 2.405-3.8 3.533-7.96.61 1.077 2.182 1.658 3.43 1.658 5.254 0 7.614-4.77 7.614-9.067C26 7.987 21.755 5 17.094 5 12.017 5 7 8.15 7 13.252z"
                                fill-rule="evenodd"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                      <a
                        role="button"
                        tabindex="0"
                        class="at-icon-wrapper at-share-btn at-svc-email"
                        style="
                          background-color: rgb(194, 194, 194);
                          border-radius: 2px;
                        "
                      >
                        <span class="at4-visually-hidden">
                          Share to Email
                        </span>
                        <span
                          class="at-icon-wrapper"
                          style="line-height: 16px; height: 16px; width: 16px;"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 32 32"
                            version="1.1"
                            role="img"
                            aria-labelledby="at-svg-email-4"
                            class="at-icon at-icon-email"
                            style="
                              fill: rgb(255, 255, 255);
                              width: 16px;
                              height: 16px;
                            "
                          >
                            <title id="at-svg-email-4">
                              Email
                            </title>
                            <g>
                              <g fill-rule="evenodd"></g>
                              <path
                                d="M27 22.757c0 1.24-.988 2.243-2.19 2.243H7.19C5.98 25 5 23.994 5 22.757V13.67c0-.556.39-.773.855-.496l8.78 5.238c.782.467 1.95.467 2.73 0l8.78-5.238c.472-.28.855-.063.855.495v9.087z"
                              ></path>
                              <path
                                d="M27 9.243C27 8.006 26.02 7 24.81 7H7.19C5.988 7 5 8.004 5 9.243v.465c0 .554.385 1.232.857 1.514l9.61 5.733c.267.16.8.16 1.067 0l9.61-5.733c.473-.283.856-.96.856-1.514v-.465z"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                      <a
                        role="button"
                        tabindex="0"
                        class="at-icon-wrapper at-share-btn at-svc-compact"
                        style="
                          background-color: rgb(194, 194, 194);
                          border-radius: 2px;
                        "
                      >
                        <span class="at4-visually-hidden">
                          Share to More
                        </span>
                        <span
                          class="at-icon-wrapper"
                          style="line-height: 16px; height: 16px; width: 16px;"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 32 32"
                            version="1.1"
                            role="img"
                            aria-labelledby="at-svg-addthis-5"
                            class="at-icon at-icon-addthis"
                            style="
                              fill: rgb(255, 255, 255);
                              width: 16px;
                              height: 16px;
                            "
                          >
                            <title id="at-svg-addthis-5">
                              AddThis
                            </title>
                            <g>
                              <path
                                d="M18 14V8h-4v6H8v4h6v6h4v-6h6v-4h-6z"
                                fill-rule="evenodd"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <span
                          class="at4-share-count-container"
                          style="
                            font-size: 10.2px;
                            line-height: 16px;
                            color: rgb(255, 255, 255);
                          "
                        >
                          8
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="sharethis-inline-reaction-buttons st-inline-reaction-buttons st-left st-has-labels st-reacted d-flex"
                >
                  <div
                    class="st-btn st-first"
                    data-reaction="slight_smile"
                    style="padding: 7px; width: 44px;"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      enable-background="new 0 0 64 64"
                      style="width: 29px; height: 29px;"
                    >
                      <circle cx="32" cy="32" r="30" fill="#ffdd67"></circle>
                      <g fill="#664e27">
                        <circle cx="20.5" cy="26.6" r="5"></circle>
                        <circle cx="43.5" cy="26.6" r="5"></circle>
                        <path
                          d="m44.6 40.3c-8.1 5.7-17.1 5.6-25.2 0-1-.7-1.8.5-1.2 1.6 2.5 4 7.4 7.7 13.8 7.7s11.3-3.6 13.8-7.7c.6-1.1-.2-2.3-1.2-1.6"
                        ></path>
                      </g>
                    </svg>
                    <span class="st-count">12</span>
                    <span class="st-text">
                      LIKE
                    </span>
                  </div>
                  <div
                    class="st-btn"
                    data-reaction="heart_eyes"
                    style="padding: 7px; width: 44px;"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      enable-background="new 0 0 64 64"
                      style="width: 29px; height: 29px;"
                    >
                      <path
                        d="M62,32c0,16.6-13.4,30-30,30C15.4,62,2,48.6,2,32C2,15.4,15.4,2,32,2C48.6,2,62,15.4,62,32z"
                        fill="#ffdd67"
                      ></path>
                      <g fill="#f46767">
                        <path
                          d="m61.8 13.2c-.5-2.7-2-4.9-4.5-5.6-2.7-.7-5.1.3-7.4 2.7-1.3-3.6-3.3-6.3-6.5-7.7-3.2-1.4-6.4-.4-8.4 2.1-2.1 2.6-2.9 6.7-.7 12 2.1 5 11.4 15 11.7 15.3.4-.2 10.8-6.7 13.3-9.9 2.5-3.1 3-6.2 2.5-8.9"
                        ></path>
                        <path
                          d="m29 4.7c-2-2.5-5.2-3.5-8.4-2.1-3.2 1.4-5.2 4.1-6.5 7.7-2.4-2.3-4.8-3.4-7.5-2.6-2.4.7-4 2.9-4.5 5.6-.5 2.6.1 5.8 2.5 8.9 2.6 3.1 13 9.6 13.4 9.8.3-.3 9.6-10.3 11.7-15.3 2.2-5.3 1.4-9.3-.7-12"
                        ></path>
                      </g>
                      <path
                        d="m49 38.1c0-.8-.5-1.8-1.8-2.1-3.5-.7-8.6-1.3-15.2-1.3-6.6 0-11.7.7-15.2 1.3-1.4.3-1.8 1.3-1.8 2.1 0 7.3 5.6 14.6 17 14.6 11.4-.1 17-7.4 17-14.6"
                        fill="#664e27"
                      ></path>
                      <path
                        d="m44.7 38.3c-2.2-.4-6.8-1-12.7-1-5.9 0-10.5.6-12.7 1-1.3.2-1.4.7-1.3 1.5.1.4.1 1 .3 1.6.1.6.3.9 1.3.8 1.9-.2 23-.2 24.9 0 1 .1 1.1-.2 1.3-.8.1-.6.2-1.1.3-1.6 0-.8-.1-1.3-1.4-1.5"
                        fill="#fff"
                      ></path>
                    </svg>
                    <span class="st-count">8</span>
                    <span class="st-text">
                      LOVE
                    </span>
                  </div>
                  <div
                    class="st-btn"
                    data-reaction="astonished"
                    style="padding: 7px; width: 44px;"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      enable-background="new 0 0 64 64"
                      style="width: 29px; height: 29px;"
                    >
                      <circle cx="32" cy="32" r="30" fill="#ffdd67"></circle>
                      <circle cx="19" cy="29" r="11" fill="#fff"></circle>
                      <path
                        d="m24 29c0 2.8-2.2 5-5 5-2.8 0-5-2.2-5-5s2.2-5 5-5c2.8 0 5 2.2 5 5"
                        fill="#664e27"
                      ></path>
                      <path
                        d="m56 29c0 6.1-4.9 11-11 11-6.1 0-11-4.9-11-11 0-6.1 4.9-11 11-11 6.1 0 11 4.9 11 11"
                        fill="#fff"
                      ></path>
                      <path
                        d="m50 29c0 2.8-2.2 5-5 5-2.8 0-5-2.2-5-5s2.2-5 5-5c2.8 0 5 2.2 5 5"
                        fill="#664e27"
                      ></path>
                      <g fill="#917524">
                        <path
                          d="m50.2 15.8c-3.2-2.7-7.5-3.9-11.7-3.1-.6.1-1.1-2-.4-2.2 4.8-.9 9.8.5 13.5 3.6.6.5-1 2.1-1.4 1.7"
                        ></path>
                        <path
                          d="m25.5 12.5c-4.2-.7-8.5.4-11.7 3.1-.4.4-2-1.2-1.4-1.7 3.7-3.2 8.7-4.5 13.5-3.6.7.2.2 2.3-.4 2.2"
                        ></path>
                      </g>
                      <circle cx="32" cy="49" r="9" fill="#664e27"></circle>
                      <path
                        d="m26 46c1.2-2.4 3.4-4 6-4 2.6 0 4.8 1.6 6 4h-12"
                        fill="#fff"
                      ></path>
                    </svg>
                    <span class="st-count">1</span>
                    <span class="st-text">
                      WOW
                    </span>
                  </div>
                  <div
                    class="st-btn st-last st-selected"
                    data-reaction="laughing"
                    style="padding: 7px; width: 44px;"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      enable-background="new 0 0 64 64"
                      style="width: 29px; height: 29px;"
                    >
                      <circle cx="32" cy="32" r="30" fill="#ffdd67"></circle>
                      <g fill="#664e27">
                        <path
                          d="m51.7 19.4c.6.3.3 1-.2 1.1-2.7.4-5.5.9-8.3 2.4 4 .7 7.2 2.7 9 4.8.4.5-.1 1.1-.5 1-4.8-1.7-9.7-2.7-15.8-2-.5 0-.9-.2-.8-.7 1.6-7.3 10.9-10 16.6-6.6"
                        ></path>
                        <path
                          d="m12.3 19.4c-.6.3-.3 1 .2 1.1 2.7.4 5.5.9 8.3 2.4-4 .7-7.2 2.7-9 4.8-.4.5.1 1.1.5 1 4.8-1.7 9.7-2.7 15.8-2 .5 0 .9-.2.8-.7-1.6-7.3-10.9-10-16.6-6.6"
                        ></path>
                        <path
                          d="m49.7 34.4c-.4-.5-1.1-.4-1.9-.4-15.8 0-15.8 0-31.6 0-.8 0-1.5-.1-1.9.4-3.9 5 .7 19.6 17.7 19.6 17 0 21.6-14.6 17.7-19.6"
                        ></path>
                      </g>
                      <path
                        d="m33.8 41.7c-.6 0-1.5.5-1.1 2 .2.7 1.2 1.6 1.2 2.8 0 2.4-3.8 2.4-3.8 0 0-1.2 1-2 1.2-2.8.3-1.4-.6-2-1.1-2-1.6 0-4.1 1.7-4.1 4.6 0 3.2 2.7 5.8 6 5.8s6-2.6 6-5.8c-.1-2.8-2.7-4.5-4.3-4.6"
                        fill="#4c3526"
                      ></path>
                      <path
                        d="m24.3 50.7c2.2 1 4.8 1.5 7.7 1.5s5.5-.6 7.7-1.5c-2.1-1.1-4.7-1.7-7.7-1.7s-5.6.6-7.7 1.7"
                        fill="#ff717f"
                      ></path>
                      <path
                        d="m47 36c-15 0-15 0-29.9 0-2.1 0-2.1 4-.1 4 10.4 0 19.6 0 30 0 2 0 2-4 0-4"
                        fill="#fff"
                      ></path>
                    </svg>
                    <span class="st-count">3</span>
                    <span class="st-text">
                      LOL
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <Recentslider />
          </div>

          <div class="post-comments">
            <h3 class="title">27 Comment(s)</h3>
            <div
              class="post-comment"
              data-comment-id="762"
              data-has-form="false"
            >
              <img
                class="user-avatar"
                src="https://s.gravatar.com/avatar/025df40f0c4712abc81f9e642cef7870?s=60"
                width="60"
                height="60"
                alt=""
              />
              <div class="comment">
                <div class="user-name">fren balatası:</div>
                <div class="user-data">
                  <div>
                    <span class="user-date p-date">
                      26
                      <i>Mar</i>
                    </span>
                    <span class="user-time p-time">09:21:45 PM</span>
                  </div>
                  <span class="user-site p-site">
                    <a href="#" target="_blank">
                      http://balatafren.com
                    </a>
                  </span>
                </div>
                <a class="btn btn-primary reply-btn">Reply</a>
              </div>
              <p class="w-100 mt-2">well done</p>
              <div class="comment-form w-100 d-none">
                <h3 class="title">Leave a Comment</h3>
                <form>
                  <fieldset>
                    <div class="form-group required">
                      <label class="col-sm-2 control-label" for="input-name">
                        Your Name
                      </label>
                      <div class="col-sm-10">
                        <input
                          type="text"
                          name="name"
                          value=""
                          id="input-name"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group required">
                      <label class="col-sm-2 control-label" for="input-email">
                        E-Mail
                      </label>
                      <div class="col-sm-10">
                        <input
                          type="text"
                          name="email"
                          value=""
                          id="input-email"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="input-website">
                        Web Site
                      </label>
                      <div class="col-sm-10">
                        <input
                          type="text"
                          name="website"
                          value=""
                          id="input-website"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group required">
                      <label class="col-sm-2 control-label" for="input-comment">
                        Comments
                      </label>
                      <div class="col-sm-10">
                        <textarea
                          name="comment"
                          rows="10"
                          id="input-comment"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </fieldset>
                  <div class="buttons">
                    <div class="pull-right">
                      <button type="button" class="btn comment-submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="comment-form">
              <h3 class="title">Leave a Comment</h3>
              <form
                method="post"
                enctype="multipart/form-data"
                class="form-horizontal"
              >
                <fieldset>
                  <div class="form-group required">
                    <label class="col-sm-2 control-label" for="input-name">
                      Your Name
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        name="name"
                        value=""
                        id="input-name"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="form-group required">
                    <label class="col-sm-2 control-label" for="input-email">
                      E-Mail
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        name="email"
                        value=""
                        id="input-email"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-sm-2 control-label" for="input-website">
                      Web Site
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        name="website"
                        value=""
                        id="input-website"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="form-group required">
                    <label class="col-sm-2 control-label" for="input-comment">
                      Comments
                    </label>
                    <div class="col-sm-10">
                      <textarea
                        name="comment"
                        rows="10"
                        id="input-comment"
                        class="form-control"
                      ></textarea>
                    </div>
                  </div>
                </fieldset>
                <div class="buttons">
                  <div class="pull-right">
                    <button type="button" class="btn comment-submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Aside />
      </div>
    </div>
  </div>
</template>

<script>
import Recentslider from '../products/Recentslider.vue'
import Aside from '../blogs/Aside.vue'
export default { components: { Recentslider, Aside } }
</script>

<style lang="scss">
.blog-content.single {
  max-width: 1000px;
  margin: 0 auto;
  .blog-content {
    padding: 20px;
    .tags {
      display: flex;
      justify-content: center;
    }
    .post-stats {
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      color: rgba(105, 105, 115, 1);
      margin-top: 15px;
      margin-bottom: 35px;
      span {
        align-items: center;
        margin-right: 8px;
        flex-wrap: wrap;
        display: inline-flex;
        a {
          color: rgba(13, 82, 214, 1) !important;
          text-decoration: underline !important;
          display: inline-block !important;
          &:hover {
            color: rgba(233, 102, 49, 1) !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
  .post-content {
    p {
      color: #333 !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 15px;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      background: rgb(233, 102, 49);
      padding: 10px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-top: 5px;
      margin-bottom: 15px;
      display: inline-block;
    }
  }
  .content-bottom {
    .share-it {
      padding: 20px 0;
    }
  }
  .post-comments {
    p {
      color: #333 !important;
    }
    .post-comment {
      display: flex;
      flex: 1;
      position: relative;
      flex-wrap: wrap;
      .reply-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 13px !important;
        border: none;
      }
      .user-site {
        a {
          color: rgba(13, 82, 214, 1) !important;
          text-decoration: underline !important;
          &:hover {
            color: rgba(233, 102, 49, 1) !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

.user-data div span {
  margin-right: 5px;
  font-style: normal !important;

  i {
    font-style: normal !important;
  }
}
</style>
