<template>
  <aside id="column-right" class="side-column">
    <div class="side-gallery">
      <h3 class="title">Gallery Module</h3>
      <div class="gallary-body">
        <div class="item" v-for="n in 9" :key="n">
          <a title="Image Caption">
            <span class="gallery-image">
              <img
                src="https://www.journal-theme.com/1/image/cache/catalog/journal3/gallery/233H-60x60h.jpg.webp"
                alt="Image Caption"
                width="60"
                height="60"
                class="lazyload lazyloaded"
              />
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="banners">
      <div class="item">
        <router-link to="/categories">
          <img
            src="https://www.journal-theme.com/1/image/cache/catalog/journal3/banners/sale-240x375h.jpg.webp"
            alt="fd"
            width="240"
            height="375"
            class="lazyload lazyloaded"
          />
          <div class="banner-text banner-caption">
            <span>See all specials</span>
          </div>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
export default {}
</script>

<style></style>
