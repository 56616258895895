<template>
  <div class="grid-row grid-row-bottom-4 bg-color">
    <div class="container">
      <div class="grid-cols">
        <div class="grid-col grid-col-bottom-4-1">
          <div class="grid-items">
            <div class="grid-item grid-item-bottom-4-1-1">
              <div class="module title-module module-title-162">
                <div class="title-wrapper">
                  <h3>From our Blog</h3>
                  <div class="title-divider"></div>
                  <div class="subtitle">
                    Journal comes with its own simple yet powerful blog. With
                    the new advanced typography styles your post page design
                    will be unmatched.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col-bottom-4-2 post-blog">
          <b-tabs content-class="mt-3" align="center">
            <b-tab title="Latest Posts" class="nav nav-tabs" active>
              <swiper
                :slides-per-view="2"
                :breakpoints="breakpoints"
                :space-between="5"
                :modules="modules"
                navigation
                :pagination="{ clickable: true }"
              >
                <swiper-slide v-for="n in 10" :key="n">
                  <BlogCard />
                </swiper-slide>
              </swiper>
            </b-tab>
            <b-tab title="Most Read" class="nav nav-tabs">
              <swiper
                :slides-per-view="2"
                :breakpoints="breakpoints"
                :space-between="5"
                :modules="modules"
                navigation
                :pagination="{ clickable: true }"
              >
                <swiper-slide v-for="n in 10" :key="n">
                  <BlogCard />
                </swiper-slide>
              </swiper>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
import BlogCard from '../blogs/BlogCard.vue'
export default {
  components: { Swiper, SwiperSlide, BlogCard },
  data() {
    return {
      modules: [Navigation, Pagination, A11y],
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        580: {
          slidesPerView: 2,
        },
      },
    }
  },
}
</script>

<style></style>
