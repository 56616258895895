<template>
  <div class="nobg mega-menu">
    <b-tabs content-class="mt-3 " align="center">
      <b-tab title="RELATED PRODUCTS" class="nav nav-tabs" active>
        <div class="carousel-wrapper">
          <swiper
            :slides-per-view="2"
            :breakpoints="breakpoints"
            :space-between="5"
            :modules="modules"
            navigation
            :pagination="{ clickable: true }"
          >
            <swiper-slide v-for="product in products" :key="product.id">
              <ProductCard :items="product" />
            </swiper-slide>
          </swiper>
        </div>
      </b-tab>
      <b-tab title="PEOPLE ALSO BOUGHT" class="nav nav-tabs">
        <div class="carousel-wrapper">
          <swiper
            :slides-per-view="2"
            :breakpoints="breakpoints"
            :space-between="5"
            :modules="modules"
            navigation
            :pagination="{ clickable: true }"
          >
            <swiper-slide v-for="product in products" :key="product.id">
              <ProductCard :items="product" />
            </swiper-slide>
          </swiper>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
import Products from '@/products.json'
import ProductCard from '../layout/ProductCard.vue'
export default {
  components: { Swiper, SwiperSlide, ProductCard },
  data() {
    return {
      modules: [Navigation, Pagination, A11y],
      products: Products,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        530: {
          slidesPerView: 2,
        },
      },
    }
  },
}
</script>

<style></style>
