<template>
  <div v-if="edit">
    <EditAccount />
  </div>
  <div v-else-if="changePassword">
    <ChangePassword />
  </div>
  <div v-else-if="affiliateInformation">
    <AffiliateInformation />
  </div>
  <div class="myAccount-page-content" v-else>
    <div class="my-account">
      <h2 class="title">My Account</h2>
      <ul class="list-unstyled account-list">
        <li class="edit-info">
          <router-link to="/account/myAccount/edit">
            Edit your account information
          </router-link>
        </li>
        <li class="edit-pass">
          <router-link to="/account/myAccount/changePassword">
            Change your password
          </router-link>
        </li>
        <li class="edit-address">
          <router-link to="/account/addresses">
            Modify your address book entries
          </router-link>
        </li>
        <li class="edit-wishlist">
          <router-link to="/account/MyWishList">
            Modify your wish list
          </router-link>
        </li>
      </ul>
    </div>
    <div class="my-orders">
      <h2 class="title">My Orders</h2>
      <ul class="list-unstyled account-list">
        <li class="edit-order">
          <router-link to="/account/order">
            View your order history
          </router-link>
        </li>
        <li class="edit-downloads">
          <router-link to="/account/downloads">
            Downloads
          </router-link>
        </li>
        <li class="edit-rewards">
          <router-link to="/account/downloads">
            Your Reward Points
          </router-link>
        </li>
        <li class="edit-returns">
          <router-link to="/account/returnProduct">
            View your return requests
          </router-link>
        </li>
        <li class="edit-transactions">
          <router-link to="/account/transactions">
            Your Transactions
          </router-link>
        </li>
        <li class="edit-recurring">
          <router-link to="/account/payments">
            Recurring payments
          </router-link>
        </li>
      </ul>
    </div>
    <div class="my-affiliates">
      <h2 class="title">My Affiliate Account</h2>
      <ul class="list-unstyled account-list">
        <li class="affiliate-add">
          <router-link to="/account/myAccount/affiliateInformation">
            Register for an affiliate account
          </router-link>
        </li>
      </ul>
    </div>
    <div class="my-newsletter">
      <h2 class="title">Newsletter</h2>
      <ul class="list-unstyled account-list">
        <li>
          <router-link to="/account/newsLetters">
            Subscribe / unsubscribe to newsletter
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EditAccount from './EditAccount.vue'
import ChangePassword from './ChangePassword.vue'
import AffiliateInformation from './affiliateInformation.vue'
export default {
  data() {
    return {
      edit: false,
      changePassword: false,
      affiliateInformation: false,
    }
  },
  mounted() {
    this.openTab(this.tabOpen)
    this.routeName = this.$route.params.actions
  },
  computed: {
    tabOpen() {
      return this.$route.params.actions
    },
  },
  watch: {
    $route() {
      this.openTab(this.$route.params.actions)
      this.routeName = this.$route.params.actions
    },
  },
  methods: {
    openTab(tab) {
      this.edit = false
      this.changePassword = false
      this.affiliateInformation = false
      //   this.addresses = false
      this[tab] = true
    },
  },
  components: { EditAccount, ChangePassword, AffiliateInformation },
}
</script>

<style lang="scss">
.account-list {
  display: flex;
  flex-wrap: wrap;
  & > li {
    width: calc(100% / 5);
    padding: 10px;
    margin: 0;
    & > a {
      display: inline-flex;
      align-items: center;
      line-height: 1.3;
      transition: all 0.1s ease-out;
      font-size: 13px;
      color: rgba(105, 105, 115, 1);
      padding: 10px;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(226, 226, 226, 1);
      border-radius: 3px;
      flex-direction: column;
      width: 100%;
      text-align: center;
      &:hover {
        color: rgba(15, 58, 141, 1);
        background: rgba(255, 255, 255, 1);
      }
      &::before {
        content: '\e93f' !important;
        font-family: icomoon !important;
        font-size: 45px;
        color: rgba(105, 105, 115, 1);
        margin: 0;
      }
    }
  }
}
.myAccount-page-content {
  > div {
    margin-bottom: 25px;
  }
}
div.cart-content .cart-buttons .btn {
  border: 0 !important;
}
.account-list .edit-info {
  display: flex;
}
.account-list .edit-pass {
  display: flex;
}
.account-list .edit-address {
  display: flex;
}
.account-list .edit-wishlist {
  display: flex;
}
.account-list .edit-order {
  display: flex;
}
.account-list .edit-downloads {
  display: flex;
}
.account-list .edit-rewards {
  display: flex;
}

.account-list .edit-returns {
  display: flex;
}
.account-list .edit-transactions {
  display: flex;
}
.account-list .edit-recurring {
  display: flex;
}
.my-newsletter .account-list {
  display: flex;
}

@media (max-width: 1024px) {
  .account-list > li {
    width: calc(100% / 3);
  }
}

@media (max-width: 470px) {
  .account-list > li {
    width: calc(100% / 2);
  }
}

.account-list > li > a:hover {
  box-shadow: 0 15px 90px -10px rgba(0, 0, 0, 0.2);
}

.account-list > li {
  width: calc(100% / 5);
  padding: 10px;
  margin: 0;
}

.account-list {
  margin: 0 -10px -10px;
  flex-direction: row;
}

.account-list > .edit-info > a::before {
  content: '\e90d' !important;
  font-family: icomoon !important;
}

.account-list > .edit-pass > a::before {
  content: '\eac4' !important;
  font-family: icomoon !important;
}

.account-list > .edit-address > a::before {
  content: '\e956' !important;
  font-family: icomoon !important;
}

.account-list > .edit-wishlist > a::before {
  content: '\e955' !important;
  font-family: icomoon !important;
}

.my-cards .account-list > li > a::before {
  content: '\e950' !important;
  font-family: icomoon !important;
}

.account-list > .edit-order > a::before {
  content: '\ead5' !important;
  font-family: icomoon !important;
}

.account-list > .edit-downloads > a::before {
  content: '\eb4e' !important;
  font-family: icomoon !important;
}

.account-list > .edit-rewards > a::before {
  content: '\e952' !important;
  font-family: icomoon !important;
}

.account-list > .edit-returns > a::before {
  content: '\f112' !important;
  font-family: icomoon !important;
}

.account-list > .edit-transactions > a::before {
  content: '\e928' !important;
  font-family: icomoon !important;
}

.account-list > .edit-recurring > a::before {
  content: '\e8b3' !important;
  font-family: icomoon !important;
}

.account-list > .affiliate-add > a::before {
  content: '\e95a' !important;
  font-family: icomoon !important;
}

.account-list > .affiliate-edit > a::before {
  content: '\e95a' !important;
  font-family: icomoon !important;
}

.account-list > .affiliate-track > a::before {
  content: '\e93c' !important;
  font-family: icomoon !important;
}

.my-newsletter .account-list > li > a::before {
  content: '\e94c' !important;
  font-family: icomoon !important;
}
</style>
