<template>
  <div id="top" class="top top-row">
    <div class="grid-rows">
      <Banner />

      <Abstract />
      <Whyus />
      <Products />
      <!-- <Categories /> -->
      <FeaturedCategory />
      <ShopByBrand />
      <ImprovedGallery />
      <Blog />
      <Testimonials />
    </div>
  </div>
</template>

<script>
import Banner from '@/components/home/Banner.vue'
import Abstract from '../components/home/Abstract.vue'
import Whyus from '../components/home/Whyus.vue'
import Products from '@/components/home/Products.vue'
// import Categories from '../components/home/Categories.vue'
import FeaturedCategory from '@/components/home/FeaturedCategory.vue'
import ShopByBrand from '@/components/home/ShopByBrand.vue'
import ImprovedGallery from '@/components/home/ImprovedGallery.vue'
import Blog from '@/components/home/Blog.vue'
import Testimonials from '@/components/home/Testimonials.vue'
export default {
  components: {
    Banner,
    Abstract,
    Whyus,
    Products,
    // Categories,
    FeaturedCategory,
    ShopByBrand,
    ImprovedGallery,
    Blog,
    Testimonials,
  },
}
</script>
