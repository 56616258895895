<template>
  <div class="register-wrapper">
    <form @submit.prevent class="register-form form-horizontal">
      <div id="account">
        <legend>Your Personal Details</legend>

        <div class="form-group">
          <label class="control-label" for="input-firstname">
            First Name
          </label>
          <div>
            <input
              type="text"
              name="firstname"
              value=""
              placeholder="First Name"
              id="input-firstname"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="control-label" for="input-lastname">
            Last Name
          </label>
          <div>
            <input
              type="text"
              name="lastname"
              value=""
              placeholder="Last Name"
              id="input-lastname"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="control-label" for="input-email">E-Mail</label>
          <div>
            <input
              type="email"
              name="email"
              value=""
              placeholder="E-Mail"
              id="input-email"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group required account-telephone">
          <label class="control-label" for="input-telephone">
            Telephone
          </label>
          <div>
            <input
              type="tel"
              name="telephone"
              value=""
              placeholder="Telephone"
              id="input-telephone"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <fieldset>
        <legend>Your Password</legend>
        <div class="form-group required">
          <label class="control-label account-pass" for="input-password">
            Password
          </label>
          <div>
            <input
              type="password"
              name="password"
              value=""
              placeholder="Password"
              id="input-password"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group required account-pass2">
          <label class="control-label" for="input-confirm">
            Password Confirm
          </label>
          <div>
            <input
              type="password"
              name="confirm"
              value=""
              placeholder="Password Confirm"
              id="input-confirm"
              class="form-control"
            />
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>Newsletter</legend>
        <div class="form-group">
          <label class="control-label">Subscribe</label>
          <div class="align-items-center">
            <label class="radio-inline">
              <input type="radio" name="newsletter" value="1" />
              Yes
            </label>
            <label class="radio-inline">
              <input
                type="radio"
                name="newsletter"
                value="0"
                checked="checked"
              />
              No
            </label>
          </div>
        </div>
      </fieldset>

      <div class="buttons">
        <div class="pull-right">
          I have read and agree to the
          <router-link to="/account" class="agree" target="_blank">
            <b>Privacy Policy</b>
          </router-link>
          <input type="checkbox" name="agree" value="1" />
          <button type="submit" class="btn btn-primary">
            <span>Continue</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.register-wrapper {
  padding: 20px;
  .form-group {
    & > div {
      display: flex;
      flex-grow: 1;
      & > label {
        width: auto;
        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
  .buttons {
    .pull-right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}
.buttons .pull-right:only-child .btn {
  line-height: 1.1 !important;
}

legend {
  font-size: 17px !important;
  margin-top: 10px;
  margin-bottom: 15px;
}
</style>
