<template>
  <div class="category-thumb w-100">
    <div class="image w-100">
      <router-link to="/categories/1" class="overflow-hidden w-100">
        <img
          src="https://www.journal-theme.com/1/image/cache/catalog/journal3/categories/demo09-240x280h.jpg.webp"
          alt="Fashion"
          title="Fashion"
          class="w-100"
        />
      </router-link>
    </div>

    <div class="caption">
      <div class="name">
        <router-link to="/categories/1">
          Fashion
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.category-thumb {
  padding: 0 10px 0 0;
  .image {
    a {
      img {
        transition: 0.3s;
      }
    }
  }
}
.is-rtl {
  .category-thumb {
    padding: 0 0 0 10px;
  }
}
</style>
