<template>
  <div class="grid-row grid-row-top-3">
    <div class="container p-0">
      <div class="grid-cols">
        <div class="grid-col grid-col-top-3-1">
          <div class="grid-items">
            <div class="grid-item grid-item-top-3-1-1">
              <div class="module title-module module-title-147">
                <div class="title-wrapper">
                  <h3>Why buy from us?</h3>
                  <div class="title-divider"></div>
                  <div class="subtitle">
                    Journal has been the best selling and most loved Opencart
                    theme since first launch in 2013. Tried and tested by over
                    20K people, Journal is the best Opencart theme framework on
                    the market today.
                    <a href="#">Learn more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col-top-3-2">
          <div class="grid-items">
            <div class="grid-item grid-item-top-3-2-1">
              <div
                class="module module-categories module-categories-37 module-categories- carousel-mode"
              >
                <div class="module-body">
                  <b-tabs>
                    <b-tab title="Top Categories" class="nav nav-tabs" active>
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :space-between="5"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true }"
                      >
                        <swiper-slide v-for="n in 10" :key="n">
                          <WhyusCard />
                        </swiper-slide>
                      </swiper>
                    </b-tab>
                    <b-tab title=" Electronics" class="nav nav-tabs">
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :space-between="5"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true }"
                      >
                        <swiper-slide v-for="n in 10" :key="n">
                          <WhyusCard />
                        </swiper-slide>
                      </swiper>
                    </b-tab>
                    <b-tab title="Beauty" class="nav nav-tabs">
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :space-between="5"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true }"
                      >
                        <swiper-slide v-for="n in 10" :key="n">
                          <WhyusCard />
                        </swiper-slide>
                      </swiper>
                    </b-tab>
                    <b-tab title="Fashion" class="nav nav-tabs">
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :space-between="5"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true }"
                      >
                        <swiper-slide v-for="n in 10" :key="n">
                          <WhyusCard />
                        </swiper-slide>
                      </swiper>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WhyusCard from '../layout/WhyusCard.vue'
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
export default {
  components: {
    WhyusCard,

    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation, Pagination, A11y],
      breakpoints: {
        1025: {
          slidesPerView: 5,
        },
        992: {
          slidesPerView: 4,
        },
        471: {
          slidesPerView: 3,
        },
      },
    }
  },
}
</script>

<style lang="scss">
.tab-content {
  padding-top: 20px;
}
ul.nav-tabs {
  border: none;
  margin-bottom: 20px !important;
  .nav-item {
    .nav-link {
      border-width: 0;
      border-color: rgba(226, 226, 226, 1);
      flex-grow: 0;
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 13px;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      background-color: rgba(238, 238, 238, 1) !important;
      color: rgba(105, 105, 115, 1);
      text-transform: uppercase;
      height: 100%;
      padding: 11px 15px 12px;
      position: relative;
      width: 100%;
      &.active {
        color: #fff;
        background: rgba(13, 82, 214, 1) !important;
        z-index: 25;
      }
      &.active {
        &::before {
          content: '';
          display: block;
          position: absolute;
          border: 10px solid transparent;
          left: 50%;
          top: 100%;
          transform: translate(-50%, 0);
          border-top-color: rgb(13, 82, 214);
          border-top-width: 10px;
          z-index: 25;
        }
      }
    }
  }
}
.nobg {
  ul.nav-tabs {
    justify-content: flex-start !important;
    padding-left: 10px !important;
    & > li {
      border: none !important;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .nav-item {
      .nav-link {
        background-color: transparent !important;
        color: rgba(139, 145, 152, 1) !important;
        text-transform: uppercase;
        border: none;
        padding: 0 !important;
        padding-bottom: 8px !important;
        &::before {
          content: unset !important;
        }
        &.active {
          background-color: transparent !important;
          border: none;
          color: rgba(230, 230, 230, 1) !important;
          border-bottom: 1px solid;
          border-color: rgba(233, 102, 49, 1);
          color: #000 !important;
        }
      }
    }
  }
}
.is-rtl {
  .nobg {
    ul.nav-tabs {
      justify-content: flex-start !important;
      padding-left: 0 !important;
      padding-right: 10px !important;
      & > li {
        border: none !important;
        &:not(:last-child) {
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
