<template>
  <aside id="column-right" class="side-column d-none d-md-block col-md-3">
    <ul class="list-unstyled">
      <li class="menu-item">
        <router-link to="/account/myAccount">
          <span class="links-text">My Account</span>
        </router-link>
      </li>
      <li class="menu-item">
        <router-link to="/account/addresses">
          <span class="links-text">Address Book</span>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link to="/account/MyWishList">
          <span class="links-text">Wishlist</span>
          <span class="count-badge wishlist-badge">2</span>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link to="/account/order">
          <span class="links-text">Order History</span>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link to="/account/downloads">
          <span class="links-text">Downloads</span>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link to="/account/payments">
          <span class="links-text">Recurring Payments</span>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link to="/account/rewards">
          <span class="links-text">Reward Points</span>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link to="/account/returnProduct">
          <span class="links-text">Returns</span>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link to="/account/transactions">
          <span class="links-text">Transactions</span>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link to="/account/newsLetters">
          <span class="links-text">Newsletter</span>
        </router-link>
      </li>

      <li class="menu-item accordion accordion-flush" id="custom-menus">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Custom Menus
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#custom-menus"
          >
            <div class="accordion-body">
              <ul class="list-unstyled">
                <li class="menu-item">
                  <a>
                    <span class="links-text">
                      All Menus Are
                    </span>
                  </a>
                </li>

                <li class="menu-item">
                  <a>
                    <span class="links-text">
                      Fully Customizable
                    </span>
                  </a>
                </li>

                <li class="menu-item">
                  <a>
                    <span class="links-text">
                      Add/Remove Links
                    </span>
                  </a>
                </li>

                <li class="menu-item">
                  <a>
                    <span class="links-text">As Needed</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {}
</script>

<style></style>
