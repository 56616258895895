<template>
  <div class="post-wrapper">
    <div class="post-thumb">
      <div class="image">
        <span class="p-date p-date-image">
          02
          <i>Aug</i>
        </span>

        <router-link to="/blogs/ds">
          <img
            src="https://www.journal-theme.com/1/image/cache/catalog/journal3/gallery/aiony-haust-667702-unsplash-300x225w.jpg.webp"
            width="300"
            height="225"
            alt="Journal Blog is Here"
            title="Journal Blog is Here"
            class="img-responsive lazyload lazyloaded"
          />
        </router-link>
      </div>

      <div class="caption">
        <div class="post-stats">
          <span class="p-author">admin</span>
          <span class="p-comment">14</span>
          <span class="p-view">18349</span>
        </div>

        <div class="name">
          <router-link to="/blogs/ds">
            Journal Blog is Here
          </router-link>
        </div>

        <div class="description">
          The Journal 3 blog has been greatly improved and it now comes with the
          most advanced set of typography tools, including custom drop-cap
          support as well as optional&nbsp;newspaper-like fluid columns. You can
          break up the page in up to 4 columns and change the configuration per
          breakpoint for the best article layout on any screen width. The
          Journal blog i..
        </div>

        <div class="button-group">
          <router-link to="/blogs/1" class="btn btn-read-more">
            <span class="btn-text">Read More</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.post-blog {
  .post-wrapper {
    padding: 0 5px;
    .post-thumb {
      flex-direction: column;
      .caption {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        flex: 1000 0 auto;
        .post-stats {
          display: flex;
          justify-content: center;
          position: absolute;
          transform: translateY(-100%);
          width: 100%;
          background: rgba(240, 242, 245, 0.9);
          padding: 7px;
        }
        .name {
          display: flex;
          width: auto;

          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          margin-top: 12px;
          margin-bottom: 12px;
        }
        .description {
          display: block;
          color: rgba(105, 105, 115, 1);
          text-align: center;
          padding-right: 15px;
          padding-left: 15px;
        }
      }
      .button-group {
        display: flex;
        justify-content: center;
        padding: 15px;
      }
    }
  }
}

.post-thumb {
  display: flex;
  flex-grow: 1;
  min-width: 0;
  position: relative;
  transition: all 0.075s ease-out;
  overflow: hidden;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .image {
    display: block;
    flex: 1 1 auto;
    background: #f8f8f8;
    a {
      overflow: hidden;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;

      img {
        width: 100%;
        transition: 0.3s all;
      }
    }
  }
  .caption {
    background: rgba(248, 248, 248, 1);
    padding: 20px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    flex: 3 3 100%;
    position: relative;
    .post-stats {
      display: flex;
      font-size: 14px;
      color: rgba(139, 145, 152, 1);
      justify-content: flex-start;
      margin-bottom: 10px;
      span {
        align-items: center;
        margin-right: 8px;
        flex-wrap: wrap;
      }
    }
    .name {
      a {
        white-space: normal;
        overflow: visible;
        text-overflow: initial;
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 22px;
        color: rgba(51, 51, 51, 1) !important;
        &:hover {
          color: rgba(233, 102, 49, 1) !important;
        }
      }
    }
    .description {
      display: block;
      color: rgba(139, 145, 152, 1);
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      line-height: 1.3;
    }
  }

  &:hover {
    box-shadow: 0 15px 90px -10px rgba(0, 0, 0, 0.2);
    .image img {
      transform: scale(1.15);
    }
  }
}
.p-date-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 5px 10px;
  pointer-events: none;
  line-height: 1;
  font-size: 16px;
  z-index: 2;
  &::before {
    content: unset !important;
  }
  i {
    padding: 0 4px;
    margin: 0;
    font-style: normal;
    font-size: 10px;
  }
}
</style>
