<template>
  <div class="contact">
    <div class="container">
      <div class="block-map">
        <div class="journal-loading" style="display: none;">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.6803814444893!2d-0.12681944838379433!3d51.50073251891121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604c38c8cd1d9%3A0xb78f2474b9a45aa9!2sBig+Ben!5e0!3m2!1sen!2sro!4v1528897605464"
          width="450"
          height="450"
          frameborder="0"
          style="border: 0;"
          allowfullscreen=""
        ></iframe>
      </div>
      <div class="contact-info">
        <div class="info">
          <div class="item item-1">
            <div class="info-block">
              <div class="info-block-content">
                <div class="info-block-title">Store Address</div>
                <div class="info-block-text">
                  123 Main St,
                  <br />
                  London, UK
                </div>
              </div>
            </div>
          </div>
          <div class="item item-2">
            <a href="tel:18005559090" class="info-block">
              <div class="info-block-content">
                <div class="info-block-title">Call Us</div>
                <div class="info-block-text">
                  Tel: 1.800.555.9090
                  <br />
                  Fax: 1.800.555.9090
                </div>
              </div>
            </a>
          </div>
          <div class="item item-3">
            <div class="info-block">
              <div class="info-block-content">
                <div class="info-block-title">Store Hours</div>
                <div class="info-block-text">
                  Mon-Fri: 10:00 - 20:00
                  <br />
                  Weekend: 12:00 - 16:00
                </div>
              </div>
            </div>
          </div>
          <div class="item item-4">
            <div class="info-block">
              <div class="info-block-content">
                <div class="info-block-title">Custom Blocks</div>
                <div class="info-block-text">
                  Create unlimited blocks with custom styles and add them on any
                  page.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form">
          <h3 class="title">Leave Us a Message</h3>

          <form>
            <fieldset>
              <div class="form-group d-flex flex-wrap">
                <label
                  class="col-sm-2 control-label"
                  for="field-62fdfb15102fb-1"
                >
                  Your Name
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="item[1]"
                    value=""
                    placeholder="Your Name"
                    id="field-62fdfb15102fb-1"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group d-flex flex-wrap">
                <label
                  class="col-sm-2 control-label"
                  for="field-62fdfb15102fb-2"
                >
                  Your Email
                </label>
                <div class="col-sm-10">
                  <input
                    type="email"
                    name="item[2]"
                    value=""
                    placeholder="Your Email"
                    id="field-62fdfb15102fb-2"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group d-flex flex-wrap custom-field">
                <label
                  class="col-sm-2 control-label"
                  for="field-62fdfb15102fb-3"
                >
                  Topic
                </label>
                <div class="col-sm-10">
                  <select
                    name="item[3]"
                    id="field-62fdfb15102fb-3"
                    class="form-control"
                  >
                    <option value="">--- Please Select ---</option>
                    <option value="Capture the information you need">
                      Capture the information you need
                    </option>
                    <option value="Add or remove any fields">
                      Add or remove any fields
                    </option>
                    <option value="Your own custom criteria">
                      Your own custom criteria
                    </option>
                    <option value="Make any field required or not">
                      Make any field required or not
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group d-flex flex-wrap">
                <label
                  class="col-sm-2 control-label"
                  for="field-62fdfb15102fb-4"
                >
                  Message
                </label>
                <div class="col-sm-10">
                  <textarea
                    name="item[4]"
                    rows="5"
                    placeholder="Message"
                    id="field-62fdfb15102fb-4"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
            </fieldset>
            <div class="buttons">
              <div class="pull-right">
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-loading-text="<span>Submit</span>"
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.contact {
  background: #f1f3f4;
  .block-map {
    height: 300px;
    position: relative;

    padding: 20px 0;
    iframe {
      width: 100%;
      height: 100%;
    }
    .journal-loading {
      position: absolute;
      display: flex;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }
  }
  .contact-info {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    .info {
      width: 100%;
      background: rgba(44, 54, 64, 1);
      @media (min-width: 768px) {
        width: 30%;
      }

      .item {
        .info-block {
          border-width: 0;
          border-style: solid;
          border-color: rgba(58, 71, 84, 1);
          padding: 20px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          text-align: left;
          position: relative;
          transition: all 0.075s ease-out;
          height: 100%;
          border-bottom-width: 1px;

          .info-block-title {
            font-size: 16px;
            color: rgba(230, 230, 230, 1);
            font-weight: 700;
            display: block;
          }
          .info-block-text {
            font-size: 12px;
            color: rgba(139, 145, 152, 1);
            font-weight: 400;
            line-height: 1.3;
            display: block;
          }
          &::before {
            margin-right: 15px;
            border-width: 2px;
            border-style: solid;
            border-color: rgba(13, 82, 214, 1);
            border-radius: 50%;
            align-self: center;
            width: 60px;
            height: 60px;
            color: rgba(233, 102, 49, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            line-height: 1;
          }
        }
      }
      .item-1 {
        .info-block {
          &::before {
            content: '\eb7b' !important;
            font-family: icomoon !important;
            font-size: 30px;
          }
        }
      }
      .item-2 {
        .info-block {
          &::before {
            content: '\eaad' !important;
            font-family: icomoon !important;
            font-size: 30px;
          }
        }
      }
      .item-3 {
        .info-block {
          &::before {
            content: '\e943' !important;
            font-family: icomoon !important;
            font-size: 30px;
          }
        }
      }
      .item-4 {
        .info-block {
          border-bottom-width: 0px !important;
          &::before {
            content: '\e922' !important;
            font-family: icomoon !important;
            font-size: 30px;
          }
        }
      }
    }

    .form {
      flex: 1;
      padding: 25px;
      background: #fff;
    }
  }
}
form {
  .form-group {
    .control-label {
      align-items: center;
      + div {
        width: auto;
        flex: 1 1 200px;
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
      }
    }
  }
  .buttons {
    margin-top: 20px !important;
  }
}
</style>
