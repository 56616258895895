<template>
  <form class="cart-table" v-if="cart.length">
    <div class="table-responsive desktop">
      <table class="table table-bordered">
        <thead>
          <tr>
            <td class="text-center td-image">Image</td>
            <td class="text-left td-name">Product Name</td>
            <td class="text-center td-model">Model</td>
            <td class="text-center td-qty">Quantity</td>
            <td class="text-center td-price">Unit Price</td>
            <td class="text-center td-total">Total</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in cart" :key="item">
            <td class="text-center td-image">
              <router-link to="/products/1">
                <img
                  v-if="item.product.images[0].path"
                  :src="item.product.images[0].path"
                  :alt="item.product.name"
                  class="img-fluid"
                  :title="item.product.name"
                />
              </router-link>
            </td>
            <td class="text-left td-name">
              <router-link to="/products/1">
                {{ item.product.name }}
              </router-link>
            </td>
            <td class="text-center td-model">{{ item.product.model }}</td>
            <td class="text-center td-qty">
              <div class="input-group btn-block">
                <div class="stepper">
                  <input
                    type="text"
                    name="quantity"
                    v-model="item.qty"
                    class="form-control"
                  />
                  <span>
                    <i
                      class="fa-solid fa-angle-up"
                      @click="addItemByOne(item)"
                    ></i>
                    <i
                      class="fa-solid fa-angle-down"
                      @click="removeItemByOne(item)"
                    ></i>
                  </span>
                </div>
                <span class="input-group-btn">
                  <button
                    type="submit"
                    class="btn btn-primary btn-update tooltip-parent"
                  >
                    <span class="tooltips">Update</span>
                    <i class="fa fa-refresh"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-remove tooltip-parent"
                    @click="removeItem(item)"
                  >
                    <span class="tooltips">Remove</span>
                    <i class="fa fa-times-circle"></i>
                  </button>
                </span>
              </div>
            </td>
            <td class="text-center td-price">
              $ {{ item.product.price_normal }}
            </td>
            <td class="text-center td-total">
              ${{ item.product.price_normal * item.qty }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="cart-mobile">
      <div class="row" v-for="item in cart" :key="item">
        <div class="col-sm-3 image">
          <router-link to="/products/1">
            <img
              v-if="item.product.images[0].path"
              :src="item.product.images[0].path"
              :alt="item.product.name"
              :title="item.product.name"
            />
          </router-link>
        </div>
        <div class="col-sm-9 details">
          <div class="text-left td-name">
            <h5 class="title">PRODUCT NAME</h5>
            <router-link to="/products/1">
              {{ item.product.name }}
            </router-link>
          </div>
          <div class="td-model">
            <h5 class="title">model</h5>
            {{ item.product.model }}
          </div>
          <div class="td-qty">
            <h5 class="title">QUANTITY</h5>
            <div class="input-group btn-block">
              <div class="stepper">
                <input
                  type="text"
                  name="quantity"
                  v-model="item.qty"
                  class="form-control"
                />
                <span>
                  <i
                    class="fa-solid fa-angle-up"
                    @click="addItemByOne(item)"
                  ></i>
                  <i
                    class="fa-solid fa-angle-down"
                    @click="removeItemByOne(item)"
                  ></i>
                </span>
              </div>
              <span class="input-group-btn">
                <button
                  type="submit"
                  class="btn btn-primary btn-update tooltip-parent"
                >
                  <span class="tooltips">Update</span>
                  <i class="fa fa-refresh"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-remove tooltip-parent"
                  data-original-title="Remove"
                  @click="removeItem(item)"
                >
                  <span class="tooltips">Remove</span>
                  <i class="fa fa-times-circle"></i>
                </button>
              </span>
            </div>
          </div>
          <div class="td-price">
            <h5 class="title">price</h5>
            ${{ item.product.price_normal }}
          </div>
          <div class="td-total">
            <h5 class="title">total</h5>
            ${{ item.product.price_normal * item.qty }}
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      quantity: 1,
    }
  },
  methods: {
    removeItem(item) {
      this.$store.commit('removeItem', item)
    },
    removeItemByOne(e) {
      this.$store.commit('removeItemByOne', e)
    },
    addItemByOne(e) {
      this.$store.commit('addItemByOne', e)
    },
  },
  computed: {
    cart() {
      return this.$store.getters.cart
    },
    totalPrice() {
      return this.$store.getters.totalPrice
    },
  },
}
</script>

<style lang="scss">
.cart-table {
  .stepper {
    height: 34px;
    input {
      height: 34px;
    }
  }
  .td-qty {
    .input-group-btn {
      display: flex;
      button {
        height: 34px !important;
      }
    }
  }
  .input-group {
    flex-wrap: nowrap;
  }
}

.cart-mobile {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(226, 226, 226);
  .image {
    a {
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    @media (min-width: 576px) {
      margin-top: 0;
    }
    & > div {
      padding-bottom: 10px;
      &:not(:last-child) {
        margin-bottom: 10px;
        border-bottom: 1px solid rgb(226, 226, 226);
      }
      h5.title {
        &::after {
          content: unset;
        }
        margin-right: 5px;
        min-width: 150px;
        color: #333 !important;
        font-size: 16px;
        display: inline-flex;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }

    .td-qty {
      display: flex;
      align-items: center;
    }
  }
}
.desktop {
  display: none;
}
@media (min-width: 768px) {
  .desktop {
    display: block;
  }
  .cart-mobile {
    display: none;
  }
}
.btn-remove {
  background: rgba(221, 14, 28, 1) !important;
  color: #fff !important;
}
</style>
