<template>
  <div class="compare">
    <div class="page-title-header">
      <h1 class="title page-title"><span>Product Comparison</span></h1>
    </div>
    <div class="container">
      <div class="row">
        <div class="table-responsive p-0" v-if="compare.length">
          <table class="table table-borderless">
            <thead>
              <tr>
                <td colspan="5"><strong>Product Details</strong></td>
              </tr>
            </thead>
            <tbody>
              <tr class="compare-name">
                <td>Product</td>
                <td v-for="item in compare" :key="item.id">
                  <a href="/products/1">
                    <strong>{{ item.name }}</strong>
                  </a>
                </td>
              </tr>
              <tr class="compare-image">
                <td>Image</td>
                <td class="text-left" v-for="item in compare" :key="item.id">
                  <img
                    v-if="item.images[0].path"
                    :src="item.images[0].path"
                    :alt="item.name"
                    :title="item.name"
                    class="img-thumbnail img-fluid"
                  />
                </td>
              </tr>
              <tr class="compare-price">
                <td>Price</td>
                <td class="" v-for="item in compare" :key="item.id">
                  ${{ item.price_normal }}
                </td>
              </tr>
              <tr class="compare-model">
                <td>Model</td>
                <td v-for="item in compare" :key="item.id">{{ item.model }}</td>
              </tr>
              <tr class="compare-manufacturer">
                <td>Brand</td>
                <td v-for="item in compare" :key="item.id">{{ item.brand }}</td>
              </tr>
              <tr class="compare-availability">
                <td>Availability</td>
                <td v-for="item in compare" :key="item.id">In Stock</td>
              </tr>
              <tr class="compare-rating">
                <td>Rating</td>
                <td class="rating" v-for="item in compare" :key="item.id">
                  <Rating />
                  <br />
                  Based on 0 reviews.
                </td>
              </tr>
              <tr class="compare-summary">
                <td>Summary</td>
                <td class="description" v-for="item in compare" :key="item.id">
                  {{ item.description }}
                </td>
              </tr>
              <tr class="compare-weight">
                <td>Weight</td>
                <td v-for="item in compare" :key="item.id">0.00kg</td>
              </tr>
              <tr class="compare-dimensions">
                <td>Dimensions (L x W x H)</td>
                <td v-for="item in compare" :key="item.id">
                  0.00cm x 0.00cm x 0.00cm
                </td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <td colspan="5"><strong>Style</strong></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Material</td>
                <td v-for="item in compare" :key="item.id">cotton</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Style</td>
                <td v-for="item in compare" :key="item.id">riding</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Dress Length</td>
                <td v-for="item in compare" :key="item.id">riding</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Attribute 1</td>

                <td v-for="item in compare" :key="item.id">Sample 2</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Attribute 2</td>

                <td v-for="item in compare" :key="item.id">Example 2</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td class="out-of-stock" v-for="item in compare" :key="item.id">
                  <teleport to="body">
                    <transition name="fade-in">
                      <Notification
                        :imgSrc="imgSrc"
                        :message="message"
                        :mode="mode"
                        @closeModal="closeNotfiy"
                        v-if="openNotification == 1"
                      />
                    </transition>
                  </teleport>
                  <div class="compare-buttons">
                    <button
                      class="btn btn-primary btn-cart"
                      onclick="cart.add('304', '1');"
                    >
                      <span>Add to Cart</span>
                    </button>
                    <a
                      role="button"
                      class="btn btn-danger btn-remove"
                      @click="remove(item)"
                    >
                      <span>Remove</span>
                    </a>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <p v-else>Your compare list is empty.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from '@/components/Rating.vue'
import Notification from '@/components/products/Notification.vue'
export default {
  components: { Rating, Notification },
  data() {
    return {
      openNotification: 0,
    }
  },
  computed: {
    compare() {
      return this.$store.getters.compare
    },
  },
  methods: {
    remove(e) {
      this.imgSrc = ''
      this.message = ''
      this.mode = ''
      this.$store.commit('removeItemCompare', e)
      this.$emit('alert-message', {
        type: 'alert-success',
        message: 'Success: You have modified your wish list!',
      })
      this.openNotification = 1
      this.imgSrc = `${e.images[0].path}`
      this.message = `<h4>You have removed <a> ${e.name}</a> Successfully</h4>`
      setTimeout(() => {
        this.openNotification = 0
      }, 3000)
    },
  },
}
</script>

<style lang="scss">
.compare {
  font-family: 'Roboto';
  padding-bottom: 20px;
  table tfoot td {
    background: rgba(226, 226, 226, 1) !important;
    border-width: 0 !important;
    border-top-width: 1px !important;
    border-style: solid !important;
    border-color: rgba(226, 226, 226, 1) !important;
  }
}
.compare table > tbody > tr > td:first-child {
  white-space: nowrap;
}
.compare .compare-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 7px 0;
  button.btn,
  a {
    font-size: 13px;
    font-family: 'Roboto';
    flex-grow: 1;
    margin: 4px;
    text-decoration: none !important;
  }
}
</style>
