<template>
  <div class="page-title-header">
    <h1 class="title page-title">
      <span>All Products</span>
    </h1>
  </div>
  <div class="container">
    <div class="row">
      <Aside />
      <Content category="true" />
    </div>
  </div>
</template>

<script>
// import ProductCard from '@/components/layout/ProductCard.vue'
import Aside from '@/components/products/Aside.vue'
import Content from '@/components/products/Content.vue'
export default {
  components: { Aside, Content },
}
</script>

<style lang="scss">
.products-page {
  background: #f8f8f8;
}
</style>
