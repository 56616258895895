<template>
  <div class="faq">
    <div class="page-title-header">
      <h1 class="title page-title"><span>Frequently Asked Questions</span></h1>
    </div>
    <div class="container">
      <div id="information-information">
        <div class="d-flex flex-wrap">
          <div id="right-column">
            <div class="content">
              <div class="my-account">
                <h2 class="title">My Account</h2>
                <ul class="list-unstyled account-list justify-content-center">
                  <li class="edit-info">
                    <a class="" role="button" @click="faq = true">
                      Clients
                    </a>
                  </li>
                  <li class="edit-pass">
                    <a role="button" @click="faq = true">
                      Shippin
                    </a>
                  </li>
                  <li class="edit-address">
                    <a role="button" @click="faq = true">
                      Delivery
                    </a>
                  </li>
                </ul>
              </div>
              <b-accordion v-if="faq">
                <b-accordion-item
                  title="DO I NEED AN ACCOUNT TO PLACE AN ORDER?"
                  visible
                >
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Consequuntur asperiores maiores nemo quia voluptatem aut
                  temporibus nobis facere assumenda tenetur. Magni dolor quos,
                  repellat ipsum quisquam explicabo similique tempora dolorum!
                </b-accordion-item>
                <b-accordion-item
                  title="DO I NEED AN ACCOUNT TO PLACE AN ORDER?"
                  v-for="n in 5"
                  :key="n"
                >
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Consequuntur asperiores maiores nemo quia voluptatem aut
                  temporibus nobis facere assumenda tenetur. Magni dolor quos,
                  repellat ipsum quisquam explicabo similique tempora dolorum!
                </b-accordion-item>
              </b-accordion>
            </div>
          </div>
          <PagesAside />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PagesAside from '@/components/PagesAside.vue'
export default {
  components: { PagesAside },
  data() {
    return {
      faq: false,
    }
  },
}
</script>

<style lang="scss">
.faq {
  background: #f8f8f8;
}
#information-information {
  .accordion-item {
    background: none;
    border: none;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
    .accordion-button {
      background: none;
      text-transform: uppercase;
      padding: 15px 5px 15px 0;

      font-family: 'Montserrat';
      font-weight: 600;
      color: rgba(105, 105, 115, 1);
      text-transform: uppercase;
      padding: 15px;
      padding-right: 5px;
      padding-left: 0px;
      &::after {
        content: '\f067' !important;
        font-family: icomoon !important;
        transform: none;
        margin-right: 3px;
        background: none;
      }
      &:hover {
        color: rgba(15, 58, 141, 1);
      }
      &:not(.collapsed) {
        color: rgba(15, 58, 141, 1);
        &::after {
          content: '\f068' !important;
          color: rgba(15, 58, 141, 1);
        }
      }
    }
    .accordion-body {
      padding: 0;
      padding-bottom: 20px;
      color: #333;
      font-family: 'Roboto';
    }
  }
}
</style>
