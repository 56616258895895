<template>
  <div class="manufacturer-wrapper">
    <div class="manufacturer-thumb">
      <div class="image">
        <router-link to="/products/1">
          <img
            src="https://www.journal-theme.com/1/image/cache/catalog/journal3/brands/superbrand-150x150.jpg.webp"
            alt="SuperBrand"
            title="SuperBrand"
            class="img-fluid"
          />
        </router-link>
      </div>

      <div class="caption">
        <div class="name">
          <router-link to="/products/1">
            SuperBrand
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
/* @media (max-width: 470px) {
  .manufacturer-wrapper .manufacturer-thumb .image a {
    padding: 10px;
  }
}
@media (max-width: 1024px) {
  .manufacturer-wrapper .manufacturer-thumb .image img {
    opacity: 1;
  }

  .manufacturer-wrapper .manufacturer-thumb .name {
    display: none;
  }
} */
.manufacturer-wrapper {
  padding: 10px;
  .manufacturer-thumb {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    transition: all 0.075s ease-out;
    position: relative;

    .image {
      display: block;
      width: 100%;
      a {
        padding: 10px !important;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(226, 226, 226, 1);
        border-radius: 7px;
        overflow: hidden;
        width: 100% !important;
        img {
          transition: all 0.075s ease-out;
          opacity: 0.7;
          filter: grayscale(1);
        }
      }
    }
    .caption {
      .name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        opacity: 0;
        transform: translate3d(0, 10px, 0);
        padding: 3px;
        transition: all 0.075s ease-out;
        background-color: #0f3a8d !important;
        text-align: center;

        &:hover {
          background-color: #0d52d6 !important;
        }
        a {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-family: 'Roboto';
          color: rgba(230, 230, 230, 1) !important;
          font-weight: 700;
          text-align: center;
        }
      }
    }
    &:hover {
      .image {
        img {
          opacity: 1;
          transform: scale(1.15);
          filter: grayscale(0);
        }
      }
      .caption {
        .name {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}
</style>
