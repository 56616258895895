<template>
  <section class="products-wrapper all-products">
    <div class="category" v-if="category">
      <div class="block-content" :class="expanded ? ' expand-content' : ''">
        <p v-for="n in 4" :key="n">
          The
          <b>category description</b>
          can be positioned anywhere on the page via the layout page builder
          inside the Blocks module with full typography control and advanced
          container styling options.
        </p>

        <div class="block-expand-overlay text-center">
          <a
            class="block-expand btn-primary btn"
            role="button"
            @click="expanded = !expanded"
          ></a>
        </div>
      </div>
    </div>
    <div class="products-filter">
      <div class="grid-list">
        <button
          id="btn-grid-view"
          class="view-btn"
          @click="listType('horizontal')"
        ></button>
        <button
          id="btn-list-view"
          class="view-btn"
          @click="listType('vertical')"
        ></button>
        <a href="#" id="compare-total" class="compare-btn">
          <span class="links-text">Product Compare</span>
          <span class="count-badge count-zero">0</span>
        </a>
      </div>
      <div class="select-group">
        <div class="input-group sort-by">
          <label class="input-group-addon" for="input-sort">Sort By:</label>

          <select id="input-sort" class="form-select">
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;sort=p.sort_order&amp;order=ASC&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628"
              selected="selected"
            >
              Default
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;sort=pd.name&amp;order=ASC&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628"
            >
              Name (A - Z)
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;sort=pd.name&amp;order=DESC&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628"
            >
              Name (Z - A)
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;sort=p.price&amp;order=ASC&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628"
            >
              Price (Low &gt; High)
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;sort=p.price&amp;order=DESC&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628"
            >
              Price (High &gt; Low)
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;sort=rating&amp;order=DESC&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628"
            >
              Rating (Highest)
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;sort=rating&amp;order=ASC&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628"
            >
              Rating (Lowest)
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;sort=p.model&amp;order=ASC&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628"
            >
              Model (A - Z)
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;sort=p.model&amp;order=DESC&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628"
            >
              Model (Z - A)
            </option>
          </select>
        </div>
        <div class="input-group per-page">
          <label class="input-group-addon" for="input-limit">Show:</label>
          <select id="input-limit" class="form-select">
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628&amp;limit=12"
              selected="selected"
            >
              12
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628&amp;limit=25"
            >
              25
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628&amp;limit=50"
            >
              50
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628&amp;limit=75"
            >
              75
            </option>
            <option
              value="https://www.journal-theme.com/1/index.php?route=product/catalog&amp;fc=69&amp;fo2=53&amp;fmin=1053&amp;fmax=5628&amp;limit=100"
            >
              100
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="products horizontal" v-if="horizontal">
      <div class="row">
        <div
          class="col-sm-6 col-lg-4 mb-3"
          v-for="product in products"
          :key="product.id"
        >
          <ProductCard :items="product" />
        </div>
      </div>
    </div>
    <div class="products vertical" v-if="vertical">
      <div class="row" :class="vertical ? 'vertical' : ''">
        <div
          class="col-12 p-0 mb-2"
          v-for="product in products"
          :key="product.id"
        >
          <ProductCard :items="product" />
        </div>
      </div>
    </div>

    <div class="main-products product-grid">
      <div
        class="ias-noneleft"
        style="text-align: center;"
        id="ias_noneleft_1660557786112"
      >
        You have reached the end of the list.
      </div>
    </div>
    <div class="row pagination-results" style="display: none;">
      <div class="col-sm-6 text-left"></div>
      <div class="col-sm-6 text-right">Showing 1 to 3 of 3 (1 Pages)</div>
    </div>
  </section>
</template>

<script>
import ProductCard from '../layout/ProductCard.vue'
import Products from '@/products.json'

export default {
  components: { ProductCard },
  props: ['category'],
  data() {
    return {
      horizontal: true,
      vertical: false,
      expanded: false,
      products: Products,
    }
  },
  methods: {
    listType(list) {
      this.horizontal = false
      this.vertical = false
      this[list] = true
    },
  },
}
</script>

<style lang="scss">
section.products-wrapper {
  padding-left: 20px;
  max-width: 100%;
  @media (min-width: 768px) {
    max-width: calc(100% - 240px);
  }
  @media (min-width: 1300px) {
    max-width: calc(100% - 260px);
  }
  .products-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .select-group {
    display: flex;
    align-items: center;
    margin-left: auto;
    .input-group {
      align-items: center;
      label {
        width: auto !important;
        display: flex !important;
        align-items: center !important;
        border-radius: 3px !important;
        background: #eee !important;
        color: #555 !important;
        padding: 5px 10px;
        font-size: 12px;
        height: 26px;
      }
      select {
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 3px;
        box-shadow: none;
        height: 26px;
        line-height: 1;
      }
    }
  }
  .grid-list {
    button {
      display: inline-flex;
      line-height: 1;
      border: none;
      background: none;
      padding: 0;
    }
    a.compare-btn {
      align-items: center;
      color: rgba(13, 82, 214, 1) !important;
      &:hover {
        color: rgba(233, 102, 49, 1) !important;
      }
    }
  }
}
.products-filter {
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-width: 0;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: rgba(226, 226, 226, 1);
}
.block-content {
  overflow: hidden;
  max-height: 70px;
  position: relative;
  transition: all 0.3s;
  p {
    color: #333 !important;
    font-family: 'Roboto';
    &:hover {
      color: #333 !important;
    }
  }
  &.expand-content {
    max-height: 100%;
    .block-expand {
      &::before {
        content: '\f077' !important;
      }
      &::after {
        content: 'Show Less';
      }
    }
  }
  .block-expand-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(to bottom, transparent, rgba(248, 248, 248, 1));
  }
}
.is-rtl {
  .all-products {
    .product-thumb .caption .name {
      padding-left: 10px;
      padding-right: 0px;
    }
    .product-thumb .caption .price {
      padding-left: 10px;
      padding-right: 0px;
    }
    .product-thumb .caption .buttons-wrapper .button-group {
      padding-left: 10px;
      padding-right: 0px;
    }
  }
  .main-menu-wrapper
    .main-menu-container
    .main-menu.main-menu-1
    .main-menu-item-4
    .content
    .item1
    .item-content
    a.catalog-title {
    margin: 0 0 8px auto;
  }
  .main-menu-wrapper
    .main-menu-container
    .main-menu.main-menu-1
    .main-menu-item-4
    .content
    .item1
    .item-content
    .item-assets
    > a {
    margin-left: 10px;
    margin-right: 0px;
  }
  .main-menu-wrapper
    .main-menu-container
    .main-menu.main-menu-2
    .main-menu-item-1
    > a::before {
    margin-left: 5px;
    margin-right: 0px;
  }
  .main-menu-wrapper
    .main-menu-container
    .main-menu.main-menu-1
    .main-menu-item-4
    .content
    .item2 {
    padding: 0 0 0 10px !important;
  }
  .main-menu-wrapper
    .main-menu-container
    .main-menu.main-menu-2
    .main-menu-item-2
    > a::before {
    margin-left: 0px;
    margin-right: 7px;
  }
  .btn-close {
    right: unset;
    left: 10px;
  }
}

.is-rtl {
  section.products-wrapper .grid-list button {
    margin: 0 0 0 10px;
  }
  .grid-list .compare-btn::before {
    margin: 0 0 0 5px;
  }
  .product-labels {
    align-items: flex-start;
  }
}
</style>
