<template>
  <div id="account-wishlist" class="account-wishlist">
    <div id="content" class="px-0">
      <div class="table-responsive" v-if="washlist.length">
        <table class="table table-borderless">
          <thead>
            <tr>
              <td class="td-image">Image</td>
              <td class="td-name">Product Name</td>
              <td class="td-model">Model</td>
              <td class="td-stock">Stock</td>
              <td class="td-price">Unit Price</td>
              <td class="td-action">Action</td>
            </tr>
          </thead>
          <tbody>
            <tr class="" v-for="item in washlist" :key="item.id">
              <teleport to="body">
                <transition name="fade-in">
                  <Notification
                    :imgSrc="imgSrc"
                    :message="message"
                    :mode="mode"
                    @closeModal="closeNotfiy"
                    v-if="openNotification == 1"
                  />
                </transition>
              </teleport>
              <td class="td-image">
                <router-link to="/products/1">
                  <img
                    v-if="item.images[0].path"
                    :src="item.images[0].path"
                    :alt="item.name"
                    :title="item.name"
                    class="img-fluid"
                  />
                </router-link>
              </td>
              <td class="td-name">
                <router-link to="/products/1">
                  {{ item.name }}
                </router-link>
              </td>
              <td class="td-model">{{ item.model }}</td>
              <td class="td-stock out-of-stock">2-3 Days</td>
              <td class="td-price">
                <div class="price">${{ item.price_normal }}</div>
              </td>
              <td class="td-action">
                <button type="button" class="btn btn-primary tooltip-parent">
                  <span class="tooltips">Add to Cart</span>
                  <i class="fa fa-shopping-cart"></i>
                </button>
                <button
                  role="button"
                  title=""
                  class="btn btn-danger btn-remove tooltip-parent"
                  @click="remove(item)"
                >
                  <span class="tooltips">Remove</span>
                  <i class="fa fa-times"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-else>Your wish list is empty.</p>
      <div class="buttons">
        <div class="pull-right">
          <router-link to="/account" class="btn btn-primary">
            Continue
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '../products/Notification.vue'
export default {
  components: { Notification },
  data() {
    return {
      openNotification: 0,
    }
  },
  computed: {
    washlist() {
      return this.$store.getters.washlist
    },
  },
  methods: {
    remove(e) {
      this.imgSrc = ''
      this.message = ''
      this.mode = ''
      this.$store.commit('removeItemWashlist', e)
      this.$emit('alert-message', {
        type: 'alert-success',
        message: 'Success: You have modified your wish list!',
      })
      this.openNotification = 1
      this.imgSrc = `${e.images[0].path}`
      this.message = `<h4>You have removed <a> ${e.name}</a> Successfully</h4>`
      setTimeout(() => {
        this.openNotification = 0
      }, 3000)
    },
  },
}
</script>

<style lang="scss">
.account-wishlist {
  & > div > p {
    text-align: start;
  }
  .td-stock.in-stock {
    color: rgba(80, 173, 85, 1);
    font-weight: 700;
  }
  .td-stock.out-of-stock {
    color: rgba(221, 14, 28, 1);
    font-weight: 700;
  }
  .td-price {
    b {
      color: rgba(221, 14, 28, 1);
      margin-right: 5px;
    }
    s {
      color: rgba(105, 105, 115, 1);
    }
  }
  .tooltip-parent .tooltips {
    font-size: 12px !important;
  }
}

table {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgba(226, 226, 226, 1) !important;
  td {
    text-align: start;
    color: rgba(105, 105, 115, 1);
    background: rgba(255, 255, 255, 1);
    padding: 12px !important;
    min-width: 100px;
  }
  thead {
    background: #eeeeee;
    td {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      background: rgba(238, 238, 238, 1);
      border-bottom-width: 1px !important;
    }
  }
  tbody {
    tr {
      background: #fff;
      &:nth-child(even) {
        td {
          background: rgba(250, 250, 250, 1);
        }
      }
      td,
      th {
        border-width: 0 !important;
        border-top-width: 0px !important;
        border-bottom-width: 0px !important;
        border-style: solid !important;
        border-color: rgba(226, 226, 226, 1) !important;
        vertical-align: middle;
      }
      .td-action {
        button {
          display: inline-flex;
          padding: 10px;
          border: none;
          font-size: 13px;
          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }
  tfoot {
    td {
      background: rgba(238, 238, 238, 1);
      td,
      th {
        border-width: 0 !important;
        border-top-width: 1px !important;
        border-style: solid !important;
        border-color: rgba(226, 226, 226, 1) !important;
      }
    }
  }

  a {
    color: rgba(13, 82, 214, 1) !important;
    text-decoration: underline !important;
    display: inline-block !important;
    &:hover {
      color: rgba(233, 102, 49, 1) !important;
      text-decoration: none !important;
    }
  }
}

.table-responsive {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(226, 226, 226, 1);
}

.table-responsive > table {
  border-width: 0px !important;
  margin-bottom: 0 !important;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(233, 102, 49, 1);
  border-radius: 5px;
}

.table-responsive::-webkit-scrollbar {
  height: 1px;
  width: 1px;
}
</style>
