<template>
  <div class="grid-row grid-row-bottom-5">
    <div class="container">
      <div class="grid-cols">
        <div class="grid-col grid-col-bottom-5-1">
          <div class="grid-items">
            <div class="grid-item grid-item-bottom-5-1-1">
              <div class="module title-module module-title-281">
                <div class="title-wrapper">
                  <h3>What are people saying about us</h3>
                  <div class="title-divider"></div>
                  <div class="subtitle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col-bottom-5-2">
          <div class="grid-items">
            <div class="grid-item grid-item-bottom-5-2-1">
              <div class="module">
                <div class="module-body">
                  <div class="carousel-wrapper">
                    <carousel v-bind="settings">
                      <slide v-for="n in 10" :key="n">
                        <TestimonialsCard />
                      </slide>
                      <template #addons>
                        <navigation />
                        <pagination />
                      </template>
                    </carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TestimonialsCard from '../layout/TestimonialsCard.vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
  components: { TestimonialsCard, Carousel, Slide, Pagination, Navigation },
  data() {
    return {
      settings: {
        itemsToShow: 1,

        breakpoints: {
          768: {
            itemsToShow: 3,
          },

          570: {
            itemsToShow: 2,
          },
        },
      },
    }
  },
}
</script>

<style></style>
