<template>
  <div class="side-product-wrapper">
    <div class="side-product d-flex position-relative flex-wrap">
      <div class="image">
        <router-link to="/products/1" class="product-img">
          <img
            src="https://www.journal-theme.com/1/image/cache/catalog/journal3/products/footwear/shoe-70x70.jpg.webp"
            width="70"
            height="70"
            alt="Women's Maria Pump"
            title="Women's Maria Pump"
            class="img-first"
          />
        </router-link>

        <div class="quickview-button">
          <a class="btn-primary btn-quickview tooltip-parent">
            <span class="tooltips">Quickview</span>
          </a>
        </div>
      </div>

      <div class="caption">
        <div class="name">
          <router-link to="/products/1">
            Women's Maria Pump
          </router-link>
        </div>

        <div class="price">
          <span class="price-old">$425.00</span>
          <span class="price-new">$425.00</span>
        </div>

        <div class="button-group">
          <a class="btn-cart tooltip-parent">
            <span class="btn-text tooltips">
              Add to Cart
            </span>
          </a>
          <a class="btn-wishlist tooltip-parent">
            <span class="btn-text tooltips">
              Add to Wish List
            </span>
          </a>
          <a class="btn-compare tooltip-parent">
            <span class="btn-text tooltips">
              Compare this Product
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.side-product-wrapper {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  .side-product {
    background: rgba(255, 255, 255, 1);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(226, 226, 226, 1);
    border-radius: 3px;
    padding: 7px;
    width: 100%;
    display: block;

    .image {
      width: 100%;
      @media (min-width: 570px) {
        width: 25%;
      }
      position: relative;
      a {
        height: 100%;
        display: flex;
      }
      img {
        width: 100%;
        height: 100%;
        max-height: 170px;
        max-width: 170px;
        border-radius: 3px;
      }
      .quickview-button {
        .btn-quickview {
          width: 30px;
          height: 30px;
        }
      }
    }
    .name {
      display: flex;
      margin-bottom: 2px;
      text-align: start;
      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 13px;
        color: rgba(51, 51, 51, 1) !important;
        line-height: 1.3;
      }
    }
    .price {
      display: flex;
      align-items: center;
      flex-direction: row;
      font-size: 14px;
      margin-bottom: 2px;
      color: #333;
      font-family: 'Roboto';
      .price-old {
        margin: 0;
        order: 2;
        font-size: 13px;
        color: rgba(105, 105, 115, 1);
        text-decoration: line-through;
      }
      .price-new {
        margin: 0 5px 0 0;
        order: 1;
        color: rgba(221, 14, 28, 1);
      }
    }
    .caption {
      width: 100%;
      @media (min-width: 570px) {
        width: calc(100% - 25%);
      }

      padding-left: 8px;
    }

    .button-group {
      display: flex;
      margin-left: -3px;
      .btn-cart,
      .btn-wishlist,
      .btn-compare {
        display: inline-flex;
        width: 30px;
        height: 30px;
        margin-right: 8px;
        color: #333;
        cursor: pointer;
        &:hover {
          color: rgba(233, 102, 49, 1) !important;
        }
        &::before {
          font-size: 14px;
          left: 1px;
        }
      }
    }
    &:hover {
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      .btn-quickview {
        visibility: visible;
        opacity: 1;

        &::before {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .side-product-wrapper .side-product .quickview-button {
    visibility: visible;
    opacity: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
}

@media (max-width: 470px) {
  .side-product-wrapper .side-product .image {
    width: 100%;
  }

  .side-product-wrapper .side-product .caption {
    width: 100%;
    padding-top: 8px;
    padding-left: 0px;
  }

  .side-product-wrapper .side-product {
    display: flex;
  }
}
.module-newsletter-67 .input-group .input-group-btn .btn {
  height: calc(100% - 5px) !important;
  top: 2px;
}

.is-rtl {
  .side-product-wrapper .side-product {
    flex-direction: row-reverse;
  }
  .side-product-wrapper .side-product .name {
    justify-content: end;
  }
  .side-product-wrapper .side-product .price {
    justify-content: end;
  }
  .side-product-wrapper .side-product .button-group {
    justify-content: end;
  }
  .side-product-wrapper .side-product .button-group .btn-cart,
  .side-product-wrapper .side-product .button-group .btn-wishlist,
  .side-product-wrapper .side-product .button-group .btn-compare {
    margin-left: 8px;
    margin-right: 0px;
  }
  .module-newsletter-67 .newsletter-text {
    text-align: start;
  }
  .module-newsletter-67 .input-group .input-group-btn .btn {
    right: unset;
    left: 7px;
    right: unset !important;
  }
  footer .grid-row-3 .grid-col-1 {
    border-right-width: 0;
    border-left-width: 1px;
  }
  .module-newsletter-67 .btn::before {
    margin-right: 0px;
    margin-left: 5px;
  }
  .module-newsletter-67 .module-body .newsletter-form .checkbox {
    text-align: start;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    a {
      margin-right: 5px;
    }
    input {
      margin-left: 5px;
    }
  }
}
.module-newsletter-67 .module-body .newsletter-form .checkbox {
  display: flex;
  label {
    display: flex;
    align-items: center;
  }
}
</style>
