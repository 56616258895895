<template>
  <div id="bottom" class="bottom top-row">
    <div class="container">
      <div class="grid-rows">
        <div class="grid-row grid-row-bottom-2">
          <div class="grid-cols">
            <div class="grid-col grid-col-bottom-2-1">
              <div class="grid-items">
                <div class="grid-item grid-item-bottom-2-1-1">
                  <div class="module title-module module-title-143">
                    <div class="title-wrapper">
                      <h3>Shop by Brand</h3>
                      <div class="title-divider"></div>
                      <div class="subtitle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-col grid-col-bottom-2-2">
              <div class="grid-items">
                <div class="grid-item grid-item-bottom-2-2-1">
                  <div
                    class="module module-manufacturers module-manufacturers-38 module-manufacturers- carousel-mode"
                  >
                    <div class="module-body">
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true }"
                      >
                        <swiper-slide v-for="n in 10" :key="n">
                          <BrandCard />
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandCard from '../layout/BrandCard.vue'
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
export default {
  components: { BrandCard, Swiper, SwiperSlide },
  data() {
    return {
      modules: [Navigation, Pagination, A11y],
      breakpoints: {
        1025: {
          slidesPerView: 9,
        },

        768: {
          slidesPerView: 6,
        },
      },
    }
  },
}
</script>

<style></style>
