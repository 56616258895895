<template>
  <div class="blogs-page">
    <div class="page-title-header">
      <h1 class="title page-title">
        <span>
          Journal Blog
          <router-link class="blog-feed" to="/blogs/1" target="_blank">
            <span class="feed-text">RSS Feed</span>
          </router-link>
        </span>
      </h1>
    </div>
    <div class="container" v-if="!id">
      <div class="row">
        <Content />
        <Aside />
      </div>
    </div>
    <SingleBlog v-else />
  </div>
</template>

<script>
import Content from '@/components/blogs/Content.vue'
import Aside from '@/components/blogs/Aside.vue'
import SingleBlog from '@/components/blogs/SingleBlog.vue'
export default {
  components: { Content, Aside, SingleBlog },
  data() {
    return {
      id: '',
    }
  },
  mounted() {
    this.id = this.$route.params.id
  },
  watch: {
    $route() {
      this.id = this.$route.params.id
    },
  },
}
</script>

<style>
.blogs-page {
  background: #f8f8f8;
}
</style>
