<template>
  <div class="grid-row grid-row-top-1">
    <div class="container p-0">
      <div class="grid-cols">
        <div class="grid-col grid-col-top-1-1">
          <div class="grid-items banner">
            <!-- CoverflowEffect, -->
            <swiper
              class="w-100"
              loop="true"
              :modules="[Navigation, Pagination, A11y, Autoplay]"
              :slides-per-view="1"
              :space-between="50"
              @swiper="onSwiper"
              :autoplay="{ delay: 5000 }"
              navigation
              :pagination="{ clickable: true }"
              grab-cursor
              :effect="'creative'"
              :creativeEffect="{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ['100%', 0, 0],
                },
              }"
              @slideChange="onSlideChange"
            >
              <swiper-slide v-for="n in 3" :key="n">
                <img src="@/assets/1.png" class="img-fluid" alt="" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="grid-col grid-col-top-1-2">
          <div class="grid-items">
            <div class="grid-item grid-item-top-1-2-1">
              <div
                id="banners-62daa801bf888"
                class="module module-banners module-banners-259"
              >
                <div class="module-body d-flex flex-wrap">
                  <div class="module-item module-item-1">
                    <router-link to="/categories/1">
                      <img
                        class="img-fluid"
                        src="https://www.journal-theme.com/1/image/cache/catalog/journal3/slider/demo1/b1-320x210w.png.webp"
                        alt=""
                      />
                      <div class="banner-text banner-caption">
                        <span>
                          <s>Wearables</s>
                          Intelligent &amp; Durable Design
                        </span>
                      </div>
                    </router-link>
                  </div>
                  <div class="module-item module-item-2">
                    <router-link to="/categories/1">
                      <img
                        src="https://www.journal-theme.com/1/image/cache/catalog/journal3/slider/demo1/b2-320x210w.png.webp"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="banner-text banner-caption">
                        <span>
                          <s>Computers</s>
                          Build your own high powered PC
                        </span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
import { Navigation, Pagination, A11y, Autoplay, EffectCreative } from 'swiper'
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
      },
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper)
    }

    return {
      onSwiper,
      Navigation,
      Pagination,
      A11y,
      Autoplay,
      EffectCreative,
    }
  },
}
</script>

<style lang="scss">
.module-banners .module-item > a {
  position: relative;
}
.module-banners .module-item > a::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  transition: all 0.15s ease-out;
  z-index: 2;
  border-radius: inherit;
  background: rgba(15, 58, 141, 0.15);
}
.banner {
  border-radius: 10px;
  overflow: hidden;

  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    border-radius: 0 !important;
    position: absolute;
    transition: all 0.1s ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    left: auto;
    right: 0;
    opacity: 0;
    background: #fff;
    z-index: 10;
    color: #333;
    svg {
      fill: #333 !important;
    }
  }
  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 1;
      &:hover {
        background: #0d52d6;

        color: #fff !important;
      }
    }
  }
  .swiper-button-prev {
    transform: translateY(calc(-50% + -20px));
    &::after {
      content: '\ea40' !important;
      font-family: icomoon !important;
      font-size: 16px;
    }
  }
  .swiper-button-next {
    transform: translateY(calc(-50% + 20px));
    &::after {
      content: '\ea3c' !important;
      font-family: icomoon !important;
      font-size: 16px;
    }
  }
  .swiper-pagination-bullet {
    width: 15px;
    height: 5px;
    border-radius: 10px;
    &.swiper-pagination-bullet-active {
      background: rgba(233, 102, 49, 1) !important;
    }
  }
  .swiper-pagination {
    text-align: start;
    bottom: 30px;
    left: 30px;
  }
}
.is-rtl {
  .module-banners-259 .banner-caption span {
    margin-right: auto !important;
    margin-left: unset !important;
    width: 44%;
  }
  .module-banners-259 a .banner-caption {
    margin-left: 10px;
  }
  .banner {
    .swiper-pagination {
      left: unset;
      right: 30px;
    }
  }
}
</style>
