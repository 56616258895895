import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
// import "./add_widget"
// import "./moatframs"

import BootstrapVue3 from 'bootstrap-vue-3'
import "@/style/main.scss"
import "@/style/all.min.css"



import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)
library.add(fab)
library.add(far)
// import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap-utilities.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-grid.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import Icon from "@/components/icomood/Icon.vue"

const app = createApp(App)


app.use(BootstrapVue3)
app.component('Icon', Icon)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.use(i18n)

app.use(store)
app.use(router)
app.mount("#app");
