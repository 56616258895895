<template>
  <div class="cart-bottom">
    <div class="panels-total">
      <div class="cart-panels">
        <h2 class="title">What would you like to do next?</h2>
        <p>
          Choose if you have a discount code or reward points you want to use or
          would like to estimate your delivery cost.
        </p>
        <b-accordion flush>
          <b-accordion-item title="Use Coupon Code">
            <div class="form-group coupon">
              <label class="control-label" for="input-coupon">
                Enter your coupon here
              </label>
              <div class="input-group">
                <input
                  type="text"
                  name="coupon"
                  value=""
                  placeholder="Enter your coupon here"
                  id="input-coupon"
                  class="form-control"
                />

                <button
                  type="button"
                  value="Apply Coupon"
                  id="button-coupon"
                  class="btn btn-primary"
                >
                  Apply Coupon
                </button>
              </div>
            </div>
          </b-accordion-item>
          <b-accordion-item title="Estimate Shipping &amp; Taxes">
            <p>Enter your destination to get a shipping estimate.</p>
            <div class="form-horizontal">
              <div class="form-group required">
                <label class="col-sm-2 control-label" for="input-country">
                  Country
                </label>
                <div class="col-sm-10">
                  <select
                    name="country_id"
                    id="input-country"
                    class="form-control"
                  >
                    <option value="">--- Please Select ---</option>
                    <option value="244">Aaland Islands</option>
                  </select>
                </div>
              </div>
              <div class="form-group required">
                <label class="col-sm-2 control-label" for="input-zone">
                  Region / State
                </label>
                <div class="col-sm-10">
                  <select name="zone_id" id="input-zone" class="form-control">
                    <option value="">--- Please Select ---</option>
                    <option value="2679">Alba</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="input-postcode">
                  Post Code
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="postcode"
                    value=""
                    placeholder="Post Code"
                    id="input-postcode"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="buttons">
                <div class="pull-right">
                  <button
                    type="button"
                    id="button-quote"
                    data-loading-text="Loading..."
                    class="btn btn-primary"
                  >
                    Get Quotes
                  </button>
                </div>
              </div>
            </div>
          </b-accordion-item>
          <b-accordion-item title="Use Gift Certificate">
            <div class="form-group">
              <label class="control-label" for="input-voucher">
                Enter your gift certificate code here
              </label>
              <div class="input-group">
                <input
                  type="text"
                  name="voucher"
                  value=""
                  placeholder="Enter your gift certificate code here"
                  id="input-voucher"
                  class="form-control"
                />

                <button type="submit" class="btn btn-primary">
                  Apply Gift Certificate
                </button>
              </div>
            </div>
          </b-accordion-item>
        </b-accordion>
      </div>
      <div class="cart-total">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class=""><strong>Sub-Total:</strong></td>
              <td class="td-total-text">${{ totalPrice }}</td>
            </tr>
            <tr>
              <td class=""><strong>Total:</strong></td>
              <td class="td-total-text">${{ totalPrice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="buttons">
      <div class="pull-left">
        <router-link to="/" class="btn btn-default">
          <span>Continue Shopping</span>
        </router-link>
      </div>
      <div class="pull-right">
        <router-link to="/checkout" class="btn btn-primary">
          <span>Checkout</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    totalPrice() {
      return this.$store.getters.totalPrice
    },
  },
}
</script>

<style lang="scss">
.cart-bottom {
  .accordion-flush .accordion-item {
    background: none !important;

    .accordion-button {
      background: none !important;
      padding: 15px 5px 15px 0 !important;
      color: rgba(105, 105, 115, 1) !important;
      font-family: 'Montserrat' !important;
      font-weight: 600 !important;
      &:hover {
        color: rgba(13, 82, 214, 1) !important;
      }
      &::after {
        content: '\f067' !important;
        font-family: icomoon !important;
        margin-right: 3px;
        order: 10;
        background-image: unset;
        transform: none;
      }
      &:not(.collapsed)::after {
        content: '\f068' !important;
      }
    }
    .accordion-body {
      padding: 0;
      .control-label {
        max-width: 9999px;
        padding-top: 7px;
        padding-bottom: 5px;
        justify-content: flex-start;
        font-family: 'Roboto';
      }
    }
  }
  select.form-control {
    font-size: 14px;
  }
  .form-group {
    .input-group {
      button {
        margin-left: 5px !important;
        font-size: 14px;
      }
    }
  }
  .cart-total {
    table tr td {
      text-align: end;
      &.td-total-text {
        width: 25%;
      }
    }
    .cart-buttons {
      padding: 13px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border-width: 1px 0 0 0;
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
      background: rgba(230, 230, 230, 1);
      a {
        margin: 0 6px;
        font-size: 13px !important;
        color: #fff !important;
      }
    }
  }
  .buttons {
    & > div {
      // flex: 1;
      // width: auto;
      & + div {
        padding-left: 10px;
        flex: 1;
        width: auto;
      }
    }
    .pull-right {
      a {
        background: rgba(80, 173, 85, 1) !important;
      }
    }
  }
}
</style>
