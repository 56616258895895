<template>
  <div class="grid-row grid-row-bottom-3">
    <div class="container">
      <div class="grid-cols">
        <div class="grid-col grid-col-bottom-3-1">
          <div class="grid-items">
            <div class="grid-item grid-item-bottom-3-1-1">
              <div class="module title-module module-title-171">
                <div class="title-wrapper">
                  <h3>Improved Gallery Module</h3>
                  <div class="title-divider"></div>
                  <div class="subtitle">
                    The Gallery module supports images, videos and can also act
                    as banners that can point to any other page or open other
                    Popup modules. Create different modules with images, videos,
                    banners or a combination of all. Add your modules on any
                    page in any grid format.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col-bottom-3-2">
          <div class="grid-items">
            <div class="grid-item grid-item-bottom-3-2-1">
              <div class="module-body">
                <div class="carousel-wrapper">
                  <swiper
                    :slides-per-view="2"
                    :breakpoints="breakpoints"
                    :modules="modules"
                    navigation
                    :pagination="{ clickable: true }"
                  >
                    <swiper-slide
                      v-for="(product, index) in products[0].images"
                      :key="product.id"
                    >
                      <ImprovedGallaryCard
                        v-if="product.path"
                        :items="product.path"
                        @click="slideHandler(index)"
                      />
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LightBox
      v-if="lightbox"
      :slide="slide"
      :products="products[0]"
      @closeLightbox="closeLightbox($event)"
    />
  </div>
</template>

<script>
import { ref, watch, toRefs } from 'vue'
import 'swiper/css'
import 'swiper/css/bundle'
import LightBox from '@/components/LightBox.vue'
import Products from '@/products.json'
import ImprovedGallaryCard from '../layout/ImprovedGallaryCard.vue'
import { A11y, Thumbs, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'

export default {
  components: {
    ImprovedGallaryCard,
    Swiper,
    SwiperSlide,

    LightBox,
  },
  data() {
    return {
      lightbox: false,
      slide: 0,
      products: Products,

      breakpoints: {
        1025: {
          slidesPerView: 9,
        },

        768: {
          slidesPerView: 6,
        },
      },
    }
  },
  methods: {
    closeLightbox(e) {
      this.lightbox = e
    },
    slideHandler(i) {
      this.slide = i
      this.lightbox = true
      console.log(i)
    },
  },
  setup(props) {
    const swiperRef = ref(null)

    const { currentSlide } = toRefs(props)

    watch(currentSlide, () => {
      if (swiperRef.value !== null) {
        swiperRef.value.slideTo(props.currentSlide)
      }
    })

    const onSwiper = (swiper) => {
      swiperRef.value = swiper
    }

    return {
      swiperRef,
      onSwiper,
      A11y,
      Lazy,
      Thumbs,
    }
  },
}
</script>
<style></style>
