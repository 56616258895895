<template>
  <div class="block-wrapper">
    <div class="block-body">
      <div class="block-header">
        <i class="icon icon-block"></i>
      </div>
      <div class="block-content block-text">
        I've purchased hundreds and hundreds of files over the past 7 years. But
        there is nothing close to this support and professionalism. Not only is
        theme, simple , useful and modern, but again the support is remarkable.
        Very happy I got this theme! Thank you!
      </div>
      <div class="block-footer">
        - charlibaltimore
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.block-wrapper {
  padding: 10px;
  height: 100%;
  .block-body {
    text-align: center;
    background: rgba(255, 255, 255, 1);
    padding: 20px;
    border-radius: 7px;
    font-size: 14px;
    height: 100%;
    transition: all 0.1s ease-in-out;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    cursor: pointer;
    &:hover {
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1) !important;
    }
    .block-header {
      text-align: center;
      i {
        margin-bottom: 10px;
        display: block;
        &::before {
          content: '\e977' !important;
          font-family: icomoon !important;
          font-size: 60px;
          color: rgba(233, 102, 49, 1);
        }
      }
    }
    .block-content {
      max-height: 100% !important;
      overflow: visible !important;
      max-height: 75px;
      overflow: hidden;
      margin-bottom: 10px;
      font-size: 14px;
      font-family: 'Roboto';
      color: #333 !important;
      font-weight: 500;
    }
    .block-footer {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 15px;
      color: rgba(105, 105, 115, 1);
      font-style: normal;
      margin-top: auto;
    }
  }
}
</style>
