<template>
  <div class="grid-row grid-row-bottom-1">
    <div class="container p-0">
      <div class="grid-cols">
        <div class="grid-col grid-col-bottom-1-1">
          <div class="grid-items">
            <div class="grid-item grid-item-bottom-1-1-1">
              <div class="module title-module module-title-163">
                <div class="title-wrapper">
                  <h3>Featured Category</h3>
                  <div class="title-divider"></div>
                  <div class="subtitle">
                    Easily create category based modules and display products
                    from specific categories or brands only. The advanced page
                    builder allows you to create any grid layout with full
                    control at any breakpoint.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col-bottom-1-2">
          <div class="grid-items">
            <div class="grid-item grid-item-bottom-1-2-1">
              <div class="module">
                <div class="module-body">
                  <div class="module-item">
                    <div class="item-content">
                      <router-link to="/categories/1" class="catalog-title">
                        Fashion
                      </router-link>
                      <div class="item-assets image-left">
                        <router-link to="/categories/1" class="catalog-image">
                          <img
                            src="https://www.journal-theme.com/1/image/cache/catalog/journal3/categories/demo09-260x260.jpg.webp"
                            alt="Fashion"
                            class="img-fluid top w-100"
                          />
                        </router-link>
                        <div class="subitems">
                          <div
                            class="subitem"
                            v-for="n in 4"
                            :key="n"
                            data-image="https://www.journal-theme.com/1/image/cache/catalog/journal3/products/sunglasses/glasses3-260x260.jpg.webp"
                          >
                            <router-link to="/categories/1">
                              <span>Accesories</span>
                            </router-link>
                          </div>

                          <div class="subitem view-more">
                            <router-link to="/categories/1">
                              <span>See all in Fashion</span>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col-bottom-1-3">
          <div class="grid-items">
            <div class="grid-item grid-item-bottom-1-3-1">
              <div class="module">
                <div class="module-body">
                  <div class="module-item category">
                    <h3 class="title">New in Fashion</h3>
                    <div class="carousel-wrapper">
                      <swiper
                        :slides-per-view="2"
                        :breakpoints="breakpoints"
                        :space-between="5"
                        :modules="modules"
                        navigation
                        :pagination="{ clickable: true, dynamicBullets: true }"
                      >
                        <swiper-slide
                          v-for="product in products"
                          :key="product.id"
                        >
                          <ProductCard :items="product" />
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/layout/ProductCard.vue'
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
import Products from '@/products.json'

export default {
  components: {
    ProductCard,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation, Pagination, A11y],
      products: Products,
      breakpoints: {
        640: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
    }
  },
}
</script>

<style lang="scss">
// .swiper-wrapper {
//   padding-bottom: 30px;
// }
.category {
  .product-thumb {
    border-radius: 3px !important;
    padding: 5px !important;
    .caption {
      .stats {
        display: none !important;
      }
      .name {
        a {
          color: rgba(13, 82, 214, 1) !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          text-align: start;
          font-weight: normal;
          &:hover {
            color: rgba(233, 102, 49, 1) !important;
          }
        }
      }
      .buttons-wrapper {
        .cart-group {
          .stepper {
            display: none !important;
          }
          button {
            padding: 2px 6px !important;
            height: fit-content !important;
            .btn-text {
              font-size: 12px !important;
            }
          }
        }
      }
    }
    .extra-group {
      display: none !important;
    }
  }
}

.catalog-title {
  padding-bottom: 8px;
  border-width: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: rgba(233, 102, 49, 1);
  margin-bottom: 15px;
  display: flex;
  font-family: 'Montserrat';
  font-weight: 600;
  color: #333 !important;
  width: auto;
  margin-left: 0;
  width: fit-content !important;
  margin-right: auto;
  justify-content: flex-start;
  white-space: normal;
  overflow: visible;
  text-overflow: initial;
  &:hover {
    color: #333 !important;
  }
}

.subitems {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  a {
    font-size: 17px;
    font-family: 'Roboto';
    color: rgba(58, 71, 84, 1);
  }
}
.subitem {
  display: flex;
  line-height: 1.2;
}

.subitem > a span {
  font-size: 14px;
  color: rgba(58, 71, 84, 1);
}

.subitem > a:hover span {
  color: rgba(233, 102, 49, 1);
  text-decoration: underline;
}

.subitem.view-more a span {
  color: rgba(15, 58, 141, 1);
  font-weight: 700;
  text-decoration: underline;
}

.subitem.view-more a:hover span {
  color: rgba(233, 102, 49, 1);
}

.subitem > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 6px;
}

.subitem > a::before {
  content: none !important;
}

.catalog-image img {
  border-radius: 3px;
}

.catalog-image {
  margin-bottom: 8px;
}

.item-assets {
  flex-direction: column;
}

.item-assets > a {
  max-width: 100%;
  order: 0;
}

.item-assets .subitems {
  width: 100%;
}

.item-assets .catalog-image {
  display: block;
}
@media (max-width: 760px) {
  .swiper-container {
    overflow: visible;
  }

  .subitem > a span {
    font-size: 17px;
  }

  .subitem > a {
    padding-bottom: 16px;
  }
}
</style>
