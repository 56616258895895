<template>
  <footer>
    <div class="grid-rows">
      <div class="recent-products nobg">
        <div class="container">
          <b-tabs content-class="mt-3 " align="center">
            <b-tab title="RECENTLY VIEWED" class="nav nav-tabs" active>
              <div class="carousel-wrapper">
                <swiper
                  :slides-per-view="2"
                  :breakpoints="breakpoints"
                  :space-between="5"
                  :modules="modules"
                  :pagination="{ clickable: true }"
                >
                  <swiper-slide v-for="n in 10" :key="n">
                    <SideProduct />
                  </swiper-slide>
                </swiper>
              </div>
            </b-tab>
            <b-tab title="Most VIEWED" class="nav nav-tabs">
              <div class="carousel-wrapper">
                <swiper
                  :slides-per-view="2"
                  :breakpoints="breakpoints"
                  :space-between="5"
                  :modules="modules"
                  :pagination="{ clickable: true }"
                >
                  <swiper-slide v-for="n in 10" :key="n">
                    <SideProduct />
                  </swiper-slide>
                </swiper>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div class="social-section">
        <div class="container">
          <div class="icons-menu icons-menu-61">
            <ul class="d-flex justify-content-center">
              <li
                class="menu-item icons-menu-item icons-menu-item-1 icon-menu-icon"
              >
                <a class="tooltip-parent btn-primary">
                  <span class="tooltips">Facebook</span>
                </a>
              </li>
              <li
                class="menu-item icons-menu-item icons-menu-item-2 icon-menu-icon"
              >
                <a class="tooltip-parent btn-primary">
                  <span class="tooltips">Twitter</span>
                </a>
              </li>
              <li
                class="menu-item icons-menu-item icons-menu-item-3 icon-menu-icon"
              >
                <a class="tooltip-parent btn-primary">
                  <span class="tooltips">Instagram</span>
                </a>
              </li>
              <li
                class="menu-item icons-menu-item icons-menu-item-4 icon-menu-icon"
              >
                <a class="tooltip-parent btn-primary">
                  <span class="tooltips">LinkedIn</span>
                </a>
              </li>
              <li
                class="menu-item icons-menu-item icons-menu-item-5 icon-menu-icon"
              >
                <a class="tooltip-parent btn-primary">
                  <span class="tooltips">YouTube</span>
                </a>
              </li>
              <li
                class="menu-item icons-menu-item icons-menu-item-6 icon-menu-icon"
              >
                <a class="tooltip-parent btn-primary">
                  <span class="tooltips">Skype</span>
                </a>
              </li>
              <li
                class="menu-item icons-menu-item icons-menu-item-7 icon-menu-icon"
              >
                <a class="tooltip-parent btn-primary">
                  <span class="tooltips">Google</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="grid-row grid-row-3">
        <div class="container">
          <div class="grid-cols d-flex flex-wrap">
            <div class="grid-col grid-col-1">
              <div class="grid-items">
                <div class="grid-item grid-item-1">
                  <div class="links-menu links-menu-72">
                    <h3 class="title module-title closed">About Us</h3>
                    <ul class="module-body">
                      <li class="menu-item links-menu-item links-menu-item-1">
                        <router-link to="/about_us">
                          <span class="links-text">About Us</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-2">
                        <router-link to="/delivery">
                          <span class="links-text">Delivery</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-3">
                        <router-link to="/privacy">
                          <span class="links-text">Privacy Policy</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-4">
                        <router-link to="/terms">
                          <span class="links-text">Terms &amp; Conditions</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-5">
                        <a>
                          <span class="links-text">Custom Links</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-col grid-col-2">
              <div class="grid-items">
                <div class="grid-item grid-item-1">
                  <div class="links-menu links-menu-75">
                    <h3 class="title module-title closed">Customer Service</h3>
                    <ul class="module-body">
                      <li class="menu-item links-menu-item links-menu-item-1">
                        <router-link to="/contact">
                          <span class="links-text">Contact</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-2">
                        <router-link to="account/returnProduct">
                          <span class="links-text">Returns</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-3">
                        <router-link to="/account/myAccount">
                          <span class="links-text">Site Map</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-4">
                        <router-link to="/products">
                          <span class="links-text">Brands</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-5">
                        <router-link to="/products">
                          <span class="links-text">Unlimited Links</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-col grid-col-3">
              <div class="grid-items">
                <div class="grid-item grid-item-1">
                  <div class="links-menu links-menu-76">
                    <h3 class="title module-title closed">My Account</h3>
                    <ul class="module-body">
                      <li class="menu-item links-menu-item links-menu-item-1">
                        <router-link to="/account/myAccount">
                          <span class="links-text">My Account</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-2">
                        <router-link to="/account/order">
                          <span class="links-text">Order History</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-3">
                        <router-link to="/account/affiliateInformation">
                          <span class="links-text">Affiliates</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-4">
                        <router-link to="/account/newsLetters">
                          <span class="links-text">Newsletter</span>
                        </router-link>
                      </li>

                      <li class="menu-item links-menu-item links-menu-item-5">
                        <router-link to="/rewards">
                          <span class="links-text">Gift Certificates</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-col grid-col-4">
              <div class="grid-items">
                <div class="grid-item grid-item-1">
                  <div class="module module-newsletter module-newsletter-67">
                    <h3 class="title module-title">Newsletter</h3>
                    <div class="module-body">
                      <div class="newsletter-text">
                        Stay up to date with news and promotions by signing up
                        for our newsletter
                      </div>
                      <div class="newsletter-form">
                        <form
                          action="https://www.journal-theme.com/1/index.php?route=journal3/newsletter/newsletter&amp;module_id=67"
                          method="post"
                          enctype="multipart/form-data"
                          class="form-horizontal"
                        >
                          <div class="input-group">
                            <input
                              type="text"
                              name="email"
                              placeholder="Your email"
                              class="form-control newsletter-email"
                            />
                            <span class="input-group-btn">
                              <button
                                type="submit"
                                class="btn btn-primary"
                                style="
                                  position: absolute;
                                  right: 2px;
                                  margin: 0;
                                "
                              >
                                <span>Send</span>
                              </button>
                            </span>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" name="agree" value="1" />
                              I have read and agree to the
                              <router-link to="/privacy" class="agree">
                                <b>Privacy Policy</b>
                              </router-link>
                            </label>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-row grid-row-4">
        <div class="container">
          <div class="grid-cols">
            <div class="grid-col grid-col-1">
              <div class="grid-items">
                <div class="grid-item grid-item-1">
                  <div class="links-menu links-menu-77">
                    <ul class="module-body">
                      <li class="menu-item links-menu-item links-menu-item-1">
                        <a>
                          <span class="links-text">
                            Copyright © 2014, Your Store, All Rights Reserved
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-col grid-col-2 copy-links">
              <div class="grid-items">
                <div class="grid-item grid-item-1">
                  <div
                    class="icons-menu icons-menu-228 d-flex justify-content-center justify-content-lg-end"
                  >
                    <ul class="d-flex">
                      <li
                        class="menu-item icons-menu-item icons-menu-item-1 icon-menu-icon"
                      >
                        <a
                          data-toggle="tooltip"
                          data-tooltip-class="icons-menu-tooltip-228"
                          data-placement="top"
                          title=""
                          href="#"
                          data-original-title="Visa"
                        >
                          <span class="links-text">Visa</span>
                        </a>
                      </li>
                      <li
                        class="menu-item icons-menu-item icons-menu-item-2 icon-menu-icon"
                      >
                        <a
                          data-toggle="tooltip"
                          data-tooltip-class="icons-menu-tooltip-228"
                          data-placement="top"
                          title=""
                          href="#"
                          data-original-title="Mastercard"
                        >
                          <span class="links-text">Mastercard</span>
                        </a>
                      </li>
                      <li
                        class="menu-item icons-menu-item icons-menu-item-3 icon-menu-icon"
                      >
                        <a
                          data-toggle="tooltip"
                          data-tooltip-class="icons-menu-tooltip-228"
                          data-placement="top"
                          title=""
                          href="#"
                          data-original-title="Amex"
                        >
                          <span class="links-text">Amex</span>
                        </a>
                      </li>
                      <li
                        class="menu-item icons-menu-item icons-menu-item-4 icon-menu-icon"
                      >
                        <a
                          data-toggle="tooltip"
                          data-tooltip-class="icons-menu-tooltip-228"
                          data-placement="top"
                          title=""
                          href="#"
                          data-original-title="Discover"
                        >
                          <span class="links-text">Discover</span>
                        </a>
                      </li>
                      <li
                        class="menu-item icons-menu-item icons-menu-item-5 icon-menu-icon"
                      >
                        <a
                          data-toggle="tooltip"
                          data-tooltip-class="icons-menu-tooltip-228"
                          data-placement="top"
                          title=""
                          href="#"
                          data-original-title="Paypal"
                        >
                          <span class="links-text">Paypal</span>
                        </a>
                      </li>
                      <li
                        class="menu-item icons-menu-item icons-menu-item-6 icon-menu-icon"
                      >
                        <a
                          data-toggle="tooltip"
                          data-tooltip-class="icons-menu-tooltip-228"
                          data-placement="top"
                          title=""
                          href="#"
                          data-original-title="Stripe"
                        >
                          <span class="links-text">Stripe</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
import SideProduct from '../layout/SideProduct.vue'
export default {
  components: { Swiper, SwiperSlide, SideProduct },
  data() {
    return {
      modules: [Pagination, A11y],

      breakpoints: {
        1025: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
      },
    }
  },
}
</script>

<style lang="scss">
footer {
  .recent-products {
    background: rgba(58, 71, 84, 1);
    padding: 30px 0;
    &.nobg ul.nav-tabs .nav-item .nav-link.active {
      color: #e6e6e6 !important;
    }
  }
  .social-section {
    padding: 30px 0;

    background: #eee;
  }
  .grid-items {
    .links-menu {
      text-align: left;
    }
  }
  .copy-links {
    .icons-menu {
      & > ul {
        justify-content: flex-end !important;
      }
      .icons-menu-items {
        a {
          background: none !important;
          .links-text {
            color: rgba(139, 145, 152, 1);
            font-size: 30px;
          }
        }
      }
    }
  }

  .tooltip-parent {
    width: 30px !important;
    height: 30px !important;
    &::before {
      font-size: 16px;
    }
    @media (min-width: 570px) {
      width: 40px !important;
      height: 40px !important;
      &::before {
        font-size: 22px;
      }
    }
  }
}

.is-rtl {
  footer {
    .grid-items {
      .links-menu {
        text-align: right;
      }
    }
  }
}
</style>
