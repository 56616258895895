<template>
  <div class="product-info productOverview out-of-stock has-extra-button">
    <div class="product-left">
      <div class="main-image">
        <Swiper
          @swiper="onSwiper"
          :slides-per-view="1"
          :space-between="50"
          :modules="[A11y, Lazy, Thumbs]"
          grab-cursor
          :preload-images="false"
          lazy
          :thumbs="{ swiper: thumbs }"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <SwiperSlide
            v-for="product in items.images"
            :key="product.id"
            class="customSlide"
          >
            <img
              v-if="product.path"
              :data-src="product.path"
              class="swiper-lazy"
              style="max-width: 100%;"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div class="swiper-image">
        <Swiper
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
          @swiper="onSwiperT"
          :breakpoints="breakpoints"
          slides-per-view="3"
          :space-between="10"
          :modules="[Thumbs]"
          watch-slides-progress
          class="dasdasd"
        >
          <SwiperSlide
            v-for="(product, index) in items.images"
            :key="product.id"
            @click.stop="currentSlide = Number(index)"
          >
            <img :src="product.path" style="width: 100%;" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div class="product-right">
      <div id="product" class="product-details">
        <div class="title page-title">{{ items.name }}</div>

        <div class="description">
          <div class="block-content" :class="expanded ? ' expand-content' : ''">
            <p>
              {{ items.description }}
            </p>

            <div class="block-expand-overlay text-center">
              <a
                class="block-expand btn-primary btn"
                role="button"
                @click="expanded = !expanded"
              ></a>
            </div>
          </div>
        </div>

        <div class="product-stats">
          <ul class="list-unstyled">
            <li class="product-stock out-of-stock">
              <b>Stock:</b>
              <span>2-3 Days</span>
            </li>
            <li class="product-stock in-stock">
              <b>Stock:</b>
              <span>In Stock</span>
            </li>
            <li class="product-manufacturer">
              <b>Brand:</b>
              <a href="/categories/1" target="_blank">
                {{ items.brand }}
              </a>
            </li>
          </ul>
        </div>

        <div class="product-price-group">
          <div class="price-wrapper">
            <div class="price-group">
              <div class="price-old" v-if="items.price_old">
                ${{ items.price_old }}
              </div>
              <div class="product-price" v-if="items.price_normal">
                ${{ items.price_normal }}
              </div>
            </div>
            <div class="product-tax" v-if="items.tax">
              Ex Tax: ${{ items.tax }}
            </div>
          </div>
        </div>

        <div class="product-options">
          <h3 class="options-title title">Available Options</h3>
          <div class="form-group required product-option-select">
            <label class="control-label" for="input-option764">
              Select list
            </label>
            <select class="form-control">
              <option value="">--- Please Select ---</option>
              <option
                v-for="multiChoice in items.multiChoice"
                :key="multiChoice"
                :value="multiChoice"
              >
                {{ multiChoice }}
              </option>
            </select>
          </div>
          <div class="form-group required product-option-checkbox push-option">
            <label class="control-label">Multiple choice</label>
            <div id="input-option763">
              <div
                class="checkbox"
                v-for="extraOptions in items.extraOptions.sizes"
                :key="extraOptions"
              >
                <label>
                  <input
                    type="checkbox"
                    :value="extraOptions"
                    v-model="item.size"
                  />
                  <span class="option-value">
                    {{ extraOptions }}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group required product-option-radio push-option">
            <label class="control-label">Single choice (with image)</label>
            <div id="input-option650">
              <div
                class="radio"
                v-for="extraOptions in items.extraOptions.singleChoice"
                :key="extraOptions.id"
              >
                <label>
                  <input
                    type="radio"
                    v-model="item.color"
                    :value="extraOptions.id"
                  />
                  <img
                    width="40"
                    height="40"
                    :src="extraOptions.path"
                    :alt="extraOptions.title"
                  />
                  <span class="option-value">
                    {{ extraOptions.title }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="button-group-page">
          <div class="buttons-wrapper">
            <div class="stepper-group cart-group">
              <div class="stepper">
                <input
                  type="text"
                  name="quantity"
                  v-model="item.qty"
                  class="form-control"
                />
                <span>
                  <i
                    class="fa-solid fa-angle-up"
                    @click="changeQuantity(1)"
                  ></i>
                  <i
                    class="fa-solid fa-angle-down"
                    @click="changeQuantity(-1)"
                  ></i>
                </span>
              </div>
              <a
                id="button-cart"
                data-loading-text="<span class='btn-text'>Add to Cart</span>"
                class="btn btn-primary btn-cart"
                @click="addToCart"
              >
                <span class="btn-text">Add to Cart</span>
              </a>
            </div>

            <div class="wishlist-compare">
              <a
                class="btn btn-wishlist"
                data-original-title="Add to Wish List"
              >
                <span class="btn-text">Add to Wish List</span>
              </a>
              <a
                class="btn btn-compare btn-primary"
                data-original-title="Compare this Product"
              >
                <span class="btn-text">Compare this Product</span>
              </a>
              <a class="btn btn-more-details" href="/products/1" target="_top">
                <span class="btn-text">More Details</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Products from '@/products.json'
import { ref, watch, toRefs } from 'vue'
import { Navigation, Pagination, A11y, Lazy, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/bundle'
export default {
  props: ['items', 'quantity'],
  components: { Swiper, SwiperSlide },
  data() {
    return {
      expanded: false,
      products: Products,
      thumbs: null,
      item: {
        color: '',
        size: [],
        qty: 1,
      },
      breakpoints: {
        499: {
          slidesPerView: 4,
          spaceBetweenSlides: 30,
        },
      },
    }
  },
  methods: {
    onSwiperT(swiper) {
      this.thumbs = swiper
    },
    changeQuantity(n) {
      if (this.item.qty <= 1 && n == -1) return
      this.item.qty += n
    },
    addToCart() {
      this.$emit('addToCart', {
        color: this.item.color,
        size: this.item.size,
        qty: this.item.qty,
        product: this.items,
      })
    },
  },
  mounted() {
    this.item.qty = this.quantity
  },
  setup() {
    const onSwiper = (swiper) => {
      swiperRef.value = swiper
    }
    const swiperRef = ref(null)
    const { currentSlide } = toRefs(0)
    watch(currentSlide, () => {
      if (swiperRef.value !== null) {
        swiperRef.value.slideTo(currentSlide)
      }
    })
    return {
      swiperRef,
      onSwiper,
      Navigation,
      Pagination,
      A11y,
      Lazy,
      Thumbs,
    }
  },
}
</script>

<style lang="scss">
.product-info {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  height: calc(100% - 65px);
  overflow-y: auto;

  .product-left {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    width: 100%;
    justify-content: flex-start;
    @media (min-width: 557px) {
      width: 50%;
    }
    .main-image {
      width: 100%;
      margin-bottom: 10px;
      @media (min-width: 768px) {
        min-height: 300px;
        .swiper {
          height: 100%;
          img {
            width: 100%;
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
    .swiper-image {
      img {
        border-width: 1px;
        border-style: solid;
        border-color: rgba(226, 226, 226, 1);
        border-radius: 3px;
        padding: 1px;
        cursor: pointer;
        &:hover {
          border-color: rgba(15, 58, 141, 1);
        }
      }
    }
  }
  .product-right {
    width: 100% !important;
    @media (min-width: 557px) {
      width: 50% !important;
      padding-left: 40px;
    }
    .product-details {
      display: flex;
      flex-direction: column;
      font-family: 'Roboto';
      .product-options > .options-title {
        display: none;
      }
      .product-tax {
        display: block;
        color: rgba(105, 105, 115, 1);
        margin-top: 5px;
      }
      > div {
        margin-bottom: 15px;
      }
      .buttons-wrapper {
        @media (max-width: 1025px) {
          position: relative !important;

          padding: 0 !important;
          background: none !important;
          .stepper-group {
            flex-grow: 1 !important;
            .btn-cart {
              flex-grow: 1 !important;
            }
            .stepper {
              height: 43px !important;
              margin-right: 0 !important;
            }
            a {
              height: 43px !important;
            }
          }
          .wishlist-compare a.btn-wishlist::before {
            color: #fff !important;
          }
        }
        @media (max-width: 470px) {
          justify-content: flex-start !important;
          .stepper-group {
            flex-grow: initial !important;
            margin-bottom: 1px !important;
            .btn-cart {
              .btn-text {
                display: none !important;
              }
            }
          }
          .wishlist-compare {
            margin-bottom: 1px !important;
          }
        }
      }
      .product-options {
        background: rgba(237, 242, 254, 1);
        padding: 15px;
        padding-top: 5px;
        .product-option-checkbox {
          > div {
            display: flex;
            flex-wrap: wrap;
            .checkbox {
              width: initial !important;
            }
            label {
              border: 0;
            }
          }
        }
        .product-option-radio {
          > div {
            display: flex;
            .option-value {
              display: block !important;
              min-height: initial;
              min-width: initial;
              margin: 0 !important;
              text-align: center;
              border: 0 !important;
            }
          }
        }
      }
    }
    .description {
      margin-bottom: 15px;
    }
    .product-stats {
      padding-bottom: 20px !important;
      padding-left: 0px !important;
      border-width: 0 !important;
      border-bottom-width: 1px !important;
      border-left-width: 0px !important;
      border-style: solid !important;
      border-color: rgba(226, 226, 226, 1);
      ul {
        display: flex;
        flex-direction: column;
        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: 'Roboto';
          b {
            margin-right: 3px;
            font-size: 12px;
          }
          a {
            font-size: 12px !important;
            text-decoration: underline !important;

            color: rgba(13, 82, 214, 1) !important;
            &:hover {
              color: rgba(233, 102, 49, 1) !important;
            }
          }
          &::before {
            margin-right: 5px;
          }
        }
      }
    }
    .product-price-group {
      padding-top: 15px;
      margin-top: -5px;
    }
  }
  .button-group-page {
    position: absolute;
    width: 100%;
    z-index: 1000;
    background: rgba(240, 242, 245, 1);
    padding: 10px;
    margin: 0px;
    border-width: 0;
    border-top-width: 1px;
    border-style: solid;
    border-color: rgba(226, 226, 226, 1);
    margin-bottom: 0 !important;
    left: 0;
    bottom: 0;

    .stepper-group,
    .wishlist-compare {
      width: initial !important;
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important;

      .btn-text {
        display: none !important;
      }
    }
    .btn-cart {
      align-items: center !important;
      justify-content: center !important;
      margin: 0 7px !important;
      height: 43px !important;
      padding: 13px 18px !important;
      .btn-text {
        display: inline-block !important;
      }
    }
    .stepper-group {
      height: 43px;
      .stepper {
        height: 43px !important;
        margin-right: 0 !important;
        min-width: 60px;
      }
    }
    .wishlist-compare {
      height: 43px;
      a {
        height: 43px !important;
        padding: 13px 18px !important;
        &.btn-wishlist {
          background: rgba(221, 14, 28, 1);
          display: inline-flex;
          margin: 0px;
          margin-right: 7px !important;

          &:hover {
            background: #0d52d6 !important;
            border-color: #0d52d6;
          }
          &::before {
            color: #fff !important;
          }
        }
        &.btn-compare.btn {
          margin-right: 7px;
          color: #fff !important;
          &:hover {
            background: #0d52d6 !important;
            border-color: #0d52d6;
          }
        }
        &.btn-more-details.btn {
          background: rgba(80, 173, 85, 1);
          color: #fff !important;
          &:hover {
            background: #0d52d6 !important;
            border-color: #0d52d6;
          }
        }
        &::before {
          font-size: 17px;
          color: #fff !important;
          margin: 0 !important;
        }
      }
    }

    .btn-more-details::after {
      content: '\e5c8' !important;
      font-family: icomoon !important;
      font-size: 18px;
    }
  }
}
.is-rtl {
  .title::after {
    margin-left: auto;
  }
  .productOverview {
    .product-details .buttons-wrapper .wishlist-compare a:first-child {
      margin: 0 !important;
    }
    .product-details .buttons-wrapper .stepper-group .stepper {
      margin: 0 !important;
    }
  }
  .product-info .product-right .product-details .product-tax {
    text-align: start;
  }
  .product-info .product-right .product-stats ul li::before {
    margin-right: 0 !important;
    margin-left: 5px !important;
  }
  .product-info .product-right .product-stats ul li b {
    margin-right: 0;
    margin-left: 3px;
  }
  .product-info .product-right .product-details > div {
    margin-bottom: 0;
  }
  @media (min-width: 557px) {
    .product-info .product-right {
      padding-right: 40px;
      padding-left: 0;
    }
  }
  .banner .carousel__prev,
  .banner .carousel__next {
    left: unset;
    right: auto;
  }
}
</style>
