<template>
  <div class="account">
    <div class="breadcrumb">
      <Breadcrumb />
    </div>

    <div class="">
      <div class="page-title-header">
        <h1 class="title page-title">
          <span>{{ $t(`misc.${tabOpen}`) }}</span>
        </h1>
      </div>

      <div class="container p-0">
        <!-- Alert type (alert-danger - alert-success) -->
        <!-- <div class="alert alert-danger alert-dismissible">
          <i class="fa fa-exclamation-circle"></i>
          Warning: No match for E-Mail Address and/or Password.
          <button type="button" class="close" data-bs-dismiss="alert">×</button>
        </div> -->
        <div class="alert alert-dismissible" :class="alertType" v-if="alert">
          <i class="fa fa-check-circle"></i>
          {{ alertMessage }}
          <button type="button" class="close" @click="alert = !alert">×</button>
        </div>
      </div>
      <div class="container p-0">
        <div id="account-account" class="container">
          <div class="row" dir="ltr">
            <div
              id="content"
              class="account-page"
              :class="routeName != 'MyWishList' ? ' col-md-9' : 'p-0'"
            >
              <Account v-if="myAccount" />
              <MyWishList v-if="MyWishList" @alertMessage="getAlert" />
              <ResetPassword v-if="resetPassword" />
              <Login v-if="login" />
              <Register v-if="register" />
              <Addresses v-if="addresses" />
              <Order v-if="order" />
              <Downloads v-if="downloads" />
              <Return v-if="returnProduct" />
              <NewsLetters v-if="newsLetters" />
              <Transactions v-if="transactions" />
              <Payments v-if="payments" />
              <RewardsPoint v-if="rewards" />
            </div>
            <Aside v-if="routeName != 'MyWishList'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Aside from '../components/account/Aside.vue'
import Account from '../components/account/Account.vue'
import Breadcrumb from '../components/Breadcrumb.vue'
import MyWishList from '../components/account/MyWishList.vue'
import ResetPassword from '@/components/auth/ResetPassword.vue'
import Login from '@/components/account/Login.vue'
import Register from '@/components/account/Register.vue'
import Addresses from '@/components/account/Addresses.vue'
import Order from '@/components/account/Order.vue'
import Downloads from '@/components/account/Downloads.vue'
import Return from '@/components/account/Return.vue'
import NewsLetters from '@/components/account/NewsLetters.vue'
import Transactions from '../components/account/Transactions.vue'
import Payments from '../components/account/Payments.vue'
import RewardsPoint from '../components/account/RewardsPoint.vue'
export default {
  components: {
    Aside,
    Account,
    Breadcrumb,
    MyWishList,
    ResetPassword,
    Login,
    Register,
    Addresses,
    Order,
    Downloads,
    Return,
    NewsLetters,
    Transactions,
    Payments,
    RewardsPoint,
  },
  data() {
    return {
      myAccount: true,
      MyWishList: false,
      register: false,
      resetPassword: false,
      addresses: false,
      login: false,
      order: false,
      newsLetters: false,
      returnProduct: false,
      downloads: false,
      payments: false,
      transactions: false,
      rewards: false,
      routeName: null,
      alert: '',
      alertType: '',
      alertMessage: '',
    }
  },
  mounted() {
    this.openTab(this.tabOpen)
    this.routeName = this.$route.params.slug
  },
  computed: {
    tabOpen() {
      return this.$route.params.slug
    },
  },
  watch: {
    $route() {
      this.openTab(this.$route.params.slug)
      this.routeName = this.$route.params.slug
    },
  },
  methods: {
    openTab(tab) {
      this.myAccount = false
      this.MyWishList = false
      this.register = false
      this.resetPassword = false
      this.login = false
      this.addresses = false
      this.downloads = false
      this.order = false
      this.returnProduct = false
      this.newsLetters = false
      this.payments = false
      this.rewards = false
      this.transactions = false
      this[tab] = true
    },
    getAlert(e) {
      this.alert = false
      this.alertType = ''
      this.alertMessage = ''
      this.alertType = e.type
      this.alertMessage = e.message
      this.alert = true
    },
  },
}
</script>

<style lang="scss">
.breadcrumb {
  background-color: #fff;
}
.account {
  @media (max-width: 1280px) {
    padding: 0 10px;
  }
  background: #f8f8f8;
  .page-title::after {
    content: unset !important;
  }

  aside {
    ul {
      border-width: 0px;
      border-style: solid;
      border-color: rgba(226, 226, 226, 1);
      padding: 15px !important;
      background: rgba(255, 255, 255, 1);
      width: fit-content;

      margin-left: auto;
      .menu-item {
        text-align: start;
        min-width: 200px;

        a {
          font-size: 15px;
          color: rgba(51, 51, 51, 1);
          font-weight: 400;
          padding: 8px;
          padding-right: 0px;
          padding-left: 0px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &::before {
            content: '\f0da';
            margin: 0 5px 0 0;
            font-family: 'Font Awesome 6 Free';
            font-weight: 900;
          }
        }
        &.accordion {
          .accordion-button {
            font-size: 15px;
            color: rgb(51, 51, 51);
            font-weight: 400;
            padding: 8px;
            padding-right: 0px;
            padding-left: 0px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:not(.collapsed) {
              background: none;
              &::after {
                content: '\eb86' !important;
                font-family: icomoon !important;
                font-size: 16px;
                color: rgba(80, 173, 85, 1);
                left: 5px;
              }
            }
            &::before {
              content: '\f0da';
              margin: 0 5px 0 0;
              font-family: 'Font Awesome 6 Free';
              font-weight: 900;
              color: rgb(51, 51, 51);
              opacity: 0.8;
            }
            &::after {
              content: '\eba1' !important;
              font-family: icomoon !important;
              font-size: 16px;
              left: 5px;
              background-image: none;
              transform: none;
            }
            &:hover {
              color: rgba(13, 82, 214, 1);
            }
          }
          .accordion-body {
            padding: 0;
            ul {
              padding: 0 !important;
              li {
                a {
                  padding: 5px;
                  padding-left: 18px !important;
                  &::before {
                    content: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.side-column {
  .count-badge {
    margin-left: 5px;
    display: none;
  }
}

.is-rtl {
  .account {
    aside {
      ul {
        .menu-item {
          a {
            &::before {
              content: '\f0d9';
              margin: 0 0 0 5px;
            }
          }
          &.accordion {
            .accordion-button {
              &::before {
                content: '\f0d9';
              }
            }
            .accordion-body {
              ul {
                li {
                  a {
                    padding-left: 0 !important;
                    padding-right: 15px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
