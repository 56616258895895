<template>
  <div class="account-login">
    <div class="row">
      <div class="col-md-6">
        <div class="well">
          <h2 class="title">New Customer</h2>
          <p>
            By creating an account you will be able to shop faster, be up to
            date on an order's status, and keep track of the orders you have
            previously made.
          </p>
          <div class="buttons">
            <div class="pull-right">
              <router-link to="/account/register" class="btn btn-primary">
                Continue
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <Login />
      </div>
    </div>
  </div>
</template>

<script>
import Login from '../auth/Login.vue'
export default { components: { Login } }
</script>

<style lang="scss">
.account-login {
  .well {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    a.forgot-passsword {
      color: rgb(13, 82, 214) !important;
      margin-bottom: 20px;
      &:hover {
        color: rgba(233, 102, 49, 1) !important;
      }
    }
    p {
      color: #333 !important;
    }
  }
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 470px) {
    margin-bottom: 3px;
  }
  label {
    max-width: 150px;
    width: 100%;
    @media (max-width: 470px) {
      font-size: 12px !important;
    }
  }
  input {
    flex-grow: 1;
  }
  input[type='password'] + div {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    flex-grow: 1;
    @media (max-width: 470px) {
      height: 32px !important;
    }
    a {
      color: rgba(13, 82, 214, 1) !important;
      text-decoration: underline !important;
      &:hover {
        color: rgba(233, 102, 49, 1) !important;
        text-decoration: none !important;
      }
    }
  }
}
.well {
  .form-group {
    input {
      width: auto !important;
    }
  }
}
.buttons {
  margin-top: auto !important;
  .pull-right {
    .btn {
      background: rgba(15, 58, 141, 1);
      border-width: 2px;
      padding: 13px;
      padding-right: 18px;
      padding-left: 18px;
      border-radius: 3px !important;
      text-transform: uppercase;
      border: 0;
      width: 100%;
    }
  }
}
</style>
