<template>
  <div class="orders" v-if="orders">
    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr>
            <td class="text-center">Order ID</td>
            <td class="text-center">Customer</td>
            <td class="text-center">No. of Products</td>
            <td class="text-center">Status</td>
            <td class="text-center">Total</td>
            <td class="text-center">Date Added</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">#4019</td>
            <td class="text-center">Ezat Ellozy</td>
            <td class="text-center">1</td>
            <td class="text-center">Processing</td>
            <td class="text-center">$1,004.00</td>
            <td class="text-center">14/08/2022</td>
            <td class="text-center">
              <router-link
                to="/account/order/info"
                class="btn btn-info tooltip-parent"
              >
                <span class="tooltips">View</span>
                <i class="fa fa-eye"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="no data" v-if="!orders">
    <p>You have no order in your account.</p>
  </div>
  <div class="buttons mt-3">
    <div class="pull-right">
      <router-link to="/account" class="btn btn-primary">
        Continue
      </router-link>
    </div>
  </div>
  <div class="order-info" v-if="info">
    <OrderInfo />
  </div>
</template>

<script>
import OrderInfo from './OrderInfo.vue'
export default {
  data() {
    return {
      info: false,
      orders: true,
    }
  },
  mounted() {
    this.openTab(this.tabOpen)
    this.routeName = this.$route.params.actions
  },
  computed: {
    tabOpen() {
      return this.$route.params.actions
    },
  },
  watch: {
    $route() {
      this.openTab(this.$route.params.actions)
      this.routeName = this.$route.params.actions
    },
  },
  methods: {
    openTab(tab) {
      this.info = false
      this[tab] = true
    },
  },
  components: { OrderInfo },
}
</script>

<style lang="scss">
.btn-info {
  padding: 13px 18px !important;
  color: #fff !important;
  text-decoration: none !important;
  border: none !important;
  i {
    font-size: 13px !important;
  }
}
</style>
